import React, { Component, Context, createContext } from "react";

import { IStaffInfo } from "messages";
import { ApiService, AuthenticatedStaff, firebaseAuth, FirebaseService } from "../services";

const AuthContext: Context<IAuth> = createContext({} as IAuth);
const { Consumer, Provider } = AuthContext;

export type IAuth = IAuthProviderState & {
  authenticate: (username: string, password: string) => Promise<AuthenticatedStaff>;
  logout: () => void;
  turnNotificationsOn: () => Promise<void>;
  turnNotificationsOff: () => Promise<void>;
};

interface IAuthProviderState {
  isAuthenticated: boolean;
  staffInfo?: IStaffInfo;
  staffRoutes?: Map<string, boolean>;
}

class AuthProvider extends Component<{}, IAuthProviderState> {
  public state: IAuthProviderState = {
    isAuthenticated: false
  };

  public componentDidMount = async () => {
    const authenticated = await this.checkLocalAuthentication();
    if (authenticated) {
      this.setState({ isAuthenticated: true });
    } else {
      this.setState({ isAuthenticated: false });
    }
    firebaseAuth.onAuthStateChanged((user: firebase.User | null) => {
      if (user) {
        // User is signed in.
        // Set the state isAuthenticated as true
      } else {
        // No user is signed in and change the state isAuthenticated as False in future
      }
    });
  };

  public checkLocalAuthentication = async (): Promise<boolean> => {
    const tid = localStorage.getItem("TID");
    const pid = localStorage.getItem("PID");
    const sfid = localStorage.getItem("SFID");
    if (!(tid && pid && sfid)) {
      localStorage.clear();
      return false;
    }
    try {
      const staffInfo: IStaffInfo = await ApiService.getStaffInfo(sfid);
      const staffAccessList: string[] = await ApiService.getStaffAccessInfo(
        tid,
        pid,
        staffInfo.department || "",
        staffInfo.role || ""
      );
      const staffRoutes: Map<string, boolean> = new Map();
      staffAccessList.forEach((accessRoute: string) => {
        staffRoutes.set(accessRoute, true);
      });
      // NOTE: Check if the tid, pid is same as local storage else logout the user
      if (staffInfo.tid === tid && staffInfo.pid === pid) {
        this.setState({ staffRoutes, staffInfo });
        return true;
      }
    } catch (error) {
      console.error("AUTH ERROR", error);
      localStorage.clear();
      return false;
    }
    localStorage.clear();
    return false;
  };

  public authenticate = async (username: string, password: string): Promise<AuthenticatedStaff> => {
    return ApiService.loginStaff(username, password).then(async (data: AuthenticatedStaff) => {
      const { tid, pid, sfid, customToken, staffAccessList } = data;
      if (tid && pid && sfid) {
        localStorage.setItem("TID", `${tid}`);
        localStorage.setItem("PID", `${pid}`);
        localStorage.setItem("SFID", `${sfid}`);
        localStorage.setItem("ACCESS_LIST", `${JSON.stringify(staffAccessList)}`);
        const staffRoutes: Map<string, boolean> = new Map();
        staffAccessList.forEach((accessRoute: string) => {
          staffRoutes.set(accessRoute, true);
        });
        this.setState({ staffRoutes, isAuthenticated: true });
        this.setState({ staffInfo: data as IStaffInfo });
        await firebaseAuth
          .signInWithCustomToken(customToken)
          .then((firebaseUser: firebase.auth.UserCredential) => {
            console.log(`Succefully logged in ${firebaseUser.user} to firebase`);
          })
          .catch((error: any) => {
            console.log(error);
          });
        return data;
      }
      return data;
    });
  };

  public logout = () => {
    localStorage.clear();
    firebaseAuth.signOut();
    window.location.pathname = "/";
    return;
  };

  public render = () => {
    return (
      <Provider
        value={{
          ...this.state,
          authenticate: this.authenticate,
          logout: this.logout,
          turnNotificationsOff: this.turnNotificationsOff,
          turnNotificationsOn: this.turnNotificationsOn
        }}
      >
        {this.props.children}
      </Provider>
    );
  };

  public turnNotificationsOn = async () => {
    const token: any = await FirebaseService.getAccessToken();
    if (this.state.staffInfo && this.state.staffInfo.sfid) {
      if (this.checkDashboardAccess()) {
        const staffInfo: IStaffInfo = await ApiService.turnOnNotification(this.state.staffInfo.sfid, token);
        if (staffInfo && staffInfo.sfid) {
          this.setState({ staffInfo });
        }
      } else if (token && token !== "") {
        ApiService.updateStaffToken(this.state.staffInfo.sfid, token);
      }
    }
  };

  public checkDashboardAccess = (): boolean => {
    const staffRoutes = this.state.staffRoutes;
    if (staffRoutes && (staffRoutes.get("service-request-dashboard") || staffRoutes.get("tasks"))) {
      return true;
    }
    return false;
  };

  public turnNotificationsOff = async () => {
    if (this.state.staffInfo && this.state.staffInfo.sfid) {
      const staffInfo: IStaffInfo = await ApiService.turnOffNotification(this.state.staffInfo.sfid);
      if (staffInfo && staffInfo.sfid) {
        this.setState({ staffInfo });
      }
    }
  };
}

export { AuthProvider, Consumer as AuthConsumer, AuthContext };
