import React from "react";
import { AuthConsumer, IAuth } from "../AuthContext";

// tslint:disable-next-line: only-arrow-functions
function withStaffInfo<T>(Component: React.ComponentType<T>) {
  return (props: T) => (
    <AuthConsumer>{({ staffInfo }: IAuth) => <Component {...props} staffInfo={staffInfo} />}</AuthConsumer>
  );
}

export default withStaffInfo;
