import React, { ChangeEvent, Component, Context, Fragment } from "react";

import { CircularProgress } from "@material-ui/core";
import styled from "../../styles/styled-components";

export interface IPageLoadState {
  pageNotFound: boolean;
}

const CenterDiv = styled.div`
  position: relative;
  text-align: center;
`;

const ProgressDiv = styled.div`
  padding-top: 25%;
  padding-bottom: 25%;
`;

class PageLoad extends Component<any, IPageLoadState> {
  public state: IPageLoadState = {
    pageNotFound: false
  };

  public componentDidMount = () => {
    setTimeout(() => {
      this.setState({ pageNotFound: true });
    }, 10000);
  };

  public render = () => {
    const { pageNotFound } = this.state;
    return (
      <CenterDiv>
        <ProgressDiv>{pageNotFound ? <span>Page not found!</span> : <CircularProgress color="primary" />}</ProgressDiv>
      </CenterDiv>
    );
  };
}

export default PageLoad;
