import React, { Component, ErrorInfo, Fragment } from "react";

import { Button, Paper, Typography } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { PaperProps } from "@material-ui/core/Paper";

import styled from "../../styles/styled-components";

const Body = styled.div`
  padding: 20px;
`;

const StyledPaper = styled(Paper as React.SFC<PaperProps>)`
  && {
    padding: 16px 24px;
  }
`;

const StyledFullButton = styled(Button as React.SFC<ButtonProps>)`
  && {
    width: 100%;
    > a {
      text-decoration: none !important;
    }
  }
`;

export interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<{}, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo });
    // TODO: log the error to our error logging service
  }

  public handleRefresh = () => {
    window.location.reload();
  };

  public render() {
    return this.state.hasError ? (
      <Body>
        <br />
        <StyledPaper elevation={1}>
          <Typography variant="h5" component="h3">
            Something went wrong!
          </Typography>
          <Typography component="p">When life gives you problems, click the damn refresh button.</Typography>
        </StyledPaper>
        <br />
        <StyledFullButton onClick={this.handleRefresh} variant="contained" color="secondary">
          REFRESH
        </StyledFullButton>
      </Body>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
