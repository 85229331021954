import { IInRoomAmenityData } from "messages";
import React, { Component, Fragment, MouseEvent } from "react";

import { Typography } from "@material-ui/core";
import styled from "../../styles/styled-components";

export interface IAmenitiesDataProps {
  data: IInRoomAmenityData;
  defaultFullInfo?: boolean;
}

export interface IAmenitiesDataState {
  isShowMore?: boolean;
}

const StyledAnchor = styled.a`
  margin-left: 5px;
`;

class AmenitiesData extends Component<IAmenitiesDataProps, IAmenitiesDataState> {
  public state: IAmenitiesDataState = {};
  public componentDidMount = () => {
    const {
      data: { amenities }
    } = this.props;
    if (amenities && amenities.length <= 1) {
      return;
    }
    this.setState({ isShowMore: false });
  };

  public handleShowMore = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ isShowMore: true });
  };

  public handleShowLess = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ isShowMore: false });
  };

  public render() {
    const {
      data: { amenities, specialInstruction },
      defaultFullInfo
    } = this.props;
    const { isShowMore } = this.state;
    return (
      <Fragment>
        {amenities &&
          amenities.map((amenity: any, amenityIndex: number) => (
            <Fragment key={`${amenity.item}-${amenityIndex}`}>
              {amenityIndex === 0 && (
                <Typography component="p">
                  {`Room amenit${amenities.length > 1 ? "ies" : "y"} - ${amenity.item} (x${amenity.quantity})`}
                  {!defaultFullInfo && isShowMore && (
                    <StyledAnchor href="" onClick={this.handleShowLess}>
                      show less
                    </StyledAnchor>
                  )}
                  {!defaultFullInfo && isShowMore !== undefined && !isShowMore && (
                    <StyledAnchor href="" onClick={this.handleShowMore}>
                      show more
                    </StyledAnchor>
                  )}
                </Typography>
              )}
              {amenityIndex > 0 && (isShowMore || defaultFullInfo) && (
                <Typography component="p">{`${amenity.item} (x${amenity.quantity})`}</Typography>
              )}
            </Fragment>
          ))}
        {specialInstruction && `Instruction: ${specialInstruction}`}
      </Fragment>
    );
  }
}

export default AmenitiesData;
