import React, { FunctionComponent, MouseEvent } from "react";
import styled from "../../styles/styled-components";

const StyledImg = styled.img`
  cursor: pointer;
  height: 50px;
  max-width: fit-content;
  object-fit: contain;
  margin: 2px 5px 0 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface IThumbnailListProps {
  images: string[];
  handleThumbnailClick: (images: string[], index: number) => void;
}

const ThumbnailList: FunctionComponent<IThumbnailListProps> = (props: IThumbnailListProps) => {
  const { handleThumbnailClick, images } = props;
  return (
    <Container>
      {images.map((src: string, index: number) => (
        <StyledImg
          src={src}
          alt="Issue image"
          key={`image#${index}`}
          onClick={(e: MouseEvent<HTMLImageElement>) => {
            e.stopPropagation();
            handleThumbnailClick(images, index);
          }}
        />
      ))}
    </Container>
  );
};

export default ThumbnailList;
