import {
  IAirportPickupData,
  IAssignedStaffInfo,
  IInRoomAmenityData,
  ILaundryData,
  IReportIssueData,
  IRoomCleaningData,
  ILinenChangeData,
  PatchedTypes
} from "messages";
import React, { Fragment, FunctionComponent } from "react";
import TimeAgo from "react-timeago";
import { keyframes } from "styled-components";

import { Chip, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import { ListItemProps } from "@material-ui/core/ListItem";
import EscalatedIcon from "@material-ui/icons/ArrowUpward";
import AssignedStaffIcon from "@material-ui/icons/Face";
import GuestSLAIcon from "@material-ui/icons/Loyalty";
import RoomIcon from "@material-ui/icons/MeetingRoom";
import BookingIcon from "@material-ui/icons/Receipt";
import StaffSLAIcon from "@material-ui/icons/TimerOff";

import styled from "../../styles/styled-components";
import { SLATime } from "../SLATime";
import {
  AirportPickupData,
  AmenitiesData,
  CheckInData,
  LaundryData,
  LinenChangeData,
  MaintenanceIssueData,
  RoomCleaningData
} from "./index";
import { ICheckInData } from "messaging/dist/messages/notification/pb";

const glow = keyframes`
  0% {
    box-shadow: inset 0 0 5px #3f51b5;
  }
  25% {
    box-shadow: inset 0 0 15px #3f51b5;
  }
  50% {
    box-shadow: inset 0 0 5px #3f51b5;
  }
  75% {
    box-shadow: inset 0 0 15px #3f51b5;
  }
  100% {
    box-shadow: inset 0 0 5px #3f51b5;
  }
`;

type StyledMyListItemProps = { isUpdated?: boolean } & ListItemProps;
const StyledMyListItem = ({ isUpdated, ...rest }: StyledMyListItemProps) => <ListItem {...rest} />;
export const StyledListItem = styled(StyledMyListItem)`
  animation: 10s ${props => props.isUpdated && glow} ease-in-out;
`;

const StyledChip = styled(Chip as React.SFC<ChipProps>)`
  margin: 2px 0;
  margin-right: 5px;
  height: 24px !important;
`;

const RequestIcon = styled.img`
  max-width: 36px;
  max-height: 36px;
`;

export interface IMyListItemProps {
  airportPickupData?: IAirportPickupData;
  bookingNo?: string;
  amenitiesData?: IInRoomAmenityData;
  checkInData?: ICheckInData;
  createdAt?: number;
  defaultFullInfo?: boolean;
  guestSLATime?: number | null;
  iconUrl: string;
  roomCleaningData?: IRoomCleaningData;
  isUpdated?: boolean;
  laundryData?: ILaundryData;
  linenChangeData?: ILinenChangeData;
  maintenanceIssueData?: IReportIssueData;
  render?: (props: {}) => JSX.Element;
  roomNo?: string | null;
  staff?: IAssignedStaffInfo;
  staffSLATime?: number | null;
  staffSLATimeForEscalation?: number | null;
  status: string;
  handleThumbnailClick?(images: string[], index: number): void;
}

const MyListItem: FunctionComponent<IMyListItemProps> = (props: IMyListItemProps) => {
  const {
    airportPickupData,
    amenitiesData,
    bookingNo,
    checkInData,
    createdAt,
    defaultFullInfo,
    guestSLATime,
    handleThumbnailClick,
    iconUrl,
    roomCleaningData,
    isUpdated,
    laundryData,
    linenChangeData,
    maintenanceIssueData,
    render,
    roomNo,
    staff,
    staffSLATime,
    staffSLATimeForEscalation,
    status
  } = props;
  return (
    <Fragment>
      <StyledListItem isUpdated={isUpdated}>
        <ListItemAvatar>
          <RequestIcon src={iconUrl} />
        </ListItemAvatar>

        <ListItemText
          disableTypography
          secondary={
            <Fragment>
              {airportPickupData && (
                <AirportPickupData
                  data={airportPickupData}
                  defaultFullInfo={defaultFullInfo}
                  handleThumbnailClick={handleThumbnailClick!}
                />
              )}
              {checkInData && (
                <CheckInData
                  data={checkInData}
                  defaultFullInfo={defaultFullInfo}
                  handleThumbnailClick={handleThumbnailClick!}
                />
              )}
              {amenitiesData && <AmenitiesData defaultFullInfo={defaultFullInfo} data={amenitiesData} />}
              {roomCleaningData && <RoomCleaningData data={roomCleaningData} />}
              {linenChangeData && <LinenChangeData data={linenChangeData} />}
              {laundryData && <LaundryData data={laundryData} />}
              {maintenanceIssueData && (
                <MaintenanceIssueData data={maintenanceIssueData} handleThumbnailClick={handleThumbnailClick!} />
              )}
              <div>{createdAt && <TimeAgo date={createdAt} />}</div>

              {staff && (
                <StyledChip
                  icon={<AssignedStaffIcon fontSize="small" />}
                  label={`${staff.firstName} ${staff.lastName}`}
                />
              )}

              {guestSLATime !== 0 && guestSLATime && (
                <StyledChip icon={<GuestSLAIcon fontSize="small" />} label={<SLATime date={guestSLATime} />} />
              )}

              {staffSLATime && (
                <StyledChip icon={<StaffSLAIcon fontSize="small" />} label={<SLATime date={staffSLATime} />} />
              )}

              {bookingNo && <StyledChip icon={<BookingIcon fontSize="small" />} label={`Booking# ${bookingNo}`} />}

              {roomNo && <StyledChip icon={<RoomIcon fontSize="small" />} label={`Room# ${roomNo}`} />}

              {/* TODO: Investigate why guestSLATime and staffSLATimeForEscalation is zero when got through in-app notifications */}

              {staffSLATimeForEscalation !== undefined &&
                staffSLATimeForEscalation !== 0 &&
                staffSLATimeForEscalation !== null &&
                staffSLATimeForEscalation < new Date().getTime() &&
                status !== "COMPLETED" && (
                  <StyledChip color="secondary" icon={<EscalatedIcon fontSize="small" />} label="Escalated" />
                )}
            </Fragment>
          }
        />
        <ListItemSecondaryAction>{render && render({})}</ListItemSecondaryAction>
      </StyledListItem>
      <Divider />
    </Fragment>
  );
};

export default MyListItem;
