import React, { Fragment, FunctionComponent } from "react";

import { Chip, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import MissedConversationIcon from "@material-ui/icons/PhoneMissed";

import styled from "../../styles/styled-components";

const StyledChip = styled(Chip as React.SFC<ChipProps>)`
  height: 24px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  border-color: rgba(0, 0, 0, 0.54) !important;
`;

const SecondaryInfo = styled.span`
  display: flex;
  justify-content: space-between;
`;

export interface IConversationsProps {
  conversations: any[];
}

const Conversations: FunctionComponent<IConversationsProps> = (props: IConversationsProps) => {
  const { conversations } = props;
  return (
    <Fragment>
      <List>
        {conversations &&
          conversations.length > 0 &&
          conversations.map((conversation: any) => {
            const { bookingNo, chreqid, email, firstName, lastName, name, reasons, roomNo } = conversation;
            return (
              <Fragment key={`${chreqid}`}>
                <ListItem>
                  <ListItemIcon>
                    <MissedConversationIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={firstName ? `${firstName || name} ${lastName ? lastName : ""}` : ""}
                    secondary={
                      <Fragment>
                        <SecondaryInfo>
                          {bookingNo && bookingNo.length > 0 && bookingNo[0] && <span>{`Booking# ${bookingNo}`}</span>}
                          {roomNo && roomNo.length > 0 && roomNo[0] && (
                            <StyledChip component="span" label={`Room ${roomNo}`} variant="outlined" />
                          )}
                        </SecondaryInfo>
                        {reasons && reasons.length > 0 && reasons[0] && <span>{`Reason(s) - ${reasons}`}</span>}
                        <br />
                        {email && email.length > 0 && email[0] && <span>{`Email ID(s) - ${email}`}</span>}
                      </Fragment>
                    }
                  />
                </ListItem>
                <Divider />
              </Fragment>
            );
          })}
      </List>
    </Fragment>
  );
};

export default Conversations;
