import {
  Badge,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { ListItemProps } from "@material-ui/core/ListItem";
import BotChatIcon from "@material-ui/icons/Adb";
import TasksIcon from "@material-ui/icons/Assignment";
import CompletedTasksIcon from "@material-ui/icons/AssignmentTurnedIn";
import BookingIcon from "@material-ui/icons/Book";
import OtherConversationIcon from "@material-ui/icons/DevicesOther";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import RestaurantMenuIcon from "@material-ui/icons/Fastfood";
import HistoryIcon from "@material-ui/icons/History";
import RequestIcon from "@material-ui/icons/Inbox";
import MissedConversationIcon from "@material-ui/icons/PhoneMissed";
import ChatIcon from "@material-ui/icons/QuestionAnswer";
import HistoryOrdersIcon from "@material-ui/icons/Receipt";
import RestaurantIcon from "@material-ui/icons/RestaurantMenu";
import EscalationIcon from "@material-ui/icons/TransferWithinAStation";
import React, { Fragment } from "react";
import { Link, LinkProps } from "react-router-dom";
import { AuthConsumer, IAuth } from "../../containers/AuthContext";
import { IState, StateConsumer } from "../../containers/StateContext";

const styles = ({ mixins: { toolbar } }: Theme) => createStyles({ toolbar });
export const LinkedListItem = (props: ListItemProps & LinkProps) => <ListItem {...props} component={Link as any} />;

const SideDrawer: React.FunctionComponent<WithStyles<typeof styles>> = (props: WithStyles<typeof styles>) => {
  const {
    classes: { toolbar }
  } = props;
  return (
    <div>
      <div className={toolbar} />
      <Divider />
      <AuthConsumer>
        {({ staffRoutes, logout }: IAuth) => (
          <List>
            {staffRoutes && staffRoutes.get("chats") && (
              <LinkedListItem to="/chats" button>
                <ListItemIcon>
                  <ChatIcon />
                </ListItemIcon>
                <ListItemText primary="Active chats" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("chat-histories") && (
              <LinkedListItem to="/histories" button>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="History chats" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("missed-conversations") && (
              <LinkedListItem to="/missed-conversations" button>
                <ListItemIcon>
                  <MissedConversationIcon />
                </ListItemIcon>
                <ListItemText primary="Missed conversations" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("other-conversations") && (
              <LinkedListItem to="/other-conversations" button>
                <ListItemIcon>
                  <OtherConversationIcon />
                </ListItemIcon>
                <ListItemText primary="Other conversations" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("bot-chat") && (
              <LinkedListItem to="/botchat" button>
                <ListItemIcon>
                  <BotChatIcon />
                </ListItemIcon>
                <ListItemText primary="BOT chat" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("hotel-bookings") && (
              <LinkedListItem to="/bookings" button>
                <ListItemIcon>
                  <BookingIcon />
                </ListItemIcon>
                <ListItemText primary="Bookings" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("service-request-dashboard") && (
              <LinkedListItem to="/dashboard" button>
                <ListItemIcon>
                  <StateConsumer>
                    {({ dashboardNotifications }: IState) =>
                      dashboardNotifications.size > 0 ? (
                        <Badge badgeContent={dashboardNotifications.size} color="secondary">
                          <DashboardIcon />
                        </Badge>
                      ) : (
                        <DashboardIcon />
                      )
                    }
                  </StateConsumer>
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("my-service-request") && (
              <LinkedListItem to="/my-requests" button>
                <ListItemIcon>
                  <StateConsumer>
                    {({ myRequestNotifications }: IState) =>
                      myRequestNotifications.size > 0 ? (
                        <Badge badgeContent={myRequestNotifications.size} color="secondary">
                          <RequestIcon />
                        </Badge>
                      ) : (
                        <RequestIcon />
                      )
                    }
                  </StateConsumer>
                </ListItemIcon>
                <ListItemText primary="My requests" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("my-escalation") && (
              <LinkedListItem to="/my-escalation" button>
                <ListItemIcon>
                  <EscalationIcon />
                </ListItemIcon>
                <ListItemText primary="My Escalation" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("tasks") && (
              <LinkedListItem to="/tasks" button>
                <ListItemIcon>
                  <StateConsumer>
                    {({ dashboardNotifications }: IState) =>
                      dashboardNotifications.size > 0 ? (
                        <Badge badgeContent={dashboardNotifications.size} color="secondary">
                          <TasksIcon />
                        </Badge>
                      ) : (
                        <TasksIcon />
                      )
                    }
                  </StateConsumer>
                </ListItemIcon>
                <ListItemText primary="Tasks" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("completed-tasks") && (
              <LinkedListItem to="/completed-tasks" button>
                <ListItemIcon>
                  <CompletedTasksIcon />
                </ListItemIcon>
                <ListItemText primary="Completed Tasks" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("restaurant-order") && (
              <LinkedListItem to="/restaurant-orders" button>
                <ListItemIcon>
                  <RestaurantIcon />
                </ListItemIcon>
                <ListItemText primary="Restaurant orders" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("history-order") && (
              <LinkedListItem to="/history-orders" button>
                <ListItemIcon>
                  <HistoryOrdersIcon />
                </ListItemIcon>
                <ListItemText primary="History orders" />
              </LinkedListItem>
            )}
            {staffRoutes && staffRoutes.get("menu-availability") && (
              <LinkedListItem to="/menu-availability" button>
                <ListItemIcon>
                  <RestaurantMenuIcon />
                </ListItemIcon>
                <ListItemText primary="Menu Availability" />
              </LinkedListItem>
            )}
            {
              <Fragment>
                <Divider />
                <ListItem button onClick={logout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
                <Divider />
              </Fragment>
            }
          </List>
        )}
      </AuthConsumer>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SideDrawer);
