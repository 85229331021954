import { GuestChatSession } from "messaging";

export const getAddedSessions = (
  sessions: Map<string, GuestChatSession>,
  newSessions: GuestChatSession[]
): GuestChatSession[] => {
  if (sessions.size < newSessions.length) {
    return newSessions.filter((session: GuestChatSession) => !sessions.get(session.sesid));
  }
  return [];
};
