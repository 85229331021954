import {
  AppBar,
  Chip,
  createStyles,
  IconButton,
  Switch,
  Theme,
  Toolbar,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { ToolbarProps } from "@material-ui/core/Toolbar";
import { TypographyProps } from "@material-ui/core/Typography";
import BackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import NotificationsOff from "@material-ui/icons/NotificationsOff";
import { StaffOnline } from "messaging";
import { inject, observer } from "mobx-react";
import React, { Context, Fragment } from "react";
import { Link } from "react-router-dom";
import { AuthConsumer, IAuth } from "../../containers/AuthContext";
import { IState, StateContext } from "../../containers/StateContext";
import { ApiService, FirebaseService } from "../../services";
import { ClientStore } from "../../stores";
import styled from "../../styles/styled-components";

const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
`;

const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledIconButton = styled(IconButton as React.SFC<IconButtonProps>)`
  color: #ffffff !important;
`;

const StyledToolbar = styled(Toolbar as React.SFC<ToolbarProps>)`
  justify-content: space-between;
`;

const StyledChip = styled(Chip as React.SFC<ChipProps>)`
  height: 24px !important;
  color: #ffffff !important;
  border-color: #ffffff !important;
`;

const StyledTypography = styled(Typography as React.SFC<TypographyProps>)`
  margin-right: 20px !important;
`;

const styles = ({ breakpoints: { up }, palette, zIndex: { drawer } }: Theme) =>
  createStyles({
    appBar: {
      zIndex: drawer + 1
    },
    menuButton: {
      [up("sm")]: {
        display: "none"
      },
      marginRight: 20
    },
    offlineToast: {
      backgroundColor: palette.secondary.main,
      color: "white",
      padding: "4px 0 4px 16px"
    }
  });

export interface INavbarProps extends WithStyles<typeof styles> {
  title: string;
  onMenuClick?: () => void;
  clientStore?: ClientStore;
  render?: () => JSX.Element | undefined;
}

@inject("clientStore")
@observer
class Navbar extends React.Component<INavbarProps, {}> {
  public static contextType: Context<IState> = StateContext;

  public toggleStatus = async () => {
    const { property, setStatus, staff, status }: IState = this.context;
    const { isConnectedToMQTTBroker, getClient } = this.props.clientStore!;
    const online = new StaffOnline({
      isOnline: status === "online" ? false : true,
      property: `${property}`,
      staff: `${staff}`
    });

    const staffClient = getClient();
    if (isConnectedToMQTTBroker && staffClient) {
      staffClient.sendStaffOnline(online);
    }

    // status === "online" ? setStatus("offline") : setStatus("online");
    if (status === "online") {
      setStatus("offline");
      this.turnOffNotification();
    } else {
      setStatus("online");
      this.turnOnNotification();
    }
  };

  public turnOnNotification = async () => {
    const { sfid } = this.context as IState;
    if (!(sfid && sfid !== "")) {
      return;
    }
    FirebaseService.requestPermession(sfid);
  };

  public turnOffNotification = async () => {
    const { sfid } = this.context as IState;
    ApiService.turnOffNotification(sfid);
  };

  public render() {
    const { classes, render, title } = this.props;
    const { isConnectedToMQTTBroker } = this.props.clientStore!;
    const { status }: IState = this.context;
    return (
      <Fragment>
        <AppBar position="fixed" className={classes.appBar}>
          <AuthConsumer>
            {({ staffRoutes, staffInfo, turnNotificationsOn, turnNotificationsOff }: IAuth) => (
              <StyledToolbar>
                <LeftContent>
                  {(title === "Active Chat" || title === "History Chat") && (
                    <Link to="/chats">
                      <StyledIconButton>
                        <BackIcon />
                      </StyledIconButton>
                    </Link>
                  )}
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.props.onMenuClick}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                  <StyledTypography variant="h6" color="inherit">
                    {title}
                  </StyledTypography>
                  {/* <StyledChip label={status} variant="outlined" /> */}
                </LeftContent>
                <RightContent>
                  {render && render()}
                  {/* <Switch checked={status === "online"} value={status} onChange={this.toggleStatus} /> */}
                  <StyledIconButton>
                    {staffInfo && staffInfo.notificationEnabled ? (
                      <NotificationsActive onClick={turnNotificationsOff} />
                    ) : (
                      <NotificationsOff onClick={turnNotificationsOn} />
                    )}
                  </StyledIconButton>
                </RightContent>
              </StyledToolbar>
            )}
          </AuthConsumer>
          {!isConnectedToMQTTBroker && (
            <div className={classes.offlineToast}>You're offline! We're trying to reconnect...</div>
          )}
        </AppBar>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Navbar);
