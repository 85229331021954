import { createStyles, List, ListSubheader, Theme, WithStyles, withStyles } from "@material-ui/core";
import { ListSubheaderProps } from "@material-ui/core/ListSubheader";
import { inject, observer } from "mobx-react";
import React, { Component, Context } from "react";

import { Chats } from "../../components/Chats";
import { Conversations } from "../../components/Conversations";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { MissedConversationStore } from "../../stores";
import styled from "../../styles/styled-components";
import { IState, StateContext } from "../StateContext";
import { RequestListItemPlaceholder } from "../../components/Placeholders";

const StyledListSubheader = styled(ListSubheader as React.SFC<ListSubheaderProps>)`
  background-image: linear-gradient(279deg, #ffd273, #ffde3b);
  color: #000000;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    }
  });

export interface IOtherConversationScreenProps extends WithStyles<typeof styles> {
  missedConversationStore: MissedConversationStore;
}

@inject("missedConversationStore")
@observer
class OtherConversationScreen extends Component<IOtherConversationScreenProps, {}> {
  public static contextType: Context<IState> = StateContext;

  public componentDidMount = () => {
    const { pid, tid }: IState = this.context;
    const { missedConversationStore } = this.props;
    missedConversationStore.fetch(tid, pid);
  };

  public render() {
    const {
      classes,
      missedConversationStore: { missedConversations }
    } = this.props;
    const { activeChats, histories, sessions }: IState = this.context;
    return (
      <ResponsiveNavbar title="Missed Conversations">
        <List className={classes.root} dense subheader={<li />}>
          <li className={classes.listSection}>
            <ul className={classes.ul}>
              <StyledListSubheader color="inherit">Missed conversations</StyledListSubheader>
              <Conversations conversations={missedConversations} />
            </ul>
          </li>
          <li className={classes.listSection}>
            <ul className={classes.ul}>
              <StyledListSubheader color="inherit">Active chats</StyledListSubheader>
              {activeChats.size === 0 && <RequestListItemPlaceholder />}
              <Chats activeChats={activeChats} sessions={sessions} />
            </ul>
          </li>
          <li className={classes.listSection}>
            <ul className={classes.ul}>
              <StyledListSubheader color="inherit">Chat histories</StyledListSubheader>
              <Chats sessions={histories} />
            </ul>
          </li>
        </List>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OtherConversationScreen);
