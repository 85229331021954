import { Typography } from "@material-ui/core";
import { ILaundryData } from "messages";
import React, { Fragment, FunctionComponent } from "react";
import { ShortDate } from "../ShortDate";
import { ShortTime } from "../ShortTime";

export interface ILaundryDataProps {
  data: ILaundryData;
}

const LaundryData: FunctionComponent<ILaundryDataProps> = (props: ILaundryDataProps) => {
  const {
    data: { dateTime, specialInstruction, type }
  } = props;
  return (
    <Fragment>
      <Typography>
        {`Laundry - `}
        <ShortDate date={dateTime} /> <ShortTime date={dateTime} />
        {` with ${type.toUpperCase()} delivery.`}
      </Typography>
      {specialInstruction && `Instruction: ${specialInstruction}`}
    </Fragment>
  );
};

export default LaundryData;
