import { IStaffInfo } from "messages";
import React, { Fragment, FunctionComponent } from "react";

import {
  Avatar,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { ThemedStyledProps } from "styled-components";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";
import { AlertDialog } from "../AlertDialog";

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed} !important;
`;

export interface IAssignStaffModalProps {
  isLoading?: boolean;
  staffs: IStaffInfo[];
  title: string;
  handleCancel(handleClose: () => void): void;
  handleStaffSelect(staff: IStaffInfo, handleClose: () => void): void;
}

const AssignStaffModal: FunctionComponent<IAssignStaffModalProps> = (props: IAssignStaffModalProps) => {
  const { handleCancel, handleStaffSelect, isLoading, staffs, title } = props;
  return (
    <AlertDialog
      render={({ handleClose }) => (
        <Fragment>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {isLoading && (
              <ProgressWrapper>
                <CircularProgress color="inherit" />
              </ProgressWrapper>
            )}
            {!isLoading && (
              <List dense>
                {staffs.map((staff: IStaffInfo, index: number) => (
                  <Fragment key={`${staff.sfid}-${index}`}>
                    <ListItem button onClick={() => handleStaffSelect(staff, handleClose)}>
                      {staff.imgURL && <Avatar src={staff.imgURL} />}
                      {!staff.imgURL && (
                        <Avatar>
                          {staff.firstName[0]}
                          {staff.lastName[0]}
                        </Avatar>
                      )}
                      {/* // TODO: Confirm if department is required here */}
                      <ListItemText primary={`${staff.firstName} ${staff.lastName}`} secondary={`${staff.role}`} />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => handleCancel(handleClose)}>
              CANCEL
            </Button>
          </DialogActions>
        </Fragment>
      )}
    />
  );
};

export default AssignStaffModal;
