import { IAssignedStaffInfo, PatchedTypes } from "messages";
import React, { Component, Context, Fragment } from "react";
import { FullscreenGalleryDialog } from "../../components/FullscreenGalleryDialog";

import {
  CircularProgress,
  createStyles,
  DialogContentText,
  Fab,
  List,
  ListSubheader,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { ListSubheaderProps } from "@material-ui/core/ListSubheader";

import { ThemedStyledProps } from "styled-components";
import { RequestListItemPlaceholder } from "../../components/Placeholders";
import { EscalationExpansionModal } from "../../components/RequestOperationConfirmationModal";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { ServiceRequestListItem } from "../../components/ServiceRequestListItem";
import { ApiService } from "../../services";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";
import { IState, StateContext } from "../StateContext";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
`;

const StyledListSubheader = styled(ListSubheader as React.SFC<ListSubheaderProps>)`
  background-image: linear-gradient(279deg, #ffd273, #ffde3b);
  color: #000000;
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed} !important;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    }
  });

export interface IMyEscalationScreenProps extends WithStyles<typeof styles> {}

export interface IMyEscalationScreenState {
  escalatedRequest?: PatchedTypes.IServiceRequest;
  escalationDetails?: PatchedTypes.IServiceRequestEscalation;
  activeEscalatedServiceRequest?: PatchedTypes.IServiceRequest[];
  completedEscalatedServiceRequest?: PatchedTypes.IServiceRequest[];
  activeServiceRequestEscalationInfo?: PatchedTypes.IServiceRequestEscalation[];
  completedServiceRequestEscalationInfo?: PatchedTypes.IServiceRequestEscalation[];
  isLoading: boolean;
  showFullscreenGalleryDialog?: {
    imgUrls: string | string[];
    clickedStep: number;
  };
}

class MyEscalationScreen extends Component<IMyEscalationScreenProps, IMyEscalationScreenState> {
  public static contextType: Context<IState> = StateContext;

  public state: IMyEscalationScreenState = {
    isLoading: false
  };

  public componentDidMount = async () => {
    const { sfid, pid, tid, serviceRequests, setAllStaffInfo } = this.context as IState;
    setAllStaffInfo();
    const serviceRequestEscalations: {
      activeEscalations: PatchedTypes.IServiceRequestEscalation[];
      completedEscalations: PatchedTypes.IServiceRequestEscalation[];
    } = await ApiService.getServiceRequestEscalation(tid, pid, sfid);

    const activeEscalatedServiceRequest = serviceRequestEscalations.activeEscalations.map(
      (serviceRequestEscalation: PatchedTypes.IServiceRequestEscalation) => {
        return serviceRequests.get(serviceRequestEscalation.serreqid) as PatchedTypes.IServiceRequest;
      }
    );
    const completedEscalatedServiceRequest = serviceRequestEscalations.completedEscalations.map(
      (serviceRequestEscalation: PatchedTypes.IServiceRequestEscalation) => {
        return serviceRequests.get(serviceRequestEscalation.serreqid) as PatchedTypes.IServiceRequest;
      }
    );
    this.setState({
      activeEscalatedServiceRequest,
      activeServiceRequestEscalationInfo: serviceRequestEscalations.activeEscalations,
      completedEscalatedServiceRequest,
      completedServiceRequestEscalationInfo: serviceRequestEscalations.completedEscalations
    });
  };

  public handleOpenEscalationActive = (request: PatchedTypes.IServiceRequest) => {
    const escalationData =
      this.state.activeServiceRequestEscalationInfo &&
      this.state.activeServiceRequestEscalationInfo.filter(
        (escalationRequest: PatchedTypes.IServiceRequestEscalation) => escalationRequest.serreqid === request.serreqid
      );
    if (!(escalationData && escalationData[0])) {
      return;
    }
    this.setState({
      escalatedRequest: request,
      escalationDetails: escalationData[0] as PatchedTypes.IServiceRequestEscalation
    });
  };

  public handleOpenEscalationCompleted = (request: PatchedTypes.IServiceRequest) => {
    const escalationData =
      this.state.completedServiceRequestEscalationInfo &&
      this.state.completedServiceRequestEscalationInfo.filter(
        (escalationRequest: PatchedTypes.IServiceRequestEscalation) => escalationRequest.serreqid === request.serreqid
      );
    if (!(escalationData && escalationData[0])) {
      return;
    }
    this.setState({
      escalatedRequest: request,
      escalationDetails: escalationData[0] as PatchedTypes.IServiceRequestEscalation
    });
  };

  public handleEscalatedRequestConfirmationAccept = async (closeModal: () => void) => {
    this.setState({ escalatedRequest: undefined, escalationDetails: undefined });
    closeModal();
  };

  public handleEscalatedRequestConfirmationDecline = (closeModal: () => void) => {
    this.setState({ escalatedRequest: undefined, escalationDetails: undefined });
    closeModal();
  };

  public handleThumbnailClick = (images: string[], index: number) => {
    this.setState({ showFullscreenGalleryDialog: { imgUrls: images, clickedStep: index } });
  };

  public handleFullscreenGalleryDialogClose = () => {
    this.setState({ showFullscreenGalleryDialog: undefined });
  };

  public render() {
    const { serviceRequests, sfid, allStaffInfoMap }: IState = this.context;
    const myRequests = Array.from(serviceRequests.values()).filter((request: PatchedTypes.IServiceRequest) => {
      const { staffs } = request;
      if (!staffs) {
        return false;
      }
      const myRequest = staffs.find((staff: IAssignedStaffInfo) => staff.sfid === sfid);
      if (!myRequest) {
        return false;
      }
      return true;
    });
    const completedRequests = myRequests.filter(
      (request: PatchedTypes.IServiceRequest) => request.status === "COMPLETED"
    );
    const activeEscalatedServiceRequest = this.state.activeEscalatedServiceRequest;
    const completedEscalatedServiceRequest = this.state.completedEscalatedServiceRequest;
    const { classes } = this.props;
    const { isLoading, escalatedRequest, escalationDetails, showFullscreenGalleryDialog } = this.state;
    return (
      <ResponsiveNavbar title="My escalations">
        {showFullscreenGalleryDialog && (
          <FullscreenGalleryDialog
            {...showFullscreenGalleryDialog}
            open={showFullscreenGalleryDialog ? true : false}
            handleClose={this.handleFullscreenGalleryDialogClose}
          />
        )}
        {escalatedRequest && escalationDetails && (
          <EscalationExpansionModal
            closeButtonLabel="Close"
            isLoading={isLoading}
            request={escalatedRequest}
            escalationRequest={escalationDetails}
            allStaffInfoMap={allStaffInfoMap}
            title="Escalated Request"
            handleAccept={this.handleEscalatedRequestConfirmationAccept}
            handleDecline={this.handleEscalatedRequestConfirmationDecline}
            handleThumbnailClick={this.handleThumbnailClick}
          >
            <DialogContentText />
          </EscalationExpansionModal>
        )}
        <Body>
          {serviceRequests.size === 0 && (
            <ProgressWrapper>
              <CircularProgress color="inherit" />
            </ProgressWrapper>
          )}
          <List className={classes.root} dense subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <StyledListSubheader color="inherit">Currently escalated to me</StyledListSubheader>
                {(!activeEscalatedServiceRequest || activeEscalatedServiceRequest.length === 0) && (
                  <RequestListItemPlaceholder />
                )}
                {activeEscalatedServiceRequest &&
                  activeEscalatedServiceRequest.length > 0 &&
                  activeEscalatedServiceRequest.map(
                    (request: PatchedTypes.IServiceRequest, index: number) =>
                      request && (
                        <div onClick={() => this.handleOpenEscalationActive(request)}>
                          <ServiceRequestListItem
                            handleThumbnailClick={this.handleThumbnailClick}
                            self
                            key={`${request.serreqid}-${index}`}
                            request={request}
                            render={({}) => <Fragment />}
                          />
                        </div>
                      )
                  )}
              </ul>
            </li>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <StyledListSubheader color="inherit">Completed Escalations</StyledListSubheader>
                {(!completedEscalatedServiceRequest || completedEscalatedServiceRequest.length === 0) && (
                  <RequestListItemPlaceholder />
                )}
                {completedEscalatedServiceRequest &&
                  completedEscalatedServiceRequest.length > 0 &&
                  completedEscalatedServiceRequest.map(
                    (request: PatchedTypes.IServiceRequest, index: number) =>
                      request && (
                        <div onClick={() => this.handleOpenEscalationCompleted(request)}>
                          <ServiceRequestListItem
                            handleThumbnailClick={this.handleThumbnailClick}
                            self
                            key={`${request.serreqid}-${index}`}
                            request={request}
                            render={({}) => <Fragment />}
                          />
                        </div>
                      )
                  )}
              </ul>
            </li>
          </List>
        </Body>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MyEscalationScreen);
