import React, { Fragment, FunctionComponent } from "react";

import { BookingInformation, GuestChatSession } from "messaging";

import { Badge, Chip, Divider, List, ListItemIcon, ListItemText } from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import MessageIcon from "@material-ui/icons/Message";

import { ActiveChat } from "../../models";
import styled from "../../styles/styled-components";
import { LinkedListItem } from "../SideDrawer";

const StyledChip = styled(Chip as React.SFC<ChipProps>)`
  height: 24px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  border-color: rgba(0, 0, 0, 0.54) !important;
`;

const SecondaryInfo = styled.span`
  display: flex;
  justify-content: space-between;
`;

export interface IChatsProps {
  sessions: Map<string, GuestChatSession>;
  activeChats?: Map<string, ActiveChat>;
}

const Chats: FunctionComponent<IChatsProps> = (props: IChatsProps) => {
  const { sessions, activeChats } = props;
  return (
    <Fragment>
      <List>
        {sessions &&
          Array.from(sessions.values()).map((session: GuestChatSession) => {
            const chat = activeChats ? activeChats.get(session.sesid) : undefined;
            const { booking, bookingNo } = session;
            const { firstName, lastName, roomNo } = booking || ({} as BookingInformation);
            return (
              <Fragment key={`${session.sesid}`}>
                <LinkedListItem to={chat ? `/chat/${session.sesid}` : `/history/${session.sesid}`} button>
                  <ListItemIcon>
                    {chat && chat.unread > 0 ? (
                      <Badge color="secondary" badgeContent={chat.unread}>
                        <MessageIcon />
                      </Badge>
                    ) : (
                      <MessageIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={firstName && lastName ? `${firstName} ${lastName}` : ""}
                    secondary={
                      <SecondaryInfo>
                        <span>{`Booking# ${bookingNo}`}</span>
                        {roomNo && <StyledChip component="span" label={`Room ${roomNo}`} variant="outlined" />}
                      </SecondaryInfo>
                    }
                  />
                </LinkedListItem>
                <Divider />
              </Fragment>
            );
          })}
      </List>
    </Fragment>
  );
};

export default Chats;
