import firebase from "firebase";
import { ApiService } from "./api-service";
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
});

export const FirebaseMessaging = firebase.messaging();

export const storage = app.storage();
export const database = app.database();
export const firebaseAuth: firebase.auth.Auth = app.auth();

export class FirebaseService {
  public static async getAccessToken() {
    await firebase.messaging().requestPermission();
    return await FirebaseMessaging.getToken();
  }
  public static requestPermession(staffId: string) {
    firebase
      .messaging()
      .requestPermission()
      .then(() => {
        return FirebaseMessaging.getToken();
      })
      .then((token: any) => {
        console.log(token);
        ApiService.updateStaffToken(staffId, token);
      })
      .catch((err: any) => {
        console.log("Unable to get permission to notify.", err);
      });
  }
}

FirebaseMessaging.onTokenRefresh(() => {
  FirebaseMessaging.getToken()
    .then((token: any) => {
      console.log(token);
      // ApiService.updateStaffToken(staffId, token)
    })
    .catch((err: any) => {
      console.log("Unable to get permission to notify.", err);
    });
});
