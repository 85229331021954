import { IMenuItem } from "messages";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent, Component, Context, Fragment } from "react";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";

import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";

import { database } from "../../services/firebase-service";

import { createStyles, Divider, Fab, List, TextField, Theme, withStyles, WithStyles } from "@material-ui/core";
import { MenuStore, OrderStore } from "../../stores";
import styled from "../../styles/styled-components";

import { IState, StateConsumer, StateContext } from "../StateContext";

import { FabProps } from "@material-ui/core/Fab";
import RestaurantIcon from "@material-ui/icons/Restaurant";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
`;

const StyledFab = styled(Fab as React.SFC<FabProps>)`
  position: fixed;
  bottom: 30px;
  right: 30px;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    },
    styledFab: {
      position: "fixed",
      bottom: "30px",
      right: "30px"
    }
  });

export interface IMenuAvailabilityScreenProps extends WithStyles<typeof styles> {
  menuStore: MenuStore;
  orderStore: OrderStore;
}

export interface IMenuAvailabilityScreenState {
  isLoading: boolean;
  searchValue: string;
  openSelectionDialog: boolean;
  restaurants?: any;
}

@inject("menuStore", "orderStore")
@observer
class MenuAvailabilityScreen extends Component<IMenuAvailabilityScreenProps, IMenuAvailabilityScreenState> {
  public static contextType: Context<IState> = StateContext;

  public state: IMenuAvailabilityScreenState = { isLoading: false, searchValue: "", openSelectionDialog: false };

  public componentDidMount() {
    const { hotelInfo }: IState = this.context;
    if (hotelInfo && hotelInfo.restaurants && hotelInfo.restaurants.length > 0) {
      this.setState({ restaurants: hotelInfo.restaurants });
    }
  }

  public handleToggle = (menuItem: IMenuItem, e: any) => {
    database
      .ref()
      .child(`/restaurants/${menuItem.rid}/menu/${menuItem.iid}`)
      .update({
        available: !menuItem.available
      });
  };

  public handleSearchChange = (change: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: change.target.value });
  };

  public handleRestaurantSelection = (restaurant: any) => {
    console.log("RID", restaurant.rid);
    this.setState({ openSelectionDialog: false });
    const { classes, menuStore } = this.props;
    const { changeRid: changeMenuStoreRid } = menuStore;
    changeMenuStoreRid(restaurant.rid);
  };

  public handleSelectRestaurantDialog = () => {
    this.setState({ openSelectionDialog: true });
  };

  public render() {
    const { menuStore, orderStore } = this.props;
    const { menuItems, setRid: setMenuStoreRid } = menuStore;
    const { rid } = orderStore;
    const { searchValue } = this.state;
    const { classes } = this.props;
    return (
      <ResponsiveNavbar title={"Menu availability"}>
        <StateConsumer>
          {({ hotelInfo }: IState) => {
            if (rid && rid !== "") {
              setMenuStoreRid(rid);
            } else {
              if (hotelInfo && hotelInfo.restaurants && hotelInfo.restaurants.length) {
                const {
                  restaurants: [firstRestaurant]
                } = hotelInfo;
                const { rid } = firstRestaurant;
                setMenuStoreRid(rid);
              }
            }
            return (
              <Body>
                {this.state.openSelectionDialog && (
                  <Dialog aria-labelledby="simple-dialog-title" open={this.state.openSelectionDialog}>
                    <DialogTitle id="simple-dialog-title">Select Restaurant</DialogTitle>
                    <List>
                      {this.state.restaurants &&
                        this.state.restaurants.map((restaurant: any) => (
                          <ListItem
                            button
                            onClick={() => this.handleRestaurantSelection(restaurant)}
                            key={restaurant.rid}
                          >
                            <ListItemText primary={restaurant.restaurantName} />
                          </ListItem>
                        ))}
                    </List>
                  </Dialog>
                )}
                <List className={classes.root}>
                  <ListItem>
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      id="bookingId"
                      label="Search Menu"
                      type="text"
                      onChange={this.handleSearchChange}
                      fullWidth
                    />
                  </ListItem>
                  {menuItems.map((menuItem: IMenuItem) => {
                    if (menuItem.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
                      return (
                        <Fragment>
                          <Divider />
                          <ListItem key={menuItem.iid}>
                            <ListItemText primary={menuItem.name} />
                            <ListItemSecondaryAction>
                              <Switch onChange={this.handleToggle.bind(this, menuItem)} checked={menuItem.available} />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Fragment>
                      );
                    }
                  })}
                </List>
                <Fab
                  color="primary"
                  aria-label="Send SMS"
                  className={classes.styledFab}
                  onClick={this.handleSelectRestaurantDialog}
                >
                  <RestaurantIcon />
                </Fab>
              </Body>
            );
          }}
        </StateConsumer>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MenuAvailabilityScreen);
