import { Typography } from "@material-ui/core";
import { IRoomCleaningData } from "messages";
import React, { Fragment, FunctionComponent } from "react";
import { ShortDate } from "../ShortDate";
import { ShortTime } from "../ShortTime";

export interface IRoomCleaningDataProps {
  data: IRoomCleaningData;
}

const RoomCleaningData: FunctionComponent<IRoomCleaningDataProps> = (props: IRoomCleaningDataProps) => {
  const {
    data: { dateTime, specialInstruction }
  } = props;
  return (
    <Fragment>
      <Typography>
        {`Room cleaning - `}
        <ShortDate date={dateTime} /> <ShortTime date={dateTime} />
      </Typography>
      {specialInstruction && `Instruction: ${specialInstruction}`}
    </Fragment>
  );
};

export default RoomCleaningData;
