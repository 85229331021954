import { inject, observer } from "mobx-react";
import { Button, Typography } from "@material-ui/core";
import { ICheckin, ICheckInData } from "messages";
import React, { Component, Fragment, MouseEvent } from "react";
import { CheckinStore } from "../../stores/checkin";
import styled from "../../styles/styled-components";
import { ThumbnailList } from "../ThumbnailList";

const StyledAnchor = styled.a`
  margin-left: 5px;
`;

export interface ICheckInDataProps {
  checkinStore?: CheckinStore;
  data: ICheckInData;
  defaultFullInfo?: boolean;
  handleThumbnailClick(images: string[], index: number): void;
}

export interface ICheckInDataState {
  checkin?: ICheckin;
  isShowMore: boolean;
  isShowRetry: boolean;
}

@inject("checkinStore")
@observer
class CheckInData extends Component<ICheckInDataProps, ICheckInDataState> {
  public state: ICheckInDataState = { isShowMore: false, isShowRetry: false };

  public componentDidMount = () => {
    this.getCheckin();
  };

  public getCheckin = async () => {
    const {
      checkinStore,
      data: { chkinid }
    } = this.props;
    const { getCheckin } = checkinStore!;
    try {
      const checkin = await getCheckin(chkinid);
      this.setState({ checkin: checkin });
    } catch (error) {
      this.setState({ isShowRetry: true });
    }
  };

  public handleShowMore = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ isShowMore: true });
  };

  public handleShowLess = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ isShowMore: false });
  };

  public handleTryAgain = () => {
    this.setState({ isShowRetry: false });
    this.getCheckin();
  };

  public render() {
    const { defaultFullInfo, handleThumbnailClick } = this.props;
    const { checkin, isShowMore, isShowRetry } = this.state;
    let allPhotos: string[] = [];
    if (checkin && checkin.idProofInfos) {
      allPhotos = checkin.idProofInfos.reduce((allProofs: string[], currProof, currIndex) => {
        return [...allProofs, ...(currProof.idProofPhotos ? currProof.idProofPhotos : [])];
      }, []);
    }
    return (
      <Fragment>
        <Typography>
          <Typography component="p">
            {`Anywhere check-in - ${checkin ? `${checkin.firstName} ${checkin.lastName}` : ""}`}
            {!defaultFullInfo && isShowMore && (
              <StyledAnchor href="" onClick={this.handleShowLess}>
                show less
              </StyledAnchor>
            )}
            {!defaultFullInfo && isShowMore !== undefined && !isShowMore && (
              <StyledAnchor href="" onClick={this.handleShowMore}>
                show more
              </StyledAnchor>
            )}
          </Typography>
        </Typography>
        {(isShowMore || defaultFullInfo) && (
          <Fragment>
            {!checkin && isShowRetry && (
              <Button size="small" color="secondary" onClick={this.handleTryAgain}>
                Load Failed! Try again
              </Button>
            )}
            {!checkin && !isShowRetry && <Typography component="progress" />}
            {checkin && !isShowRetry && (
              <Fragment>
                {checkin.email && <Typography component="p">{`Email: ${checkin.email}`}</Typography>}
                {checkin.mobile && <Typography component="p">{`Mobile: ${checkin.mobile}`}</Typography>}
                {checkin.nationality && <Typography component="p">{`Nationality: ${checkin.nationality}`}</Typography>}
                {checkin.noOfAdults && (
                  <Typography component="p">{`# of people: ${checkin.noOfAdults} adult(s) ${
                    checkin.noOfChildren ? ` & ${checkin.noOfChildren} child(ren)` : ""
                  }`}</Typography>
                )}
                {(checkin.bedType || checkin.pillowType || checkin.extraBed) && (
                  <Typography component="p">
                    {`Room preference: ${checkin.bedType ? `${checkin.bedType} bed` : ""}${
                      checkin.pillowType ? `, ${checkin.pillowType} pillow(s)` : ""
                    }${checkin.extraBed ? `, ${checkin.extraBed} Extra bed(s)` : ""}`}
                  </Typography>
                )}
                {checkin.idProofInfos &&
                  checkin.idProofInfos.map((info, index) => {
                    return (
                      <Fragment key={`id-proof#${index}`}>
                        {info.type && checkin.nationality === "Indian" && (
                          <Typography component="p">{`ID type of person ${index + 1}: ${info.type}`}</Typography>
                        )}
                      </Fragment>
                    );
                  })}
              </Fragment>
            )}
          </Fragment>
        )}
        {allPhotos.length > 0 && <ThumbnailList images={allPhotos} handleThumbnailClick={handleThumbnailClick} />}
      </Fragment>
    );
  }
}

export default CheckInData;
