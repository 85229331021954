import {
  AppBar,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Slide,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Toolbar,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { SlideProps } from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Fragment, FunctionComponent } from "react";
import { BookingStore } from "../../stores";

const styles = () =>
  createStyles({
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    }
  });

const Transition: FunctionComponent<SlideProps> = (props: SlideProps) => {
  return <Slide direction="up" {...props} />;
};

export interface IBookingModalProps extends WithStyles<typeof styles> {
  bookingStore?: BookingStore;
}

const BookingModal: FunctionComponent<IBookingModalProps> = inject("bookingStore")(
  observer((props: IBookingModalProps) => {
    const { bookingStore, classes } = props;
    const {
      activeBooking,
      disableSubmitForActiveBooking,
      errorsInBooking,
      hideBookingModal,
      isBookingModalInEditMode,
      isBookingModalVisible,
      reportError,
      setAdditionalInfoInBooking,
      setBookingNoInBooking,
      setCheckInDateInBooking,
      setCheckOutDateInBooking,
      setEmailInBooking,
      setFirstNameInBooking,
      setLastNameInBooking,
      setMobileInBooking,
      setRoomNoInBooking,
      submitActiveBooking,
      toggleIsCancelledInBooking,
      toggleIsForeignNationalInBooking
    } = bookingStore!;
    return (
      <Dialog title="Booking Modal" open={isBookingModalVisible} fullScreen TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" aria-label="Close" onClick={hideBookingModal}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {!isBookingModalInEditMode && "Add a booking"}
              {isBookingModalInEditMode && "Edit a booking"}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {!isBookingModalInEditMode && (
            <Stepper activeStep={0} alternativeLabel>
              <Step>
                <StepLabel>Booking</StepLabel>
              </Step>
              <Step>
                <StepLabel>Checkin</StepLabel>
              </Step>
            </Stepper>
          )}

          <form noValidate autoComplete="off" onSubmit={submitActiveBooking}>
            <FormGroup>
              <FormLabel>
                <Typography color="primary">Booking information</Typography>
              </FormLabel>
              <FormControl required error={errorsInBooking.bookingNo ? true : false} margin="dense" fullWidth>
                <TextField
                  required
                  disabled={isBookingModalInEditMode ? true : false}
                  autoFocus
                  id="bookingId"
                  label="Booking number"
                  type="text"
                  variant="filled"
                  defaultValue={activeBooking.bookingNo}
                  onChange={setBookingNoInBooking}
                  onBlur={() => reportError("bookingNo")}
                />
                {errorsInBooking.bookingNo === "required" && (
                  <FormHelperText error>Booking number is a required field.</FormHelperText>
                )}
              </FormControl>

              <FormControl required error={errorsInBooking.checkInDate ? true : false} margin="dense" fullWidth>
                <TextField
                  required
                  id="datetime-local"
                  label="Check-in date"
                  type="date"
                  defaultValue={moment(activeBooking.checkInDate as number).format("YYYY-MM-DD")}
                  onChange={setCheckInDateInBooking}
                />
                {errorsInBooking.checkInDate === "required" && (
                  <FormHelperText error>Check-in date is a required field.</FormHelperText>
                )}
              </FormControl>

              <FormControl required error={errorsInBooking.checkOutDate ? true : false} margin="dense" fullWidth>
                <TextField
                  required
                  id="datetime-local"
                  label="Check-out date"
                  type="date"
                  defaultValue={moment(activeBooking.checkOutDate as number).format("YYYY-MM-DD")}
                  onChange={setCheckOutDateInBooking}
                />
                {errorsInBooking.checkOutDate === "required" && (
                  <FormHelperText error>Check-out date is a required field.</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth margin="dense">
                <TextField
                  id="roomNumber"
                  label="Room number"
                  type="text"
                  variant="filled"
                  defaultValue={activeBooking.roomNo || undefined}
                  onChange={setRoomNoInBooking}
                />
              </FormControl>
            </FormGroup>
            <br />
            <br />
            <FormGroup>
              <FormLabel>
                <Typography color="primary">Guest information</Typography>
              </FormLabel>
              <FormHelperText>
                Note: Enter Email ID or Mobile or both to make sure the GCP link is communicated to the guest via the
                automated system.
              </FormHelperText>
              <FormControl required error={errorsInBooking.firstName ? true : false} margin="dense" fullWidth>
                <TextField
                  required
                  id="firstName"
                  label="Guest first name"
                  type="text"
                  variant="filled"
                  defaultValue={activeBooking.firstName}
                  onChange={setFirstNameInBooking}
                  onBlur={() => reportError("firstName")}
                />
                {errorsInBooking.firstName === "required" && (
                  <FormHelperText error>Guest first name is a required field.</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth margin="dense">
                <TextField
                  id="lastName"
                  label="Guest last name"
                  type="text"
                  variant="filled"
                  defaultValue={activeBooking.lastName || undefined}
                  onChange={setLastNameInBooking}
                />
              </FormControl>

              <FormControl error={errorsInBooking.email ? true : false} margin="dense" fullWidth>
                <TextField
                  id="email"
                  label="Email ID"
                  type="text"
                  variant="filled"
                  defaultValue={activeBooking.email || undefined}
                  onChange={setEmailInBooking}
                  onBlur={() => reportError("email")}
                />
                {errorsInBooking.email === "invalid" && <FormHelperText error>Email ID is invalid.</FormHelperText>}
              </FormControl>

              <FormControl error={errorsInBooking.mobile ? true : false} margin="dense" fullWidth>
                <TextField
                  id="mobile"
                  label="Mobile number"
                  type="text"
                  variant="filled"
                  defaultValue={activeBooking.mobile || undefined}
                  onChange={setMobileInBooking}
                  onBlur={() => reportError("mobile")}
                />
                {errorsInBooking.mobile === "invalid" && (
                  <FormHelperText error>Mobile number is invalid.</FormHelperText>
                )}
              </FormControl>

              <br />
              <FormGroup>
                <FormLabel>Foreign National</FormLabel>
                <FormControl margin="dense">
                  <Switch
                    defaultChecked={activeBooking.isForeignNational || false}
                    onChange={toggleIsForeignNationalInBooking}
                    value="checked"
                  />
                </FormControl>
              </FormGroup>
            </FormGroup>
            <br />

            <FormGroup>
              <FormLabel>
                <Typography color="primary">Additional information</Typography>
              </FormLabel>
              <FormControl fullWidth margin="dense">
                <TextField
                  id="additional-info"
                  label="Other info"
                  multiline
                  rows={2}
                  rowsMax={3}
                  type="text"
                  variant="filled"
                  defaultValue={activeBooking.additionalInfo || undefined}
                  onChange={setAdditionalInfoInBooking}
                />
              </FormControl>
            </FormGroup>
            <br />

            {isBookingModalInEditMode && (
              <Fragment>
                <br />
                <FormGroup>
                  <FormLabel>Cancel booking</FormLabel>
                  <FormControl margin="dense">
                    <Switch
                      defaultChecked={activeBooking.isCancelled || false}
                      onChange={toggleIsCancelledInBooking}
                      value="checked"
                    />
                  </FormControl>
                </FormGroup>
              </Fragment>
            )}

            <Button
              disabled={disableSubmitForActiveBooking}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              {isBookingModalInEditMode ? "SAVE" : "NEXT"}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    );
  })
);

export default withStyles(styles)(BookingModal);
