import { PatchedTypes } from "messages";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import TimeAgo from "react-timeago";

import { Chip, Divider, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import AlertIcon from "@material-ui/icons/Error";
import OrderIcon from "@material-ui/icons/Receipt";
import RestaurantIcon from "@material-ui/icons/RestaurantMenu";
import StationIcon from "@material-ui/icons/Weekend";

import { StationStore } from "../../stores";
import styled from "../../styles/styled-components";
import BucketData from "./bucket-data";

const StyledChip = styled(Chip as React.SFC<ChipProps>)`
  margin: 2px 0;
  margin-right: 5px;
  height: 24px !important;
`;

export interface IOrderListItemProps {
  buckets?: PatchedTypes.IBucket[];
  defaultFullInfo?: boolean;
  order: PatchedTypes.IOrder;
  stationStore?: StationStore;
  render?: (props: {}) => JSX.Element;
}

export interface IOrderListItemState {
  onDemandFullInfo: boolean;
}

@inject("stationStore")
@observer
class OrderListItem extends Component<IOrderListItemProps, IOrderListItemState> {
  public state: IOrderListItemState = { onDemandFullInfo: false };

  public toggleOnDemandFullScreen = () => {
    const { onDemandFullInfo } = this.state;
    this.setState({ onDemandFullInfo: !onDemandFullInfo });
  };

  public render() {
    const {
      buckets,
      defaultFullInfo,
      order: { createdAt, custClosed, oid, sid },
      render,
      stationStore
    } = this.props;
    const { stationsMap, stationName } = stationStore!;
    const { onDemandFullInfo } = this.state;

    return (
      <Fragment>
        <ListItem button onClick={this.toggleOnDemandFullScreen}>
          {!defaultFullInfo && (
            <ListItemIcon>
              <RestaurantIcon fontSize="large" color="primary" />
            </ListItemIcon>
          )}

          <ListItemText
            disableTypography
            secondary={
              <Fragment>
                {
                  buckets && <BucketData buckets={buckets} defaultFullInfo={defaultFullInfo || onDemandFullInfo} />
                }
                {
                  !buckets && "No items "
                }
                {!defaultFullInfo && buckets && (
                  <div>
                    <TimeAgo date={buckets.length === 1 ? buckets[0].createdAt : createdAt} />
                  </div>
                )}
                {stationsMap.size > 0 && stationName(sid) !== undefined && (
                  <StyledChip icon={<StationIcon fontSize="small" />} label={`${stationName(sid)}`} />
                )}
                {oid && <StyledChip icon={<OrderIcon fontSize="small" />} label={`${oid.slice(-5).toUpperCase()}`} />}
                {custClosed && (
                  <StyledChip color="secondary" icon={<AlertIcon fontSize="small" />} label="Bill requested" />
                )}
              </Fragment>
            }
          />
          <ListItemSecondaryAction>{render && render({})}</ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </Fragment>
    );
  }
}

export default OrderListItem;
