import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { FormControlProps } from "@material-ui/core/FormControl";
import { FormLabelProps } from "@material-ui/core/FormLabel";
import { RadioProps } from "@material-ui/core/Radio";
import { inject, observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { ReasonPickerStore } from "../../stores";
import styled from "../../styles/styled-components";

const StyledRadio = styled(Radio as React.SFC<RadioProps>)`
  padding: 2px !important;
  margin-left: 10px !important;
`;

const StyledFormLabel = styled(FormLabel as React.SFC<FormLabelProps>)`
  margin: 6px 0;
`;

const StyledFormControl = styled(FormControl as React.SFC<FormControlProps>)`
  width: 100%;
`;

export interface IReasonPickerProps {
  reasonPickerStore?: ReasonPickerStore;
}

const ReasonPicker: FunctionComponent<IReasonPickerProps> = inject("reasonPickerStore")(
  observer((props: IReasonPickerProps) => {
    const { reasonPickerStore } = props;
    const { additionalReason, reason, handleAdditionalReasonChange, handleReasonChange } = reasonPickerStore!;
    return (
      <div style={{ display: "flex" }}>
        <StyledFormControl>
          <StyledFormLabel>
            <Typography color="primary">
              Reason : {reason && `${reason} `}
              {additionalReason && `${additionalReason}`}
              {!reason && !additionalReason && "No reason provided."}
            </Typography>
          </StyledFormLabel>
          <RadioGroup aria-label="Reason" name="gender1" value={reason} onChange={handleReasonChange}>
            <FormControlLabel
              value="The item is not available."
              control={<StyledRadio />}
              label="The item is not available."
            />
            <FormControlLabel
              value="The customization is not available."
              control={<StyledRadio />}
              label="The customization is not available."
            />
            <FormControlLabel
              value="The instructions given can not be fulfilled."
              control={<StyledRadio />}
              label="The instructions given can not be fulfilled."
            />
            <FormControlLabel
              value="Guest cancelled the order."
              control={<StyledRadio />}
              label="Guest cancelled the order."
            />
            <FormControlLabel
              value="Guest returned the item."
              control={<StyledRadio />}
              label="Guest returned the item."
            />
            <FormControlLabel value="The kitchen is closed." control={<StyledRadio />} label="The kitchen is closed." />
          </RadioGroup>
          <TextField
            id="additional-reason"
            label="Additional reason"
            value={additionalReason}
            onChange={handleAdditionalReasonChange}
            margin="dense"
            variant="filled"
          />
        </StyledFormControl>
      </div>
    );
  })
);

export default ReasonPicker;
