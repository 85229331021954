export interface IRoute {
  name: string;
  URL: string;
}

export const appRoutes: IRoute[] = [
  { name: "chats", URL: "/chats" },
  { name: "chat-histories", URL: "/histories" },
  { name: "bot-chat", URL: "/botchat" },
  { name: "hotel-bookings", URL: "/bookings" },
  { name: "service-request-dashboard", URL: "/dashboard" },
  { name: "tasks", URL: "/tasks" },
  { name: "completed-tasks", URL: "/completed-tasks" },
  { name: "my-service-request", URL: "/my-requests" },
  { name: "my-escalation", URL: "my-escalation" },
  { name: "restaurant-order", URL: "restaurant-orders" },
  { name: "history-order", URL: "history-orders " },
  { name: "menu-availability", URL: "menu-availability" }
];
