import { IStaffInfo, PatchedTypes } from "messages";
import React, { Component, Context } from "react";
import { IState, StateContext } from "../../containers/StateContext";

import { List } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { SLATime } from "../SLATime";

import EditIcon from "@material-ui/icons/EditRounded";
import FaceIcon from "@material-ui/icons/FaceRounded";
import SupervisedUserCircleRoundedIcon from "@material-ui/icons/SupervisedUserCircleRounded";
import AssignStaffIcon from "@material-ui/icons/WcRounded";

export interface IEscalationTimeline {
  icon?: React.ReactElement<any>;
  label: React.ReactNode;
  eventTime?: number;
  active?: boolean;
  completed?: boolean;
}

export interface IEscalationTimelineProps {
  request: PatchedTypes.IServiceRequest;
  escalationDetails: PatchedTypes.IServiceRequestEscalation;
}

export interface IEscalationTimelineState {
  escalationTimelineArray: IEscalationTimeline[];
}

class EscalationTimeline extends Component<IEscalationTimelineProps, IEscalationTimelineState> {
  public static contextType: Context<IState> = StateContext;
  public componentWillMount() {
    const { request, escalationDetails } = this.props;
    this.processEscalationTimeline(request, escalationDetails);
  }

  public getTimelineLabel(escalationType: string, sfid?: string): string {
    if (!sfid) {
      return `${escalationType}`;
    }
    const { allStaffInfoMap } = this.context as IState;
    if (allStaffInfoMap && allStaffInfoMap.get(sfid)) {
      const staffInfo: IStaffInfo = allStaffInfoMap.get(sfid) || ({} as IStaffInfo);
      return `${escalationType}-${staffInfo.firstName} ${staffInfo.lastName}`;
    }
    return `${escalationType}`;
  }

  public processEscalationTimeline(
    request: PatchedTypes.IServiceRequest,
    escalationDetails: PatchedTypes.IServiceRequestEscalation
  ) {
    const escalationTimelineArray: IEscalationTimeline[] = [];
    const createdData: IEscalationTimeline = {
      eventTime: escalationDetails.createdAt,
      icon: <EditIcon />,
      label: "Created"
    };
    escalationTimelineArray.push(createdData);
    const runnerData: IEscalationTimeline = {
      eventTime: escalationDetails.runnerAssignedAt,
      icon: <AssignStaffIcon />,
      label: this.getTimelineLabel("RUNNER", escalationDetails.runnerStaff || undefined)
    };
    escalationTimelineArray.push(runnerData);
    const l1Data: IEscalationTimeline = {
      eventTime: escalationDetails.l1AssignedAt || escalationDetails.l1Time,
      icon: <SupervisedUserCircleRoundedIcon />,
      label: this.getTimelineLabel("L1", escalationDetails.l1Staff || undefined)
    };
    escalationTimelineArray.push(l1Data);
    const l2Data: IEscalationTimeline = {
      eventTime: escalationDetails.l2AssignedAt || escalationDetails.l2Time,
      icon: <SupervisedUserCircleRoundedIcon />,
      label: this.getTimelineLabel("L2", escalationDetails.l2Staff || undefined)
    };
    escalationTimelineArray.push(l2Data);
    const l3Data: IEscalationTimeline = {
      eventTime: escalationDetails.l3AssignedAt || escalationDetails.l3Time,
      icon: <SupervisedUserCircleRoundedIcon />,
      label: this.getTimelineLabel("L3", escalationDetails.l3Staff || undefined)
    };
    escalationTimelineArray.push(l3Data);
    const l4Data: IEscalationTimeline = {
      eventTime: escalationDetails.l4AssignedAt || escalationDetails.l4Time,
      icon: <SupervisedUserCircleRoundedIcon />,
      label: this.getTimelineLabel("L4", escalationDetails.l4Staff || undefined)
    };
    escalationTimelineArray.push(l4Data);
    escalationTimelineArray.push(l3Data);
    const guestData: IEscalationTimeline = {
      eventTime: request.guestSLATime,
      icon: <FaceIcon />,
      label: "Guest SLA"
    };
    escalationTimelineArray.push(guestData);
    escalationTimelineArray.sort((a: IEscalationTimeline, b: IEscalationTimeline) => {
      if (isNaN(a.eventTime!)) {
        return 1;
      }
      if (a.eventTime! < b.eventTime!) {
        return -1;
      } else {
        return 1;
      }
    });
    this.setState({ escalationTimelineArray });
  }

  public render() {
    const escalationTimelineArray = this.state.escalationTimelineArray;
    const currentTime = new Date().getTime();
    return (
      <List dense>
        <Stepper activeStep={-1} orientation="vertical">
          {escalationTimelineArray.map((escalationTimeline: IEscalationTimeline) => (
            <Step>
              <StepLabel icon={escalationTimeline.icon} active={escalationTimeline.eventTime! < currentTime}>
                <SLATime date={escalationTimeline.eventTime!} />
                {escalationTimeline.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </List>
    );
  }
}

export default EscalationTimeline;
