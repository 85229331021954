import * as React from "react";
import { ThemeProvider } from "./styled-components";

export type StyleProps<T> = Pick<
  T & { children: JSX.Element },
  Exclude<keyof T, "children">
>;

export interface IColorPalette {
  background: string;
  linkPrimary: string;
  ordaapBlack: string;
  ordaapRed: string;
  ordaapWhite: string;
  primary: string;
  secondary: string;
  secondaryDark: string;
  textAccent: string;
  textPrimary: string;
  textSecondary: string;
}

export interface ITypography {
  bubbleText: string;
  fontFamily: string;
  heading: string;
  label: string;
  specialButton: string;
  subHeading: string;
  timestamp: string;
}

export interface ITheme {
  colorPalette: IColorPalette;
  primaryColor: string;
  secondaryColor: string;
  typography: ITypography;
}

export const colorPalette: IColorPalette = {
  background: "#f0f0f0",
  linkPrimary: "#2595cc",
  ordaapBlack: " #241516",
  ordaapRed: "#ce1c2e",
  ordaapWhite: "#ffffff",
  primary: "#16bade",
  secondary: "#ffde3b",
  // secondaryDark: "#fed67e",
  secondaryDark: "#ffd273",
  textAccent: "#cccccc",
  textPrimary: "#333333",
  textSecondary: "#727272"
};

export const typography: ITypography = {
  bubbleText: "16px",
  fontFamily: "'Roboto', sans-serif",
  heading: "24px",
  label: "12px",
  specialButton: "14px",
  subHeading: "20px",
  timestamp: "10px"
};

export const theme: ITheme = {
  colorPalette,
  primaryColor: "#16bade",
  secondaryColor: " #ffde3b",
  typography
};

export function ThemeWrapper<T>(App: any) {
  class ThemeHOC extends React.Component<T, {}> {
    public render() {
      return (
        <ThemeProvider theme={theme}>
          <App {...this.props} />
        </ThemeProvider>
      );
    }
  }
  return ThemeHOC;
}
