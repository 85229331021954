import React, { FunctionComponent, Fragment } from "react";

export interface IShortDateProps {
  date: Date | number;
}

const ShortDate: FunctionComponent<IShortDateProps> = (props: IShortDateProps) => {
  const date = new Date(props.date).toDateString();
  const [, shortMonth, actualdate] = date.split(" ");
  const shortDate = [actualdate, shortMonth].join(" ");
  return <Fragment>{shortDate}</Fragment>;
};

export default ShortDate;
