import { IStation } from "messages";
import { action, computed, observable, reaction } from "mobx";
import { database } from "../services";

export class StationStore {
  @observable public stationsMap: Map<string, IStation> = new Map();
  @observable public isFetching: boolean = false;
  @observable public rid?: string;

  @action public setRid = (rid: string) => {
    if (!this.rid) {
      this.rid = rid;
      this.fetch(rid);
    }
  };

  @action public changeRid = (rid: string) => {
    if (this.rid !== rid) {
      this.rid = rid;
      this.stationsMap = new Map();
      this.fetch(rid);
    }
  };

  public stationName = (sid: string) => {
    return computed(() => {
      const station = this.stationsMap.get(sid);
      if (!station) {
        return;
      }
      return station.name;
    });
  };

  private async fetch(rid: string) {
    this.isFetching = true;
    try {
      const restaurantRef = database
        .ref("/restaurants/")
        .orderByKey()
        .equalTo(rid)
        .limitToFirst(1);
      restaurantRef.on("value", (snapshot: any) => {
        const restaurant = snapshot.val();
        const stations = restaurant[rid] ? restaurant[rid].stations : undefined;
        if (stations) {
          const stationsMap: Map<string, IStation> = new Map();
          Object.keys(stations).map((key: string) => stationsMap.set("" + stations[key].sid, stations[key]));
          this.stationsMap = stationsMap;
        }

        this.isFetching = false;
      });
    } catch (error) {
      console.log("error", error);
    }
  }
}
