import { action, observable } from "mobx";
import { ApiService } from "../services";

export class MissedConversationStore {
  @observable public missedConversations: any[] = [];

  @action public fetch = async (tid: string, pid: string) => {
    try {
      const missedConversations = await ApiService.getMissedConversations(tid, pid);
      this.missedConversations = missedConversations;
    } catch (error) {
      this.missedConversations = [];
    }
  };
}
