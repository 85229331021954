import { IConnectionOptions } from "messaging";

export const getConnectionOptions = (): IConnectionOptions => {
  return {
    cid: Math.random()
      .toString(36)
      .substr(2),
    keepAliveInterval: 30,
    password:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ik" +
      "pvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.5osKj_ZU0WMDxRqYYHWxDPPuCzN7pYwWTl3_liH2I38",
    reconnect: true,
    uri: process.env.REACT_APP_MQTT_URL as string,
    userName: "foo"
  };
};
