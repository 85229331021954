import React, { ChangeEvent, Component, Context, createRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { ThemedStyledProps } from "styled-components";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { IconProps } from "@material-ui/core/Icon";
import PeopleIcon from "@material-ui/icons/People";
import SendIcon from "@material-ui/icons/Send";
import { BotMessage, Staff } from "messaging";

import { FullscreenGalleryDialog } from "../../components/FullscreenGalleryDialog";
import { Gallery } from "../../components/Gallery";
import { StaffClientService, storage } from "../../services";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";
import { IState, ONBOARDING, StateContext } from "../StateContext";

import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { Content, MessagesEnd, MessageWrapper, StyledTextBubble } from "../MessagingScreen";
import { ClientStore } from "../../stores";
import { inject } from "mobx-react";

export interface IBotMessagingScreenState {
  isUploading: boolean;
  showFullscreenGalleryDialog?: {
    imgUrls: string | string[];
    clickedStep: number;
  };
  showServiceRequests: boolean;
  textBoxContent: string;
}

export const TextBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
`;

export const StyledContent = styled(Content)`
  padding-bottom: 10px;
`;

export const StyledButton = styled(Button as React.SFC<ButtonProps>)`
  margin-right: 6px !important;
  margin-bottom: 6px !important;
`;

export const LaundryIcon = styled.img`
  margin-left: 6px;
`;

export const TalkToStaffIcon = styled(PeopleIcon as React.SFC<IconProps>)`
  margin-left: 6px;
`;

export const Icon = styled.img`
  margin-left: 6px;
`;

const AmenitiesIcon = LaundryIcon;

const Logo = styled.img`
  display: inline-block;
  min-width: 30px;
  height: 30px;
  margin-left: 0px;
  margin-right: 8px;
  margin-bottom: 4px;
`;

const EmptyDiv = styled.div`
  display: inline-block;
  min-width: 38px;
  height: 30px;
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed};
`;

@inject("clientStore")
export default class BotMessagingScreen extends Component<{ clientStore?: ClientStore }, IBotMessagingScreenState> {
  public static contextType: Context<IState> = StateContext;

  public state: IBotMessagingScreenState = {
    isUploading: false,
    showServiceRequests: false,
    textBoxContent: ""
  };

  public messagesEnd = createRef<HTMLDivElement>();

  public componentDidMount = async () => {
    const { botChat, isSubscribedToBotTopics }: IState = this.context;
    if (isSubscribedToBotTopics) {
      this.botChatInit();
    }
    this.scrollToBottom();
    botChat.on("new-message", this.scrollToBottom);
  };

  public botChatInit = () => {
    if (localStorage.getItem("BOT_CHAT")) {
      this.replyToBot(ONBOARDING.WELCOME_BACK);
    } else {
      this.replyToBot(ONBOARDING.WELCOME);
    }
  };

  public componentWillUnmount = () => {
    const { botChat }: IState = this.context;
    botChat.removeAllListeners();
  };

  public componentWillUpdate = async (nextProps: {}, nextState: {}, nextContext: IState) => {
    const { isSubscribedToBotTopics }: IState = this.context;
    if (isSubscribedToBotTopics === false && nextContext.isSubscribedToBotTopics) {
      this.botChatInit();
    }
  };

  public scrollToBottom = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollIntoView();
    }
  };

  public handleTyping = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ textBoxContent: event.currentTarget.value });
  };

  public handleSendMessage = () => {
    this.replyToBot(this.state.textBoxContent);
    this.setState({ textBoxContent: "" });
  };

  public handleFullscreenGalleryDialogClose = () => {
    this.setState({ showFullscreenGalleryDialog: undefined });
  };

  public handleGalleryImgClick = (imgUrls: string | string[], clickedStep: number) => {
    this.setState({ showFullscreenGalleryDialog: { imgUrls, clickedStep } });
  };

  public handleFilesChange = async (e: ChangeEvent<HTMLInputElement>, serreqid: string) => {
    const files = e.target.files;
    if (files) {
      const uploadPromises: Array<Promise<string>> = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < files.length; index++) {
        uploadPromises.push(this.uploadAsAPromise(files[index], index, serreqid));
      }
      try {
        this.setState({ isUploading: true });
        const images = await Promise.all(uploadPromises);
        this.replyToBot(JSON.stringify(images), true); // NOTE: true will make set the gallery to true
        this.setState({ isUploading: false });
      } catch (error) {
        // TODO: handle error here
        this.setState({ isUploading: false });
      }
    }
  };

  public uploadAsAPromise = (imageFile: File, index: number, serreqid: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const { property }: IState = this.context;
      const storageRef = storage.ref(`${property}/request/${serreqid}/${index}`);
      const task = storageRef.put(imageFile);
      task.on(
        "state_changed",
        (snapshot: any) => {
          // NOTE: Can be used to calculate the actual progress percentage
        },
        (error: Error) => {
          reject(error);
        },
        async () => {
          resolve(await task.snapshot.ref.getDownloadURL());
        }
      );
    });
  };

  public replyToBot = async (text: string, gallery?: boolean) => {
    const { property, staff }: IState = this.context;
    const { isConnectedToMQTTBroker, getClient } = this.props.clientStore!;
    if (staff && property) {
      const { id: sfid } = staff as Staff;
      const message = new BotMessage({
        gallery,
        isPeerStaff: true,
        property: `${property}`,
        sender: `${staff}`,
        sfid,
        text
      });

      const staffClient = getClient();
      if (isConnectedToMQTTBroker && staffClient) {
        staffClient.sendBotMessage(message);
      }
    }
  };

  public render() {
    const { botChat, staff }: IState = this.context;
    const { isUploading, showFullscreenGalleryDialog } = this.state;
    const messages = botChat.messages;
    return (
      <ResponsiveNavbar title={"Bot chat"}>
        {showFullscreenGalleryDialog && (
          <FullscreenGalleryDialog
            {...showFullscreenGalleryDialog}
            open={showFullscreenGalleryDialog ? true : false}
            handleClose={this.handleFullscreenGalleryDialogClose}
          />
        )}
        <StyledContent>
          {messages &&
            messages.length > 0 &&
            messages.map(
              (message: BotMessage, index: number) =>
                message.type === "BotMessage" && (
                  <Fragment key={`${index}`}>
                    <MessageWrapper {...{ self: message.sender === `${staff}` }}>
                      {index === 0 && message.sender !== `${staff}` && <Logo src="/logo-circle-180x180.png" />}
                      {message.sender !== `${staff}` &&
                      messages[index - 1] &&
                      messages[index - 1].sender === `${staff}` ? (
                        <Logo src="/logo-circle-180x180.png" />
                      ) : (
                        index !== 0 && <EmptyDiv />
                      )}
                      <StyledTextBubble self={message.sender === `${staff}`}>
                        {message.gallery ? (
                          <Gallery imgUrls={message.text} handleGalleryImgClick={this.handleGalleryImgClick} />
                        ) : (
                          <Typography component="pre">{message.text}</Typography>
                        )}
                      </StyledTextBubble>
                    </MessageWrapper>
                    {index === messages.length - 1 && message.continueTyping && (
                      <Typography color="textSecondary" component="p">
                        Bot is typing...
                      </Typography>
                    )}
                    {index === messages.length - 1 &&
                      message.optionMessage &&
                      message.optionMessage.options &&
                      message.optionMessage.options.map((option, optionIndex) => {
                        if (option.externalUrl) {
                          return (
                            <a href={`${option.externalUrl}`} key={`${optionIndex}`} target="_blank">
                              <StyledButton
                                variant="outlined"
                                color="secondary"
                                onClick={() => this.replyToBot(option.value)}
                              >
                                {option.label}
                              </StyledButton>
                            </a>
                          );
                        } else {
                          return (
                            <StyledButton
                              key={`${optionIndex}`}
                              variant="outlined"
                              color={option.value === "cancel" ? "secondary" : "primary"}
                              onClick={() => this.replyToBot(option.value)}
                            >
                              {option.label}
                            </StyledButton>
                          );
                        }
                      })}
                    {index === messages.length - 1 &&
                      message.serviceListMessage &&
                      message.serviceListMessage.options &&
                      message.serviceListMessage.options.map((service, serviceIndex) => {
                        if (service.externalUrl) {
                          return (
                            <a href={`${service.externalUrl}`} key={`${serviceIndex}`} target="_blank">
                              <StyledButton
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => this.replyToBot(service.value)}
                              >
                                {service.label}
                                {service.icon === "dining" && (
                                  <Icon src="/img/dining.png" width={"24px"} height={"24px"} />
                                )}
                              </StyledButton>
                            </a>
                          );
                        } else if (service.internalRoute) {
                          return (
                            <Link to={service.internalRoute} key={`${serviceIndex}`}>
                              <StyledButton
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => this.replyToBot(service.value)}
                              >
                                {service.label}
                                {service.icon === "local_laundry_service" && (
                                  <LaundryIcon src="/img/laundry-white.png" width={"24px"} height={"24px"} />
                                )}
                                {service.icon === "people" && <TalkToStaffIcon />}
                              </StyledButton>
                            </Link>
                          );
                        } else {
                          return (
                            <StyledButton
                              key={`${serviceIndex}`}
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => this.replyToBot(service.value)}
                            >
                              {service.label}
                              {service.icon === "local_laundry_service" && (
                                <LaundryIcon src="/img/laundry-white.png" width={"24px"} height={"24px"} />
                              )}
                              {service.icon === "people" && <TalkToStaffIcon />}
                              {service.icon === "local_amenities_service" && (
                                <AmenitiesIcon src="/img/amenities-white.png" width={"24px"} height={"24px"} />
                              )}
                              {service.icon === "dining" && (
                                <Icon src="/img/dining.png" width={"24px"} height={"24px"} />
                              )}
                              {service.icon === "maintenance" && (
                                <Icon src="/img/maintenance-white.png" width={"24px"} height={"24px"} />
                              )}
                            </StyledButton>
                          );
                        }
                      })}
                    {index === messages.length - 1 && message.uploadFilesMessage && (
                      <Fragment>
                        <StyledButton variant="raised" color="primary" component="label">
                          Upload File(s)
                          <input
                            style={{ display: "none" }}
                            type="file"
                            accept={message.uploadFilesMessage.accept}
                            multiple={message.uploadFilesMessage.multiple || false}
                            onChange={e => this.handleFilesChange(e, message.uploadFilesMessage!.serreqid)}
                          />
                        </StyledButton>
                      </Fragment>
                    )}
                    {index === messages.length - 1 && message.inputMessage && (
                      <TextBox>
                        <TextField
                          type={message.inputMessage.type}
                          placeholder={message.inputMessage.placeHolder}
                          margin="normal"
                          variant="filled"
                          fullWidth
                          defaultValue={this.state.textBoxContent}
                          onChange={this.handleTyping}
                        />
                        <IconButton
                          disabled={this.state.textBoxContent.trim().length === 0}
                          color="secondary"
                          onClick={this.handleSendMessage}
                        >
                          <SendIcon />
                        </IconButton>
                      </TextBox>
                    )}
                  </Fragment>
                )
            )}
          <Dialog open={isUploading}>
            <DialogTitle>Uploading</DialogTitle>
            <DialogContent>
              <ProgressWrapper>
                <CircularProgress />
              </ProgressWrapper>
            </DialogContent>
          </Dialog>
          <MessagesEnd ref={this.messagesEnd} />
        </StyledContent>
      </ResponsiveNavbar>
    );
  }
}
