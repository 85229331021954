import { IReportIssueData } from "messages";
import React, { Fragment, FunctionComponent } from "react";

import { Typography } from "@material-ui/core";
import { ThumbnailList } from "../ThumbnailList";

export interface IMaintenanceIssueDataProps {
  data: IReportIssueData;
  handleThumbnailClick(images: string[], index: number): void;
}

export interface IMaintenanceIssueDataState {
  showFullscreenGalleryDialog?: {
    imgUrls: string | string[];
    clickedStep: number;
  };
}

const MaintenanceIssueData: FunctionComponent<IMaintenanceIssueDataProps> = (props: IMaintenanceIssueDataProps) => {
  const {
    data: { issue, images, specialInstruction },
    handleThumbnailClick
  } = props;
  return (
    <Fragment>
      <Typography component="p">{`Maintenance issue - ${issue}`}</Typography>
      {specialInstruction && `Instruction: ${specialInstruction}`}
      {images && images.length > 0 && <ThumbnailList images={images} handleThumbnailClick={handleThumbnailClick} />}
    </Fragment>
  );
};

export default MaintenanceIssueData;
