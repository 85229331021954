import React, { Fragment, FunctionComponent } from "react";

import { Avatar, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { AvatarProps } from "@material-ui/core/Avatar";
import { ListItemProps } from "@material-ui/core/ListItem";
import styled from "../../../styles/styled-components";

import { keyframes } from "styled-components";

const animateProgress = keyframes`
  0% {
    left: -200px;
  }
  3% {
    visibility: visible;
  }
  100% {
    left: calc(100%);
  }
`;

const Progress = styled.div`
  animation: ${animateProgress} 1s ease-in-out 1s infinite;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  visibility: hidden;
  height: 72.5px;
  position: absolute;
  width: 200px;
  z-index: 3;
`;

const TitlePlaceholder = styled.div`
  width: 100px;
  height: 15px;
  margin: 2.5px 0;
  background-color: #bdbdbd;
`;

const SubheaderPlaceholder = styled.div`
  width: 85px;
  height: 10px;
  margin: 2.5px 0;
  background-color: #e0e0e0;
`;

const Container = styled.div`
  display: flex;
`;

const ChipPlaceholder = styled.div`
  width: 70px;
  height: 24px;
  margin-right: 5px;
  background-color: #e0e0e0;
  border-radius: 12px;
`;

const StyledAvatar = styled(Avatar as React.SFC<AvatarProps>)`
  margin-right: 8px !important;
`;

const StyledListItem = styled(ListItem as React.SFC<ListItemProps>)`
  overflow: hidden;
`;

export interface IRequestListItemProps {
  isLoading?: boolean;
  noOfLoadingItems?: 2 | 3 | 4 | 5;
}

const RequestListItem: FunctionComponent<IRequestListItemProps> = (props: IRequestListItemProps) => {
  const { isLoading, noOfLoadingItems } = props;
  const limit = isLoading && noOfLoadingItems ? noOfLoadingItems : 1;
  const items = [];
  for (let i = 0; i < limit; i++) {
    items.push(
      <Fragment key={`placeholder#${i}`}>
        <StyledListItem>
          {isLoading && <Progress />}
          <ListItemAvatar>
            <Avatar>{""}</Avatar>
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={<TitlePlaceholder />}
            secondary={
              <Fragment>
                <SubheaderPlaceholder />
                <Container>
                  <ChipPlaceholder />
                  <ChipPlaceholder />
                </Container>
              </Fragment>
            }
          />
          <ListItemSecondaryAction>
            <ListItemAvatar>
              <StyledAvatar>{""}</StyledAvatar>
            </ListItemAvatar>
          </ListItemSecondaryAction>
        </StyledListItem>
        <Divider />
        {!isLoading && (
          <Fragment>
            <ListItem>
              <ListItemText primary="You've done all you needed! Take a moment. 🍏" />
            </ListItem>
            <Divider />
          </Fragment>
        )}
      </Fragment>
    );
  }
  return <Fragment>{items}</Fragment>;
};

export default RequestListItem;
