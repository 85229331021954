import { PatchedTypes } from "messages";
import React, { Fragment, FunctionComponent } from "react";

import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, List } from "@material-ui/core";

import { ThemedStyledProps } from "styled-components";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";
import { AlertDialog } from "../AlertDialog";
import { ServiceRequestListItem } from "../ServiceRequestListItem";

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed} !important;
`;

export interface IRequestOperationConfirmationModalProps {
  actionButtonLabel: string;
  children: JSX.Element;
  isLoading: boolean;
  isUpdated?: boolean;
  request: PatchedTypes.IServiceRequest;
  title: string;
  handleAccept(handleClose: () => void): void;
  handleDecline(handleClose: () => void): void;
  handleThumbnailClick?(images: string[], index: number): void;
}

const RequestOperationConfirmationModal: FunctionComponent<IRequestOperationConfirmationModalProps> = (
  props: IRequestOperationConfirmationModalProps
) => {
  const {
    actionButtonLabel,
    children,
    handleAccept,
    handleDecline,
    handleThumbnailClick,
    isLoading,
    isUpdated,
    request,
    title
  } = props;
  return (
    <AlertDialog
      render={({ handleClose }) => (
        <Fragment>
          <DialogTitle>{isLoading ? "Please wait" : title}</DialogTitle>
          <DialogContent>
            {!isLoading && children}
            {!isLoading && (
              <List dense>
                <ServiceRequestListItem
                  defaultFullInfo
                  request={request}
                  handleThumbnailClick={handleThumbnailClick}
                  isUpdated={isUpdated}
                />
              </List>
            )}
            {isLoading && (
              <ProgressWrapper>
                <CircularProgress color="inherit" />
              </ProgressWrapper>
            )}
          </DialogContent>
          {!isLoading && (
            <DialogActions>
              <Button variant="contained" color="secondary" onClick={() => handleDecline(handleClose)}>
                CANCEL
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleAccept(handleClose)}>
                {actionButtonLabel}
              </Button>
            </DialogActions>
          )}
        </Fragment>
      )}
    />
  );
};

export default RequestOperationConfirmationModal;
