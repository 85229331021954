import { IBucketItem, PatchedTypes } from "messages";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent, Component, Context, Fragment } from "react";

import {
  createStyles,
  DialogContentText,
  Fab,
  List,
  ListSubheader,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { FabProps } from "@material-ui/core/Fab";
import { ListSubheaderProps } from "@material-ui/core/ListSubheader";
import AcceptIcon from "@material-ui/icons/Check";
import RejectIcon from "@material-ui/icons/Close";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import CancelItemIcon from "@material-ui/icons/DeleteSweep";
import MessageIcon from "@material-ui/icons/MessageOutlined";
import CloseOrderIcon from "@material-ui/icons/ShoppingCart";
import { CancelItemModal } from "../../components/CancelItemModal";
import { OrderListItem } from "../../components/OrderListItem";
import { OrderOperationConfirmationModal } from "../../components/OrderOperationConfirmationModal";
import { SendSmsModal } from "../../components/SendSmsModal";
import { RequestListItemPlaceholder } from "../../components/Placeholders";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { OrderStore, ReasonPickerStore, StationStore } from "../../stores";
import styled from "../../styles/styled-components";

import { IState, StateConsumer, StateContext } from "../StateContext";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { string } from "prop-types";
import { ApiService } from "../../services";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
`;

const StyledFab = styled(Fab as React.SFC<FabProps>)`
  margin-right: 8px !important;
`;

const StyledListSubheader = styled(ListSubheader as React.SFC<ListSubheaderProps>)`
  background-image: linear-gradient(279deg, #ffd273, #ffde3b);
  color: #000000;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    },
    restaurantSelectFab: {
      bottom: "30px",
      position: "fixed",
      right: "30px",
      zIndex: 10
    },
    sendSmsFab: {
      bottom: "100px",
      position: "fixed",
      right: "30px",
      zIndex: 10
    }
  });

export interface IRestaurantOrdersScreenProps extends WithStyles<typeof styles> {
  orderStore: OrderStore;
  stationStore: StationStore;
  reasonPickerStore: ReasonPickerStore;
}

export interface IRestaurantOrdersScreenState {
  acceptOrder?: {
    bucket: PatchedTypes.IBucket;
    order: PatchedTypes.IOrder;
  };
  cancelAnotherItem?: boolean;
  cancelItem?: {
    order: PatchedTypes.IOrder;
  };
  closeOrder?: {
    buckets: PatchedTypes.IBucket[];
    order: PatchedTypes.IOrder;
  };
  isLoading: boolean;
  selectedRestaurant?: any;
  restaurants?: any;
  openSelectionDialog: boolean;
  newOrdersAgainstOid: boolean;
  rejectOrder?: {
    bucket: PatchedTypes.IBucket;
    order: PatchedTypes.IOrder;
  };
  selectedItem?: ISelectedItem;
  sendSMSDialogShown: boolean;
}

export interface ISelectedItem {
  bucket: PatchedTypes.IBucket;
  bucketIndex: number;
  index: number;
  item: IBucketItem;
  order: PatchedTypes.IOrder;
}

@inject("orderStore", "stationStore", "reasonPickerStore")
@observer
class RestaurantOrdersScreen extends Component<IRestaurantOrdersScreenProps, IRestaurantOrdersScreenState> {
  public static contextType: Context<IState> = StateContext;

  public state: IRestaurantOrdersScreenState = {
    isLoading: false,
    newOrdersAgainstOid: false,
    openSelectionDialog: false,
    sendSMSDialogShown: false
  };

  public componentDidMount() {
    const { hotelInfo }: IState = this.context;
    if (hotelInfo && hotelInfo.restaurants && hotelInfo.restaurants.length > 0) {
      this.setState({ selectedRestaurant: hotelInfo.restaurants[0], restaurants: hotelInfo.restaurants });
    }
  }

  public handleAcceptOrder = (order: PatchedTypes.IOrder, bucket: PatchedTypes.IBucket) => {
    this.setState({ acceptOrder: { bucket, order } });
  };

  public handleCloseOrder = (order: PatchedTypes.IOrder, buckets: PatchedTypes.IBucket[]) => {
    const {
      orderStore: { newOrders }
    } = this.props;
    const { oid } = order;
    const newOrdersAgainstOid = newOrders.find((newOrder: PatchedTypes.IOrder) => newOrder.oid === oid);
    this.setState({ closeOrder: { buckets, order }, newOrdersAgainstOid: newOrdersAgainstOid ? true : false });
  };

  public handleRejectOrder = (order: PatchedTypes.IOrder, bucket: PatchedTypes.IBucket) => {
    this.setState({ rejectOrder: { bucket, order } });
  };

  public handleAcceptOrderConfirmationAccept = async (closeModal: () => void) => {
    this.setState({ isLoading: true });
    const { sfid }: IState = this.context;
    const { acceptOrder } = this.state;
    const { orderStore } = this.props;
    try {
      await orderStore.acceptOrderBucket({ ...acceptOrder!, sfid });
      this.setState({ acceptOrder: undefined, isLoading: false });
      closeModal();
    } catch (error) {
      // TODO: show an error toast
      this.setState({ acceptOrder: undefined, isLoading: false });
      closeModal();
    }
  };

  public handleAcceptOrderConfirmationDecline = (closeModal: () => void) => {
    this.setState({ acceptOrder: undefined });
    closeModal();
  };

  public handleCloseOrderConfirmationAccept = async (closeModal: () => void) => {
    this.setState({ isLoading: true });
    const { sfid }: IState = this.context;
    const { closeOrder } = this.state;
    const { order } = closeOrder!;
    const {
      orderStore: { closeOrderBucket }
    } = this.props;
    try {
      await closeOrderBucket({ order, sfid });
      this.setState({ closeOrder: undefined, isLoading: false, newOrdersAgainstOid: false });
      closeModal();
    } catch (error) {
      this.setState({ closeOrder: undefined, isLoading: false, newOrdersAgainstOid: false });
      closeModal();
    }
  };

  public handleCloseOrderConfirmationDecline = (closeModal: () => void) => {
    this.setState({ closeOrder: undefined, newOrdersAgainstOid: false });
    closeModal();
  };

  public handleRejectOrderConfirmationAccept = async (closeModal: () => void) => {
    this.setState({ isLoading: true });
    const { sfid }: IState = this.context;
    const { rejectOrder } = this.state;
    const { orderStore, reasonPickerStore } = this.props;
    const reasons: string[] = this.getReason();
    try {
      await orderStore.rejectOrderBucket({
        ...rejectOrder!,
        reason: reasons.length ? reasons.join(" ") : undefined,
        sfid
      });
      this.setState({ acceptOrder: undefined, isLoading: false });
      reasonPickerStore.clear();
      closeModal();
    } catch (error) {
      // TODO: show an error toast
      this.setState({ acceptOrder: undefined, isLoading: false });
      reasonPickerStore.clear();
      closeModal();
    }
  };

  public handleRejectOrderConfirmationDecline = (closeModal: () => void) => {
    const { reasonPickerStore } = this.props;
    reasonPickerStore.clear();
    this.setState({ rejectOrder: undefined });
    closeModal();
  };

  public handleCancelItemConfirmationAccept = async (closeModal: () => void) => {
    this.setState({ isLoading: true });
    const { orderStore, reasonPickerStore } = this.props;
    const { cancelAnotherItem, selectedItem } = this.state;
    const { sfid }: IState = this.context;
    const reasons: string[] = this.getReason();
    try {
      await orderStore.cancelItem({
        ...selectedItem!,
        reason: reasons.length ? reasons.join(" ") : undefined,
        sfid
      });
      if (cancelAnotherItem) {
        const { bucketIndex, index, order } = selectedItem!;
        order.buckets![bucketIndex].items![index].cancelled = true;
        this.setState({ cancelAnotherItem: false, cancelItem: { order }, isLoading: false, selectedItem: undefined });
      } else {
        this.setState({
          cancelAnotherItem: undefined,
          cancelItem: undefined,
          isLoading: false,
          selectedItem: undefined
        });
      }
      reasonPickerStore.clear();
      closeModal();
    } catch (error) {
      // TODO: show an error toast
      this.setState({ cancelAnotherItem: undefined, cancelItem: undefined, isLoading: false, selectedItem: undefined });
      reasonPickerStore.clear();
      closeModal();
    }
  };

  public handleCancelItemConfirmationDecline = (closeModal: () => void) => {
    const { reasonPickerStore } = this.props;
    reasonPickerStore.clear();
    this.setState({ cancelAnotherItem: undefined, cancelItem: undefined, isLoading: false, selectedItem: undefined });
    closeModal();
  };

  public handleCancelItem = (order: PatchedTypes.IOrder) => {
    this.setState({ cancelAnotherItem: false, cancelItem: { order } });
  };

  public handleCancelItemModalCancel = (closeModal: () => void) => {
    this.setState({ cancelAnotherItem: undefined, cancelItem: undefined, isLoading: false });
    closeModal();
  };

  public handleItemSelect = (selectedItem: ISelectedItem, closeModal: () => void) => {
    this.setState({ selectedItem });
    closeModal();
  };

  public getReason = () => {
    const {
      reasonPickerStore: { reason, additionalReason }
    } = this.props;
    const reasons = [];
    if (reason) {
      reasons.push(reason);
    }
    if (additionalReason) {
      reasons.push(additionalReason);
    }
    return reasons;
  };

  public handleCancelAnotherItemChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ cancelAnotherItem: event.target.checked });
  };

  public handleRestaurantSelection = (restaurant: any) => {
    console.log("RID", restaurant.rid);
    this.setState({ openSelectionDialog: false, selectedRestaurant: restaurant });
    const { classes, orderStore, stationStore } = this.props;
    const { changeRid: changeOrderStoreRid } = orderStore;
    const { changeRid: changeStationStoreRid } = stationStore;
    changeStationStoreRid(restaurant.rid);
    changeOrderStoreRid(restaurant.rid);
    // this.setState({ selectedRestaurant: { rid: "" } });
  };

  public handleSelectRestaurantDialog = () => {
    console.log("OPEN DIALOG");
    this.setState({ openSelectionDialog: true });
  };

  public showSendSmsDialog = () => {
    this.setState({ sendSMSDialogShown: true });
  };

  public closeSendSmsDialog = () => {
    this.setState({ sendSMSDialogShown: false });
  };

  public handleSendSms = async (oid: string, sid: string, phoneNumbers: string) => {
    if (oid.length === 0) {
      const { sfid }: IState = this.context;
      const createOrderResult = await ApiService.createOrder(this.state.selectedRestaurant.rid, sid, sfid);
      oid = createOrderResult.oid;
    }
    const result = await ApiService.sendSMS(oid, phoneNumbers);
  };

  public render() {
    const {
      acceptOrder,
      cancelAnotherItem,
      cancelItem,
      closeOrder,
      isLoading,
      newOrdersAgainstOid,
      rejectOrder,
      selectedItem,
      selectedRestaurant
    } = this.state;
    const { classes, orderStore, stationStore } = this.props;
    const { activeOrders, isFetching, newOrders, orders, setRid: setOrderStoreRid } = orderStore;
    const { setRid: setStationStoreRid, stationsMap } = stationStore;
    return (
      <ResponsiveNavbar title={"Restaurant Orders"}>
        <StateConsumer>
          {({ hotelInfo }: IState) => {
            /* if (hotelInfo && hotelInfo.restaurants && hotelInfo.restaurants.length) {
              const { restaurants } = hotelInfo;

              const { rid } = restaurants[0];
              setStationStoreRid(rid);
              setOrderStoreRid(rid);
            } */
            if (selectedRestaurant && selectedRestaurant.rid) {
              const restaurantRid = selectedRestaurant.rid;
              if (restaurantRid) {
                setStationStoreRid(restaurantRid);
                setOrderStoreRid(restaurantRid);
              }
            }
            return (
              <Fragment>
                {this.state.sendSMSDialogShown && (
                  <SendSmsModal
                    orders={orders}
                    stations={stationsMap}
                    handleSendSms={this.handleSendSms}
                    handleClose={this.closeSendSmsDialog}
                  />
                )}
                {this.state.openSelectionDialog && (
                  <Dialog aria-labelledby="simple-dialog-title" open={this.state.openSelectionDialog}>
                    <DialogTitle id="simple-dialog-title">Select Restaurant</DialogTitle>
                    <List>
                      {this.state.restaurants.map((restaurant: any) => (
                        <ListItem
                          button
                          onClick={() => this.handleRestaurantSelection(restaurant)}
                          key={restaurant.rid}
                        >
                          <ListItemText primary={restaurant.restaurantName} />
                        </ListItem>
                      ))}
                    </List>
                  </Dialog>
                )}
                {cancelItem && selectedItem && (
                  <OrderOperationConfirmationModal
                    cancelAnotherItem={cancelAnotherItem}
                    item={selectedItem.item}
                    actionButtonLabel="CANCEL ITEM"
                    isLoading={isLoading}
                    showReasonPicker
                    title="Cancel item"
                    handleCancelAnotherItemChange={this.handleCancelAnotherItemChange}
                    handleAccept={this.handleCancelItemConfirmationAccept}
                    handleDecline={this.handleCancelItemConfirmationDecline}
                  >
                    <DialogContentText>Are you sure you want to cancel the following item?</DialogContentText>
                  </OrderOperationConfirmationModal>
                )}

                {cancelItem && !selectedItem && (
                  <CancelItemModal
                    {...cancelItem}
                    handleCancel={this.handleCancelItemModalCancel}
                    handleItemSelect={this.handleItemSelect}
                    title="Select an item to cancel"
                  />
                )}

                {acceptOrder && (
                  <OrderOperationConfirmationModal
                    order={acceptOrder.order}
                    buckets={[acceptOrder.bucket]}
                    actionButtonLabel="ACCEPT"
                    isLoading={isLoading}
                    title="Accept order"
                    handleAccept={this.handleAcceptOrderConfirmationAccept}
                    handleDecline={this.handleAcceptOrderConfirmationDecline}
                  >
                    <DialogContentText>Are you sure you want to accept the following order?</DialogContentText>
                  </OrderOperationConfirmationModal>
                )}

                {closeOrder && (
                  <OrderOperationConfirmationModal
                    {...closeOrder}
                    actionButtonLabel="CLOSE ORDER"
                    disableAction={newOrdersAgainstOid}
                    // tslint:disable-next-line: max-line-length
                    showMessageWhenDisabled="There is/are new order(s) for this order ID! Accept or reject those order(s) first."
                    isLoading={isLoading}
                    title="Close order"
                    handleAccept={this.handleCloseOrderConfirmationAccept}
                    handleDecline={this.handleCloseOrderConfirmationDecline}
                  >
                    <DialogContentText>Are you sure you want to close the following order?</DialogContentText>
                  </OrderOperationConfirmationModal>
                )}

                {rejectOrder && (
                  <OrderOperationConfirmationModal
                    order={rejectOrder.order}
                    buckets={[rejectOrder.bucket]}
                    actionButtonLabel="REJECT"
                    isLoading={isLoading}
                    showReasonPicker
                    title="Reject order"
                    handleAccept={this.handleRejectOrderConfirmationAccept}
                    handleDecline={this.handleRejectOrderConfirmationDecline}
                  >
                    <DialogContentText>Are you sure you want to reject the following order?</DialogContentText>
                  </OrderOperationConfirmationModal>
                )}
                <Body>
                  {orderStore && (
                    <List className={classes.root} dense subheader={<li />}>
                      <li className={classes.listSection}>
                        <ul className={classes.ul}>
                          <StyledListSubheader color="inherit">New orders</StyledListSubheader>
                          {!orders.length && <RequestListItemPlaceholder isLoading={isFetching} noOfLoadingItems={2} />}
                          {newOrders.map((order: PatchedTypes.IOrder) => {
                            const { buckets } = order;
                            return (
                              <Fragment key={`order#${order.oid}`}>
                                {buckets &&
                                  buckets.length > 0 &&
                                  buckets.map((bucket: PatchedTypes.IBucket) => (
                                    <OrderListItem
                                      order={order}
                                      buckets={[bucket]}
                                      key={`order#${order.oid}bucket#${bucket.bid}`}
                                      render={({}) => (
                                        <Fragment>
                                          <StyledFab
                                            aria-label="Reject order"
                                            color="secondary"
                                            size="small"
                                            onClick={() => this.handleRejectOrder(order, bucket)}
                                          >
                                            <RejectIcon />
                                          </StyledFab>
                                          <StyledFab
                                            aria-label="Accept order"
                                            color="primary"
                                            size="small"
                                            onClick={() => this.handleAcceptOrder(order, bucket)}
                                          >
                                            <AcceptIcon />
                                          </StyledFab>
                                        </Fragment>
                                      )}
                                    />
                                  ))}
                              </Fragment>
                            );
                          })}
                        </ul>
                      </li>
                      <li className={classes.listSection}>
                        <ul className={classes.ul}>
                          <StyledListSubheader color="inherit">Active orders</StyledListSubheader>
                          {!orders.length && <RequestListItemPlaceholder isLoading={isFetching} noOfLoadingItems={2} />}
                          {activeOrders.map((order: PatchedTypes.IOrder) => {
                            const { buckets } = order;
                            return (
                              <Fragment key={`order#${order.oid}`}>
                                <OrderListItem
                                  order={order}
                                  buckets={buckets as PatchedTypes.IBucket[]}
                                  render={({}) => (
                                    <Fragment>
                                      <StyledFab
                                        aria-label="Cancel an item"
                                        color="secondary"
                                        size="small"
                                        onClick={() => this.handleCancelItem(order)}
                                      >
                                        <CancelItemIcon />
                                      </StyledFab>
                                      <StyledFab
                                        aria-label="Close order"
                                        color="primary"
                                        size="small"
                                        onClick={() => this.handleCloseOrder(order, buckets!)}
                                      >
                                        <CloseOrderIcon />
                                      </StyledFab>
                                    </Fragment>
                                  )}
                                />
                              </Fragment>
                            );
                          })}
                        </ul>
                      </li>
                    </List>
                  )}
                  <Fab
                    color="primary"
                    aria-label="Send SMS"
                    className={classes.sendSmsFab}
                    onClick={this.showSendSmsDialog}
                  >
                    <MessageIcon />
                  </Fab>
                  <Fab
                    color="primary"
                    aria-label="Send SMS"
                    className={classes.restaurantSelectFab}
                    onClick={this.handleSelectRestaurantDialog}
                  >
                    <RestaurantIcon />
                  </Fab>
                </Body>
              </Fragment>
            );
          }}
        </StateConsumer>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RestaurantOrdersScreen);
