import {
  Button,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component, Context, Fragment } from "react";
import { IState, StateContext } from "../StateContext";
import { BookingModal } from "../../components/BookingModal";
import { CheckinModal } from "../../components/CheckinModal";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { BookingStore, CheckinStore } from "../../stores";
import styled from "../../styles/styled-components";

const ExpandedData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DIV = styled.div`
  width: 100%;
  padding-bottom: 2px;
`;

const StyledFullButton = styled(Button as React.SFC<ButtonProps>)`
  && {
    width: 100%;
    > a {
      text-decoration: none !important;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin: 10px 20px 10px 20px;
`;

const styles = createStyles({
  styledFab: {
    bottom: "30px",
    position: "fixed",
    right: "30px",
    zIndex: 1
  }
});

interface IBookingListScreenProps extends WithStyles<typeof styles> {
  bookingStore: BookingStore;
  checkinStore: CheckinStore;
}

@inject("bookingStore", "checkinStore")
@observer
class BookingListScreen extends Component<IBookingListScreenProps, {}> {
  public static contextType: Context<IState> = StateContext;

  public componentDidMount = () => {
    const { pid, sfid, tid }: IState = this.context;
    const { bookingStore, checkinStore } = this.props;
    bookingStore.setPrerequisite({ pid, sfid, tid });
    checkinStore.setPrerequisite({ pid, sfid, tid });
  };

  public render() {
    const {
      bookingStore: { bookings, isBookingModalVisible, showBookingModal },
      checkinStore: { isCheckinModalVisible, showCheckinModal },
      classes
    } = this.props;
    return (
      <Fragment>
        <BookingModal />
        <CheckinModal />
        <ResponsiveNavbar title={"Hotel bookings"}>
          {!isBookingModalVisible && (
            <Fab color="primary" aria-label="Add" className={classes.styledFab} onClick={() => showBookingModal()}>
              <AddIcon />
            </Fab>
          )}
          {bookings &&
            bookings.length > 0 &&
            bookings.map((hotelBooking, index) => {
              const bookingCheckInDate = hotelBooking.checkInDate;
              const bookingCheckOutDate = hotelBooking.checkOutDate;
              return (
                <ExpansionPanel key={index}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <ExpandedData>
                      <DIV>
                        <b>{hotelBooking.bookingNo}</b>
                      </DIV>
                      <DIV>
                        <b>{hotelBooking.firstName && hotelBooking.lastName && "Name: "}</b>
                        {hotelBooking.firstName && hotelBooking.firstName}{" "}
                        {hotelBooking.lastName && hotelBooking.lastName}
                      </DIV>
                      <DIV>
                        <b>{hotelBooking.roomNo && "Room No: "}</b>
                        {hotelBooking.roomNo && hotelBooking.roomNo}
                      </DIV>
                    </ExpandedData>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <ExpandedData>
                      <DIV>
                        <b>Check in Date: </b>
                        {moment(bookingCheckInDate as number).format("DD-MMM-YYYY dddd")}
                      </DIV>
                      <DIV>
                        <b>Check out Date: </b>
                        {hotelBooking.checkOutDate
                          ? moment(bookingCheckOutDate as number).format("DD-MMM-YYYY dddd")
                          : ""}
                      </DIV>
                      <DIV>
                        <b>Room Number: </b>
                        {hotelBooking.roomNo ? hotelBooking.roomNo : "Not Assigned"}
                      </DIV>
                      <DIV>
                        <b>Status: </b>
                        {hotelBooking.isCancelled ? "Cancelled" : "Active"}
                      </DIV>
                      <ButtonWrapper>
                        <StyledFullButton
                          variant="contained"
                          color="primary"
                          onClick={() => showBookingModal({ booking: hotelBooking, index })}
                        >
                          Edit
                        </StyledFullButton>
                      </ButtonWrapper>
                    </ExpandedData>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
        </ResponsiveNavbar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(BookingListScreen);
