import { IStaffInfo, PatchedTypes } from "messages";
import React, { Component, Context, Fragment } from "react";

import {
  Avatar,
  Chip,
  createStyles,
  DialogContentText,
  Fab,
  List,
  ListSubheader,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import { FabProps } from "@material-ui/core/Fab";
import { ListSubheaderProps } from "@material-ui/core/ListSubheader";
import AssignedStaffIcon from "@material-ui/icons/Face";
import AssignStaffIcon from "@material-ui/icons/Wc";

import { AssignStaffModal } from "../../components/AssignStaffModal";
import { FullscreenGalleryDialog } from "../../components/FullscreenGalleryDialog";
import { RequestListItemPlaceholder } from "../../components/Placeholders";
import { RequestOperationConfirmationModal } from "../../components/RequestOperationConfirmationModal";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { ServiceRequestListItem } from "../../components/ServiceRequestListItem";
import { ApiService } from "../../services";
import styled from "../../styles/styled-components";
import { IState, StateContext } from "../StateContext";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
`;

const StyledChip = styled(Chip as React.SFC<ChipProps>)`
  margin: 0 5px;
`;

const StyledListSubheader = styled(ListSubheader as React.SFC<ListSubheaderProps>)`
  background-image: linear-gradient(279deg, #ffd273, #ffde3b);
  color: #000000;
`;

const StyledFab = styled(Fab as React.SFC<FabProps>)`
  margin-right: 8px !important;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    }
  });

export interface IDashboardScreenProps extends WithStyles<typeof styles> {}

export interface IDashboardScreenState {
  assignStaffToRequest?: PatchedTypes.IServiceRequest;
  selectedStaff?: IStaffInfo;
  isLoading: boolean;
  showFullscreenGalleryDialog?: {
    imgUrls: string | string[];
    clickedStep: number;
  };
}

class DashboardScreen extends Component<IDashboardScreenProps, IDashboardScreenState> {
  public static contextType: Context<IState> = StateContext;

  public state: IDashboardScreenState = {
    isLoading: false
  };

  private isReAssignStaff?: boolean;

  public componentDidMount = () => {
    const { markAllDashboardNotificationsAsRead, setIsDashboardOpenOnUI }: IState = this.context;
    setIsDashboardOpenOnUI(true);
    setTimeout(markAllDashboardNotificationsAsRead, 10000);
  };

  public componentWillUnmount = () => {
    const { setIsDashboardOpenOnUI }: IState = this.context;
    setIsDashboardOpenOnUI(false);
  };

  public handleAssignStaffToRequest = async (request: PatchedTypes.IServiceRequest) => {
    const { setAllStaffInfo }: IState = this.context;
    this.setState({ assignStaffToRequest: request, isLoading: true });
    await setAllStaffInfo();
    this.setState({ isLoading: false });
  };

  public handleAssignStaffToRequestConfirmationAccept = async (closeModal: () => void) => {
    this.isReAssignStaff = false;
    const { assignStaffToRequest, selectedStaff } = this.state;
    if (!assignStaffToRequest || !selectedStaff) {
      closeModal();
      this.setState({ assignStaffToRequest: undefined, isLoading: false, selectedStaff: undefined });
      return;
    }
    this.setState({ isLoading: true });
    const { serreqid } = assignStaffToRequest;
    try {
      await ApiService.assignStaffToServiceRequest(serreqid as string, [selectedStaff]);
      closeModal();
      this.setState({ assignStaffToRequest: undefined, isLoading: false, selectedStaff: undefined });
    } catch (error) {
      closeModal();
      this.setState({ assignStaffToRequest: undefined, isLoading: false, selectedStaff: undefined });
      // TODO: Add a toast saying Something went wrong! Please, try again later.
    }
  };

  public handleAssignStaffToRequestConfirmationDecline = (closeModal: () => void) => {
    this.isReAssignStaff = false;
    closeModal();
    this.setState({ assignStaffToRequest: undefined, isLoading: false, selectedStaff: undefined });
  };

  public handleStaffSelect = (staff: IStaffInfo, closeAssignStaffModal: () => void) => {
    closeAssignStaffModal();
    this.setState({ selectedStaff: staff });
  };

  public handleAssignStaffModalCancel = (closeAssignStaffModal: () => void) => {
    this.isReAssignStaff = false;
    closeAssignStaffModal();
    this.setState({ assignStaffToRequest: undefined, isLoading: false });
  };

  public handleThumbnailClick = (images: string[], index: number) => {
    this.setState({ showFullscreenGalleryDialog: { imgUrls: images, clickedStep: index } });
  };

  public handleFullscreenGalleryDialogClose = () => {
    this.setState({ showFullscreenGalleryDialog: undefined });
  };

  public render() {
    const { allStaffInfoMap, dashboardNotifications, isRequestsLoading, serviceRequests, sfid }: IState = this.context;
    const assignedRequests = Array.from(serviceRequests.values()).filter(
      (request: PatchedTypes.IServiceRequest) => request.status === "ASSIGNED"
    );
    const inProgressRequests = Array.from(serviceRequests.values()).filter(
      (request: PatchedTypes.IServiceRequest) => request.status === "IN_PROGRESS"
    );
    const newRequests = Array.from(serviceRequests.values()).filter(
      (request: PatchedTypes.IServiceRequest) => request.status === "NEW"
    );
    const { classes } = this.props;
    const { assignStaffToRequest, selectedStaff, isLoading, showFullscreenGalleryDialog } = this.state;
    return (
      <ResponsiveNavbar title="Dashboard">
        {showFullscreenGalleryDialog && (
          <FullscreenGalleryDialog
            {...showFullscreenGalleryDialog}
            open={showFullscreenGalleryDialog ? true : false}
            handleClose={this.handleFullscreenGalleryDialogClose}
          />
        )}
        {assignStaffToRequest && !selectedStaff && (
          <AssignStaffModal
            isLoading={isLoading}
            staffs={Array.from(allStaffInfoMap.values()).filter((staff: IStaffInfo) => {
              const { department } = assignStaffToRequest;
              if (staff.department === department) {
                return true;
              }
              return false;
            })}
            title="Select a staff"
            handleCancel={this.handleAssignStaffModalCancel}
            handleStaffSelect={this.handleStaffSelect}
          />
        )}
        {assignStaffToRequest && selectedStaff && (
          <RequestOperationConfirmationModal
            actionButtonLabel={this.isReAssignStaff ? "RE-ASSIGN" : "ASSIGN"}
            isLoading={isLoading}
            request={assignStaffToRequest}
            title={`${this.isReAssignStaff ? "Re-assign" : "Assign"} staff to request`}
            handleAccept={this.handleAssignStaffToRequestConfirmationAccept}
            handleDecline={this.handleAssignStaffToRequestConfirmationDecline}
            handleThumbnailClick={this.handleThumbnailClick}
          >
            <DialogContentText component="div">
              {`Are you sure you want to ${this.isReAssignStaff ? "re-assign" : "assign"}`}
              {selectedStaff.imgURL && (
                <StyledChip
                  avatar={
                    <Avatar
                      alt={`${selectedStaff.firstName[0]}${selectedStaff.lastName[0]}`}
                      src={selectedStaff.imgURL}
                    />
                  }
                  label={`${selectedStaff.firstName} ${selectedStaff.lastName}`}
                />
              )}
              {!selectedStaff.imgURL && (
                <StyledChip
                  icon={<AssignedStaffIcon />}
                  label={`${selectedStaff.firstName} ${selectedStaff.lastName}`}
                />
              )}
              {"to the following request?"}
            </DialogContentText>
          </RequestOperationConfirmationModal>
        )}

        <Body>
          <List className={classes.root} dense subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <StyledListSubheader color="inherit">New requests</StyledListSubheader>
                {!newRequests.length && (
                  <RequestListItemPlaceholder isLoading={isRequestsLoading} noOfLoadingItems={2} />
                )}
                {newRequests.map((request: PatchedTypes.IServiceRequest, index: number) => (
                  <ServiceRequestListItem
                    handleThumbnailClick={this.handleThumbnailClick}
                    key={`${request.serreqid}-${index}`}
                    request={request}
                    isUpdated={dashboardNotifications.has(request.serreqid!)}
                    render={() => (
                      <Fragment>
                        <StyledFab
                          aria-label="Assign staff"
                          color="primary"
                          size="small"
                          onClick={() => this.handleAssignStaffToRequest(request)}
                        >
                          <AssignStaffIcon />
                        </StyledFab>
                      </Fragment>
                    )}
                  />
                ))}
              </ul>
            </li>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <StyledListSubheader color="inherit">Assigned requests</StyledListSubheader>
                {!assignedRequests.length && (
                  <RequestListItemPlaceholder isLoading={isRequestsLoading} noOfLoadingItems={2} />
                )}
                {assignedRequests.map((request: PatchedTypes.IServiceRequest, index: number) => (
                  <ServiceRequestListItem
                    key={`${request.serreqid}-${index}`}
                    handleThumbnailClick={this.handleThumbnailClick}
                    request={request}
                    isUpdated={dashboardNotifications.has(request.serreqid!)}
                    render={() => (
                      <Fragment>
                        <StyledFab
                          aria-label="Re-assign staff"
                          color="primary"
                          size="small"
                          onClick={() => {
                            this.isReAssignStaff = true;
                            this.handleAssignStaffToRequest(request);
                          }}
                        >
                          <AssignStaffIcon />
                        </StyledFab>
                      </Fragment>
                    )}
                  />
                ))}
              </ul>
            </li>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <StyledListSubheader color="inherit">In-progress requests</StyledListSubheader>
                {!inProgressRequests.length && (
                  <RequestListItemPlaceholder isLoading={isRequestsLoading} noOfLoadingItems={2} />
                )}
                {inProgressRequests.map((request: PatchedTypes.IServiceRequest, index: number) => (
                  <ServiceRequestListItem
                    handleThumbnailClick={this.handleThumbnailClick}
                    key={`${request.serreqid}-${index}`}
                    request={request}
                    isUpdated={dashboardNotifications.has(request.serreqid!)}
                  />
                ))}
              </ul>
            </li>
          </List>
        </Body>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DashboardScreen);
