import { inject, observer } from "mobx-react";
import React, { Component, Context } from "react";

import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { Conversations } from "../../components/Conversations";
import { IState, StateContext } from "../StateContext";
import { MissedConversationStore } from "../../stores";

export interface IMissedConversationScreenProps {
  missedConversationStore: MissedConversationStore;
}

@inject("missedConversationStore")
@observer
class MissedConversationScreen extends Component<IMissedConversationScreenProps, {}> {
  public static contextType: Context<IState> = StateContext;

  public componentDidMount = () => {
    const { pid, tid }: IState = this.context;
    const { missedConversationStore } = this.props;
    missedConversationStore.fetch(tid, pid);
  };

  public render() {
    const {
      missedConversationStore: { missedConversations }
    } = this.props;
    return (
      <ResponsiveNavbar title="Missed Conversations">
        <Conversations conversations={missedConversations} />
      </ResponsiveNavbar>
    );
  }
}

export default MissedConversationScreen;
