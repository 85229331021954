import { appRoutes, IRoute } from "../models";

export const getAuthorisedRoutes = (staffRoutes: Map<string, boolean>) => {
  return appRoutes.filter((route: IRoute) => {
    const staffRoute: boolean | undefined = staffRoutes.get(route.name);
    if (!staffRoute) {
      return false;
    }
    return true;
  });
};
