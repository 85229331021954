import { action, observable } from "mobx";
import { ChangeEvent } from "react";

export class ReasonPickerStore {
  @observable public reason: string = "";
  @observable public additionalReason: string = "";

  @action public clear = () => {
    this.reason = "";
    this.additionalReason = "";
  };

  @action public handleAdditionalReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.additionalReason = event.target.value;
  };

  @action public handleReasonChange = (event: any, value: string) => {
    this.reason = value;
  };
}
