import React, { FunctionComponent } from "react";

import { Chats } from "../../components/Chats";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { IState, StateConsumer } from "../StateContext";

const ChatListScreen: FunctionComponent<{}> = (props: {}) => (
  <ResponsiveNavbar title={"Active chats"}>
    <StateConsumer>
      {({ activeChats, sessions }: IState) => (
        <Chats sessions={sessions} activeChats={activeChats} />
      )}
    </StateConsumer>
  </ResponsiveNavbar>
);

export default ChatListScreen;
