import { ILinenChangeData } from "messages";
import React, { Fragment, FunctionComponent } from "react";
import TimeAgo from "react-timeago";

import { Typography } from "@material-ui/core";

export interface ILinenChangeDataProps {
  data: ILinenChangeData;
}

const LinenChangeData: FunctionComponent<ILinenChangeDataProps> = (props: ILinenChangeDataProps) => {
  const {
    data: { dateTime, specialInstruction }
  } = props;
  return (
    <Fragment>
      <Typography>
        {`Linen Change - `}
        <TimeAgo date={dateTime} />
      </Typography>
      {specialInstruction && `Instruction: ${specialInstruction}`}
    </Fragment>
  );
};

export default LinenChangeData;
