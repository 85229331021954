import { Typography } from "@material-ui/core";
import { IBucketItem, IBucketItemOptions } from "messages";
import React, { Fragment, FunctionComponent } from "react";

export interface IBucketItemDataProps {
  defaultFullInfo?: boolean;
  item: IBucketItem;
}

const BucketItemData: FunctionComponent<IBucketItemDataProps> = (props: IBucketItemDataProps) => {
  const { defaultFullInfo, item } = props;
  return (
    <Fragment>
      <Typography
        component="p"
        color={item.cancelled ? "textSecondary" : "default"}
        style={{
          fontWeight: defaultFullInfo ? "bold" : "normal",
          textDecoration: item.cancelled ? "line-through" : "none"
        }}
      >
        {item.name} {defaultFullInfo && `(₹ ${item.total})`} {`(x${item.quantity})`}
      </Typography>
      {defaultFullInfo &&
        item.options &&
        item.options.map((option: IBucketItemOptions, optionIndex: number) => (
          <Typography
            component="p"
            color="textSecondary"
            style={{ textDecoration: item.cancelled ? "line-through" : "none" }}
            key={`option#${optionIndex}`}
          >
            {option.name} {option.price && `(₹ ${option.price})`}
          </Typography>
        ))}
      {defaultFullInfo && item.instruction && (
        <Typography
          component="p"
          color="textSecondary"
          style={{ textDecoration: item.cancelled ? "line-through" : "none" }}
        >
          {item.instruction}
        </Typography>
      )}
    </Fragment>
  );
};

export default BucketItemData;
