export const paymentModes = [
  "Wallet",
  "UPI",
  "Debit card",
  "Credit card",
  "Net banking",
  "Cash",
  "Cheque",
  "NEFT/IMPS"
];
