import React, { Component, Fragment } from "react";

import { Button, createStyles, MobileStepper, Theme, withStyles, WithStyles } from "@material-ui/core";

import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import styled from "../../styles/styled-components";

const styles = ({ breakpoints: { up } }: Theme) =>
  createStyles({
    fullscreenImage: {
      backgroundColor: "black",
      display: "block",
      height: "calc(100vh - 54px - 56px)",
      maxWidth: "100vw",
      objectFit: "contain",
      overflow: "hidden",
      padding: "16px 0",
      width: "100vw",
      [up("xs") + " and (orientation: landscape)"]: {
        height: "calc(100vh - 54px - 48px)"
      },
      [up("sm")]: {
        height: "calc(100vh - 54px - 64px)"
      }
    },
    image: {
      backgroundColor: "black",
      display: "block",
      height: 255,
      maxWidth: 400,
      objectFit: "contain",
      overflow: "hidden",
      padding: "16px 0",
      width: "100%"
    }
  });

const StyledDiv = styled.div<IGalleryProps>`
  flex-grow: 1;
  max-width: ${props => (props.fullscreen ? "100vw" : "400px")};
`;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface IGalleryProps extends WithStyles<typeof styles, true> {
  fullscreen?: boolean;
  imgUrls: string | string[];
  initialActiveStep?: number;
  stopAutoPlay?: boolean;
  handleGalleryImgClick?: (imgUrls: string | string[], clickedStep: number) => void;
}

export interface IGalleryState {
  activeStep: number;
}

class Gallery extends Component<IGalleryProps, IGalleryState> {
  constructor(props: IGalleryProps) {
    super(props);
    const { initialActiveStep } = props;
    this.state = {
      activeStep: initialActiveStep || 0
    };
  }

  public handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  public handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  public handleStepChange = (activeStep: number) => {
    this.setState({ activeStep });
  };

  public render() {
    const { classes, handleGalleryImgClick, imgUrls, stopAutoPlay } = this.props;
    const { theme, ...styleProps } = this.props;
    const { activeStep } = this.state;
    let images: string[];
    if (Array.isArray(imgUrls)) {
      images = imgUrls;
    } else {
      const parsedImgUrls: string[] = JSON.parse(imgUrls);
      images = parsedImgUrls;
    }
    const maxSteps = images.length;

    return (
      <Fragment>
        <StyledDiv {...styleProps}>
          {!stopAutoPlay && (
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
            >
              {images.map((src, index) => (
                <div key={`GalleryImage#${index}`}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img
                      className={classes.image}
                      src={src}
                      alt="Issue image"
                      onClick={() => {
                        if (handleGalleryImgClick) {
                          handleGalleryImgClick(imgUrls, activeStep);
                        }
                      }}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
          )}
          {stopAutoPlay && (
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
            >
              {images.map((src, index) => (
                <div key={`GalleryImage#${index}`}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img className={classes.fullscreenImage} src={src} alt="Issue image" />
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
          )}
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                {theme.direction === "rtl" ? <BackIcon /> : <NextIcon />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                {theme.direction === "rtl" ? <NextIcon /> : <BackIcon />}
                Back
              </Button>
            }
          />
        </StyledDiv>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Gallery);
