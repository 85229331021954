import React, { ChangeEvent, Component, Context, createRef } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import {
  Button,
  CircularProgress,
  createStyles,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { PaperProps } from "@material-ui/core/Paper";
import SendIcon from "@material-ui/icons/Send";
import CompleteChatIcon from "@material-ui/icons/SpeakerNotesOff";

import InfiniteScroll from "react-infinite-scroller";

import { AlertDialog } from "../../components/AlertDialog";
import { ActiveChat, HistoryChat } from "../../models";
import { StaffClientService } from "../../services";
import styled from "../../styles/styled-components";

import { IState, StateContext } from "../StateContext";

import { GuestChatComplete, GuestChatSession, PeerMessage } from "messaging";
import { drawerWidth, ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { rand } from "../../utils";
import { ClientStore } from "../../stores";
import { inject } from "mobx-react";

export const Content = styled.div`
  ${(props: any) => (props.session ? "padding: 16px 16px 100px 16px;" : "padding: 16px 16px 10px 16px;")}
  height: 100%;
  overflow: auto;
  background-color: #f0f0f0;
`;

export const MessageWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: ${(props: any) => (props.self ? "flex-end" : "flex-start")};
`;

type MyPaperProps = { self: boolean } & PaperProps;
const MyPaper = ({ self, ...rest }: MyPaperProps) => <Paper {...rest} />;
export const StyledTextBubble = styled(MyPaper)`
  && {
    padding: 12px 10px;
    height: fit-content;
    ${props =>
      props.self ? "background-image: linear-gradient(279deg, #ffd273, #ffde3b);" : "backgound-color: #ffffff"};
  }
`;

const TextBox = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
`;

export const MessagesEnd = styled.div`
  float: "left";
  clear: "both";
`;

const styles = ({ breakpoints: { up } }: Theme) =>
  createStyles({
    textBox: {
      [up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`
      },
      marginLeft: drawerWidth
    }
  });

export interface IWithRouteProps {
  sesid: string;
}
export type IMessagingScreenWithRouteProps = {
  clientStore?: ClientStore;
} & RouteComponentProps<IWithRouteProps> &
  WithStyles<typeof styles>;

export interface IMessagingScreenState {
  completeChatAlert: boolean;
  invalidUrl: boolean;
  session?: GuestChatSession;
  textBoxContent: string;
}

@inject("clientStore")
class MessagingScreen extends Component<IMessagingScreenWithRouteProps, IMessagingScreenState> {
  public static contextType: Context<IState> = StateContext;

  public messagesEnd = createRef<HTMLDivElement>();

  public state: IMessagingScreenState = {
    completeChatAlert: false,
    invalidUrl: false,
    textBoxContent: ""
  };

  private chat?: ActiveChat | HistoryChat;

  constructor(props: IMessagingScreenWithRouteProps) {
    super(props);
  }

  public componentDidMount() {
    this.chatInit().then((chat: ActiveChat | HistoryChat | undefined) => {
      if (chat) {
        chat.on("new-message", () => this.scrollToBottom());
        chat.on("first-page-fetch", () => {
          this.forceUpdate();
          this.scrollToBottom();
        });
        this.chat = chat;
      }
      this.scrollToBottom();
    });
  }

  public chatInit = async () => {
    const { sesid } = this.props.match.params;
    const {
      activeChats,
      createActiveChat,
      createHistoryChat,
      histories,
      historyChats,
      setChatOpenOnUI,
      sessions
    }: IState = this.context;
    const foundSession = sessions.get(sesid);
    const foundChat = activeChats.get(sesid);
    const foundHistorySession = histories.get(sesid);
    const foundHistoryChat = historyChats.get(sesid);
    if (foundSession) {
      setChatOpenOnUI(foundSession.sesid);
      this.setState({ session: foundSession });
      if (!foundChat) {
        return createActiveChat(foundSession);
      } else {
        return foundChat;
      }
    } else if (foundHistorySession) {
      setChatOpenOnUI("");
      if (!foundHistoryChat) {
        return createHistoryChat(foundHistorySession);
      }
      return foundHistoryChat;
    } else {
      this.setState({ invalidUrl: true });
    }
  };

  public scrollToBottom = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollIntoView();
    }
  };

  public componentWillUnmount = () => {
    const { setChatOpenOnUI }: IState = this.context;
    setChatOpenOnUI("");
    if (this.chat) {
      this.chat.removeAllListeners();
    }
  };

  public handleSendMessage = async () => {
    const { isConnectedToMQTTBroker, getClient } = this.props.clientStore!;
    const { session, textBoxContent } = this.state;
    const { property, sesid, staff } = session as GuestChatSession;
    const message = new PeerMessage({
      msgid: rand(),
      property,
      sender: staff,
      sesid,
      text: textBoxContent
    });

    const staffClient = getClient();
    if (isConnectedToMQTTBroker && staffClient) {
      staffClient.sendMessage(message);
    }

    this.setState({ textBoxContent: "" });
  };

  public handleTyping = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ textBoxContent: event.currentTarget.value });
  };

  public handleCompleteChat = () => {
    this.setState({ completeChatAlert: true });
  };

  public handleCompleteChatAccept = async () => {
    this.setState({ completeChatAlert: false });
    const { isConnectedToMQTTBroker, getClient } = this.props.clientStore!;
    const { session } = this.state;
    const complete = new GuestChatComplete({
      session: session as GuestChatSession
    });

    const staffClient = getClient();
    if (isConnectedToMQTTBroker && staffClient) {
      staffClient.sendGuestChatComplete(complete);
    }

    this.setState({ invalidUrl: true });
  };

  public handleCompleteChatDecline = () => {
    this.setState({ completeChatAlert: false });
  };

  public render() {
    const { completeChatAlert, invalidUrl, session, textBoxContent } = this.state;
    const { activeChats, historyChats, staff }: IState = this.context;
    const {
      classes: { textBox }
    } = this.props;
    const { sesid } = this.props.match.params;
    const chat: ActiveChat | HistoryChat | undefined = activeChats.get(sesid) || historyChats.get(sesid);
    if (chat instanceof ActiveChat) {
      chat.readUnreadMessages();
    }
    const messages = chat ? chat.messages : [];

    return (
      <ResponsiveNavbar
        title={session ? "Active chat" : "History chat"}
        render={() =>
          session && (
            <IconButton onClick={this.handleCompleteChat} color="inherit" aria-label="Menu">
              <CompleteChatIcon />
            </IconButton>
          )
        }
      >
        {invalidUrl && <Redirect to="/chats" />}
        {completeChatAlert && (
          <AlertDialog
            render={({ handleClose }) => (
              <div>
                <DialogTitle>Sure you want to end this chat?</DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.handleCompleteChatAccept();
                      handleClose();
                    }}
                  >
                    AGREE
                  </Button>
                  <Button
                    onClick={() => {
                      this.handleCompleteChatDecline();
                      handleClose();
                    }}
                  >
                    DISAGREE
                  </Button>
                </DialogActions>
              </div>
            )}
          />
        )}

        <Content {...this.state}>
          {chat && (
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={async (page: number) => {
                await chat.fetchNextPage(page);
                this.forceUpdate();
              }}
              hasMore={chat.noOfLastLoadRecords === chat.noOfRecords}
              loader={
                <ProgressWrapper key={0}>
                  <CircularProgress color="primary" />
                </ProgressWrapper>
              }
              isReverse={true}
              threshold={50}
              useWindow={false}
            >
              {messages &&
                messages.length > 0 &&
                messages.map(
                  (message: PeerMessage, index: number) =>
                    message.type === "PeerMessage" && (
                      <MessageWrapper key={`${message.sesid}${index}`} {...{ self: message.sender === `${staff}` }}>
                        <StyledTextBubble self={message.sender === `${staff}`}>
                          <Typography component="pre">{message.text}</Typography>
                        </StyledTextBubble>
                      </MessageWrapper>
                    )
                )}
            </InfiniteScroll>
          )}

          <MessagesEnd ref={this.messagesEnd} />
          {session && (
            <TextBox className={textBox}>
              <TextField
                id="outlined-textarea"
                label="Enter your message here"
                multiline
                rows={2}
                rowsMax={2}
                margin="normal"
                variant="filled"
                onChange={this.handleTyping}
                value={textBoxContent}
                fullWidth
              />
              <IconButton
                disabled={this.state.textBoxContent.trim().length === 0}
                color="secondary"
                onClick={this.handleSendMessage}
              >
                <SendIcon />
              </IconButton>
            </TextBox>
          )}
        </Content>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MessagingScreen);
