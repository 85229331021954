import { IMenuItem } from "messages";
import { action, computed, observable } from "mobx";
import { database } from "../services";

export class MenuStore {
  @observable public menuItems: IMenuItem[] = [];
  @observable public isFetching: boolean = false;
  @observable public rid?: string;

  @action public setRid = (rid: string) => {
    if (!this.rid) {
      this.rid = rid;
      this.fetch(rid);
    }
  };

  @action public changeRid = (rid: string) => {
    if (this.rid !== rid) {
      this.rid = rid;
      this.menuItems = [];
      this.fetch(rid);
    }
  };

  public filteredMenu = (filter: string) => {
    return computed(() => this.menuItems.filter((menuItem: IMenuItem) => menuItem.name.indexOf(filter) > -1));
  };

  private async fetch(rid: string) {
    this.isFetching = true;
    try {
      const ordersRef = database.ref(`/restaurants/${rid}/menu`);
      ordersRef.on("value", (snapshot: any) => {
        const data = snapshot.val();
        this.menuItems = Object.keys(data).map((menuItemKey: string) => {
          const menuItem = data[menuItemKey];
          return { iid: menuItemKey, rid, ...data[menuItemKey] } as IMenuItem;
        });
        this.isFetching = false;
      });
    } catch (error) {
      console.log("error", error);
    }
  }
}
