import {
  IAirportPickupData,
  IInRoomAmenityData,
  ILaundryData,
  IReportIssueData,
  IRoomCleaningData,
  PatchedTypes,
  ILinenChangeData,
  ICheckInData
} from "messages";
import React, { Fragment, FunctionComponent } from "react";
import { MyListItem } from "./";

export interface IServiceRequestListItemProps {
  defaultFullInfo?: boolean;
  isUpdated?: boolean;
  render?: (props: {}) => JSX.Element;
  request: PatchedTypes.IServiceRequest;
  self?: boolean;
  handleThumbnailClick?(images: string[], index: number): void;
}

const ServiceRequestListItem: FunctionComponent<IServiceRequestListItemProps> = (
  props: IServiceRequestListItemProps
) => {
  const { defaultFullInfo, handleThumbnailClick, isUpdated, render, request, self } = props;
  const {
    airportPickup,
    bookingNo,
    createdAt,
    checkIn,
    data,
    guestSLATime,
    inRoomAmenity,
    laundry,
    linenChange,
    l1Time,
    roomCleaning,
    roomNo,
    reportIssue,
    staffs
  } = request;
  return (
    <Fragment>
      {request && request.type === "ANYWHERE_CHECKIN" && (
        <MyListItem
          defaultFullInfo={defaultFullInfo}
          bookingNo={bookingNo}
          checkInData={checkIn as ICheckInData}
          createdAt={createdAt}
          guestSLATime={!self ? guestSLATime : undefined}
          handleThumbnailClick={handleThumbnailClick}
          staffSLATime={self ? l1Time : undefined}
          staffSLATimeForEscalation={l1Time}
          iconUrl="/img/check-in.svg"
          isUpdated={isUpdated}
          render={render}
          roomNo={roomNo}
          staff={!self && staffs && staffs.length > 0 ? staffs[0] : undefined}
          status={status}
        />
      )}
      {request && request.type === "AIRPORT_PICKUP" && (
        <MyListItem
          defaultFullInfo={defaultFullInfo}
          bookingNo={bookingNo}
          airportPickupData={airportPickup as IAirportPickupData}
          createdAt={createdAt}
          guestSLATime={!self ? guestSLATime : undefined}
          handleThumbnailClick={handleThumbnailClick}
          staffSLATime={self ? l1Time : undefined}
          staffSLATimeForEscalation={l1Time}
          iconUrl="/img/airport-pickup.svg"
          isUpdated={isUpdated}
          render={render}
          roomNo={roomNo}
          staff={!self && staffs && staffs.length > 0 ? staffs[0] : undefined}
          status={status}
        />
      )}
      {request && request.type === "AMENITIES" && (
        <MyListItem
          defaultFullInfo={defaultFullInfo}
          amenitiesData={inRoomAmenity as IInRoomAmenityData}
          bookingNo={bookingNo}
          createdAt={createdAt}
          guestSLATime={!self ? guestSLATime : undefined}
          staffSLATime={self ? l1Time : undefined}
          staffSLATimeForEscalation={l1Time}
          iconUrl="/img/amenities.svg"
          isUpdated={isUpdated}
          render={render}
          roomNo={roomNo}
          staff={!self && staffs && staffs.length > 0 ? staffs[0] : undefined}
          status={status}
        />
      )}
      {request && request.type === "ROOM_CLEANING" && (
        <MyListItem
          roomCleaningData={roomCleaning as IRoomCleaningData}
          bookingNo={bookingNo}
          createdAt={createdAt}
          guestSLATime={!self ? guestSLATime : undefined}
          staffSLATime={self ? l1Time : undefined}
          staffSLATimeForEscalation={l1Time}
          iconUrl="/img/cleaning.svg"
          isUpdated={isUpdated}
          render={render}
          roomNo={roomNo}
          staff={!self && staffs && staffs.length > 0 ? staffs[0] : undefined}
          status={status}
        />
      )}
      {request && request.type === "LINEN_CHANGE" && (
        <MyListItem
          linenChangeData={linenChange as ILinenChangeData}
          bookingNo={bookingNo}
          createdAt={createdAt}
          guestSLATime={!self ? guestSLATime : undefined}
          staffSLATime={self ? l1Time : undefined}
          staffSLATimeForEscalation={l1Time}
          iconUrl="/img/rooms.svg"
          isUpdated={isUpdated}
          render={render}
          roomNo={roomNo}
          staff={!self && staffs && staffs.length > 0 ? staffs[0] : undefined}
          status={status}
        />
      )}
      {request && request.type === "LAUNDRY" && (
        <MyListItem
          bookingNo={bookingNo}
          createdAt={createdAt}
          guestSLATime={!self ? guestSLATime : undefined}
          staffSLATime={self ? l1Time : undefined}
          staffSLATimeForEscalation={l1Time}
          iconUrl="/img/laundry.svg"
          laundryData={laundry as ILaundryData}
          isUpdated={isUpdated}
          render={render}
          roomNo={roomNo}
          staff={!self && staffs && staffs.length > 0 ? staffs[0] : undefined}
          status={status}
        />
      )}
      {request && request.type === "MAINTENANCE_ISSUE" && (
        <MyListItem
          bookingNo={bookingNo}
          createdAt={createdAt}
          guestSLATime={guestSLATime}
          // staffSLATime={self ? l1Time : undefined}
          // staffSLATimeForEscalation={l1Time}
          handleThumbnailClick={handleThumbnailClick}
          iconUrl="/img/maintenance.svg"
          maintenanceIssueData={reportIssue as IReportIssueData}
          // isUpdated={isUpdated}
          render={render}
          roomNo={roomNo}
          staff={!self && staffs && staffs.length > 0 ? staffs[0] : undefined}
          status={status}
        />
      )}
    </Fragment>
  );
};

export default ServiceRequestListItem;
