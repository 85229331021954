import { IBucketItem, PatchedTypes } from "messages";
import React, { Fragment, FunctionComponent } from "react";
import TimeAgo from "react-timeago";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import { ISelectedItem } from "../../containers/RestaurantOrdersScreen";
import { AlertDialog } from "../AlertDialog";
import BucketItemData from "../OrderListItem/bucket-item-data";

export interface ICancelItemModalProps {
  order: PatchedTypes.IOrder;
  title: string;
  handleCancel(handleClose: () => void): void;
  handleItemSelect(selectedItem: ISelectedItem, handleClose: () => void): void;
}

const CancelItemModal: FunctionComponent<ICancelItemModalProps> = (props: ICancelItemModalProps) => {
  const {
    handleCancel,
    handleItemSelect,
    order,
    order: { buckets },
    title
  } = props;
  return (
    <AlertDialog
      render={({ handleClose }) => (
        <Fragment>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <List dense>
              {buckets &&
                buckets.map((bucket: PatchedTypes.IBucket, bucketIndex: number) => (
                  <Fragment key={`${bucket.bid}#${bucketIndex}`}>
                    {bucket.items &&
                      bucket.items.map((item: IBucketItem, itemIndex: number) => (
                        <Fragment key={`${item.iid}#${itemIndex}`}>
                          <ListItem
                            button
                            disabled={item.cancelled === true ? true : false}
                            onClick={() =>
                              handleItemSelect({ bucket, bucketIndex, index: itemIndex, item, order }, handleClose)
                            }
                          >
                            <ListItemText
                              primary={
                                <Fragment>
                                  {itemIndex === 0 && (
                                    <Typography color="primary">
                                      <TimeAgo date={bucket.createdAt} />
                                    </Typography>
                                  )}
                                  <BucketItemData item={item} defaultFullInfo />
                                </Fragment>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </Fragment>
                      ))}
                  </Fragment>
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => handleCancel(handleClose)}>
              CANCEL
            </Button>
          </DialogActions>
        </Fragment>
      )}
    />
  );
};

export default CancelItemModal;
