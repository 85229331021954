import { IBucketItem, PatchedTypes } from "messages";
import { action, computed, observable } from "mobx";
import { database } from "../services";

export class OrderStore {
  @observable public closedOrders: PatchedTypes.IOrder[] = [];
  @observable public orders: PatchedTypes.IOrder[] = [];
  @observable public isFetching: boolean = false;
  @observable public rid?: string;

  constructor(private orderNotificationTone: HTMLAudioElement) {}

  @action public setRid = (rid: string) => {
    if (!this.rid) {
      this.rid = rid;
      this.orders = [];
      this.closedOrders = [];
      this.fetch(rid);
      this.fetchClosed(rid);
    }
  };

  @action public changeRid = (rid: string) => {
    if (this.rid !== rid) {
      this.rid = rid;
      this.orders = [];
      this.closedOrders = [];
      this.fetch(rid);
      this.fetchClosed(rid);
    }
  };

  @computed public get activeOrders() {
    const filteredActiveOrders = this.orders
      .map((order: PatchedTypes.IOrder) => {
        const { buckets } = order;
        if (buckets && buckets.length) {
          const acceptedBuckets = buckets.filter(
            (bucket: PatchedTypes.IBucket) => bucket.accepted && bucket.acceptedAt && bucket.acceptedBy
          );
          return { ...order, buckets: acceptedBuckets } as PatchedTypes.IOrder;
        }
      })
      .filter(this.orderFilter) as PatchedTypes.IOrder[];
    return filteredActiveOrders.sort(this.activeOrderSort);
  }

  @computed public get newOrders() {
    return this.orders
      .map((order: PatchedTypes.IOrder) => {
        const { buckets } = order;
        if (buckets && buckets.length) {
          const newbuckets = buckets.filter(
            (bucket: PatchedTypes.IBucket) => !(bucket.accepted && bucket.acceptedAt && bucket.acceptedBy)
          );
          return { ...order, buckets: newbuckets } as PatchedTypes.IOrder;
        }
      })
      .filter(this.orderFilter) as PatchedTypes.IOrder[];
  }

  public getOrderForStation(sid: string) {
    return this.orders.filter(order => order.sid === sid);
  }

  public acceptOrderBucket = async ({
    bucket,
    order,
    sfid
  }: {
    bucket: PatchedTypes.IBucket;
    order: PatchedTypes.IOrder;
    sfid: string;
  }) => {
    const { bid } = bucket;
    const { oid } = order;
    await database.ref(`/orders/${oid}/buckets/${bid}`).update({
      accepted: true,
      acceptedAt: Date.now(),
      acceptedBy: sfid
    } as PatchedTypes.IBucket);
  };

  public cancelItem = async ({
    bucket,
    index,
    item,
    order,
    reason,
    sfid
  }: {
    bucket: PatchedTypes.IBucket;
    index: number;
    item: IBucketItem;
    order: PatchedTypes.IOrder;
    reason?: string;
    sfid: string;
  }) => {
    const { bid } = bucket;
    const { oid } = order;
    const now = Date.now();
    await database.ref(`/orders/${oid}/buckets/${bid}/items/${index}`).update({
      ...item,
      cancelled: true,
      cancelledAt: now,
      cancelledBy: sfid,
      reason
    } as IBucketItem);
  };

  public closeOrderBucket = async ({ order, sfid }: { order: PatchedTypes.IOrder; sfid: string }) => {
    const { oid, rid } = order;
    const closedAt = Date.now();
    await database.ref(`/orders/${oid}`).update({
      ...order,
      // tslint:disable-next-line: object-literal-key-quotes
      closed: true,
      closedAt,
      // tslint:disable-next-line: object-literal-key-quotes
      closedBy: sfid,
      "rid-closed-closedAt": `${rid}-${true}-${closedAt}`
    } as PatchedTypes.IOrder);
  };

  public rejectOrderBucket = async ({
    bucket,
    order,
    reason,
    sfid
  }: {
    bucket: PatchedTypes.IBucket;
    order: PatchedTypes.IOrder;
    reason?: string;
    sfid: string;
  }) => {
    const { bid, items } = bucket;
    const { oid } = order;
    const now = Date.now();
    const update = {
      ...bucket,
      accepted: true,
      acceptedAt: now,
      acceptedBy: sfid,
      items: items!.map(item => ({
        ...item,
        cancelled: true,
        cancelledAt: now,
        cancelledBy: sfid,
        reason
      }))
    } as PatchedTypes.IBucket;
    await database.ref(`/orders/${oid}/buckets/${bid}`).update(update);
  };

  private async fetchClosed(rid: string) {
    this.isFetching = true;
    try {
      const ordersRef = database
        .ref("/orders/")
        .orderByChild("rid-closed-closedAt")
        .startAt(`${rid}-true-`)
        .endAt(`${rid}-true-\uf8ff`)
        .limitToLast(50);
      ordersRef.on("value", (snapshot: any) => {
        const data = snapshot.val() || [];
        this.closedOrders = Object.keys(data)
          .map((orderKey: string) => {
            const order = data[orderKey];
            if (order.buckets) {
              const buckets = Object.keys(order.buckets).map((bucketKey: string) => {
                return { bid: bucketKey, ...order.buckets[bucketKey] } as PatchedTypes.IBucket;
              });
              return { oid: orderKey, ...data[orderKey], buckets } as PatchedTypes.IOrder;
            }
            return { oid: orderKey, ...data[orderKey] } as PatchedTypes.IOrder;
          })
          .reverse();
        this.isFetching = false;
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  private async fetch(rid: string) {
    this.isFetching = true;
    try {
      const ordersRef = database
        .ref("/orders/")
        .orderByChild("rid-closed-closedAt")
        .equalTo(`${rid}-false`);
      ordersRef.on("value", (snapshot: any) => {
        const data = snapshot.val();
        this.orders = Object.keys(data).map((orderKey: string) => {
          const order = data[orderKey];
          if (order.buckets) {
            const buckets = Object.keys(order.buckets).map((bucketKey: string) => {
              return { bid: bucketKey, ...order.buckets[bucketKey] } as PatchedTypes.IBucket;
            });
            return { oid: orderKey, ...data[orderKey], buckets } as PatchedTypes.IOrder;
          }
          return { oid: orderKey, ...data[orderKey] } as PatchedTypes.IOrder;
        });
        this.isFetching = false;
      });
      ordersRef.on("child_added", this.onNewOrder);
    } catch (error) {
      console.log("error", error);
    }
  }

  private orderFilter(order: PatchedTypes.IOrder | undefined): boolean {
    if (order && order.buckets && order.buckets.length) {
      return true;
    }
    return false;
  }

  private activeOrderSort(order1: PatchedTypes.IOrder, order2: PatchedTypes.IOrder) {
    return parseInt(order1.sid, 10) - parseInt(order2.sid, 10);
  }

  private onNewOrder = () => {
    if (!this.isFetching) {
      this.orderNotificationTone.play();
    }
  };
}
