import { action, observable } from "mobx";
import { SyntheticEvent } from "react";

interface IToastData {
  title: string;
  body: string;
}

export class ToastStore {
  @observable public isOpen: boolean = false;
  @observable public toast?: IToastData;

  constructor() {
    this.closeToast = this.closeToast.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openToast = this.openToast.bind(this);
  }

  @action public closeToast() {
    this.isOpen = false;
  }

  @action public handleClose(event: SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    this.isOpen = false;
  }

  @action public openToast(toast: IToastData) {
    this.toast = toast;
    this.isOpen = true;
  }
}
