import { IAssignedStaffInfo, PatchedTypes } from "messages";
import React, { Component, Context, Fragment } from "react";

import {
  createStyles,
  DialogContentText,
  Fab,
  List,
  ListSubheader,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { FabProps } from "@material-ui/core/Fab";
import { ListSubheaderProps } from "@material-ui/core/ListSubheader";
import AcceptIcon from "@material-ui/icons/Check";
import RejectIcon from "@material-ui/icons/Close";

import { FullscreenGalleryDialog } from "../../components/FullscreenGalleryDialog";
import { RequestListItemPlaceholder } from "../../components/Placeholders";
import { RequestOperationConfirmationModal } from "../../components/RequestOperationConfirmationModal";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { ServiceRequestListItem } from "../../components/ServiceRequestListItem";
import { ApiService } from "../../services";
import styled from "../../styles/styled-components";
import { IState, StateContext } from "../StateContext";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
`;

const StyledListSubheader = styled(ListSubheader as React.SFC<ListSubheaderProps>)`
  background-image: linear-gradient(279deg, #ffd273, #ffde3b);
  color: #000000;
`;

const StyledFab = styled(Fab as React.SFC<FabProps>)`
  margin-right: 8px !important;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    }
  });

export interface IMyRequestsScreenProps extends WithStyles<typeof styles> {}

export interface IMyRequestsScreenState {
  acceptRequest?: PatchedTypes.IServiceRequest;
  completeRequest?: PatchedTypes.IServiceRequest;
  rejectRequest?: PatchedTypes.IServiceRequest;
  isLoading: boolean;
  showFullscreenGalleryDialog?: {
    imgUrls: string | string[];
    clickedStep: number;
  };
}

class MyRequestsScreen extends Component<IMyRequestsScreenProps, IMyRequestsScreenState> {
  public static contextType: Context<IState> = StateContext;

  public state: IMyRequestsScreenState = {
    isLoading: false
  };

  public componentDidMount = () => {
    const { markAllMyRequestNotificationsAsRead, setIsMyRequestsOpenOnUI }: IState = this.context;
    setIsMyRequestsOpenOnUI(true);
    setTimeout(markAllMyRequestNotificationsAsRead, 10000);
  };

  public componentWillUnmount = () => {
    const { setIsMyRequestsOpenOnUI }: IState = this.context;
    setIsMyRequestsOpenOnUI(false);
  };

  public handleAcceptRequest = (request: PatchedTypes.IServiceRequest) => {
    this.setState({ acceptRequest: request });
  };

  public handleRejectRequest = (request: PatchedTypes.IServiceRequest) => {
    this.setState({ rejectRequest: request });
  };

  public handleCompleteRequest = (request: PatchedTypes.IServiceRequest) => {
    this.setState({ completeRequest: request });
  };

  public handleCompleteRequestConfirmationAccept = async (closeModal: () => void) => {
    const { completeRequest } = this.state;
    if (completeRequest) {
      try {
        this.setState({ isLoading: true });
        const { serreqid } = completeRequest;
        await ApiService.setServiceRequestStatus(serreqid as string, "COMPLETED");
        closeModal();
        this.setState({ completeRequest: undefined, isLoading: false });
      } catch (error) {
        closeModal();
        this.setState({ completeRequest: undefined, isLoading: false });
        // TODO: Add a toast saying Something went wrong! Please, try again later.
      }
    }
  };

  public handleCompleteRequestConfirmationDecline = (closeModal: () => void) => {
    closeModal();
    this.setState({ completeRequest: undefined });
  };

  public handleAcceptRequestConfirmationAccept = async (closeModal: () => void) => {
    const { acceptRequest } = this.state;
    if (acceptRequest) {
      try {
        this.setState({ isLoading: true });
        const { serreqid } = acceptRequest;
        await ApiService.setServiceRequestStatus(serreqid as string, "IN_PROGRESS");
        closeModal();
        this.setState({ acceptRequest: undefined, isLoading: false });
      } catch (error) {
        closeModal();
        this.setState({ acceptRequest: undefined, isLoading: false });
        // TODO: Add a toast saying Something went wrong! Please, try again later.
      }
    }
  };

  public handleAcceptRequestConfirmationDecline = (closeModal: () => void) => {
    closeModal();
    this.setState({ acceptRequest: undefined });
  };

  public handleRejectRequestConfirmationAccept = async (closeModal: () => void) => {
    const { rejectRequest } = this.state;
    if (rejectRequest) {
      try {
        this.setState({ isLoading: true });
        const { serreqid } = rejectRequest;
        await Promise.all([
          ApiService.removeStaffFromServiceRequest(serreqid as string),
          ApiService.setServiceRequestStatus(serreqid as string, "NEW")
        ]);
        closeModal();
        this.setState({ rejectRequest: undefined, isLoading: false });
      } catch (error) {
        closeModal();
        this.setState({ rejectRequest: undefined, isLoading: false });
        // TODO: Add a toast saying Something went wrong! Please, try again later.
      }
    }
  };

  public handleRejectRequestConfirmationDecline = (closeModal: () => void) => {
    closeModal();
    this.setState({ rejectRequest: undefined });
  };

  public handleThumbnailClick = (images: string[], index: number) => {
    this.setState({ showFullscreenGalleryDialog: { imgUrls: images, clickedStep: index } });
  };

  public handleFullscreenGalleryDialogClose = () => {
    this.setState({ showFullscreenGalleryDialog: undefined });
  };

  public render() {
    const { isRequestsLoading, myRequestNotifications, serviceRequests, sfid }: IState = this.context;
    const myRequests = Array.from(serviceRequests.values()).filter((request: PatchedTypes.IServiceRequest) => {
      const { staffs } = request;
      if (!staffs) {
        return false;
      }
      const myRequest = staffs.find((staff: IAssignedStaffInfo) => staff.sfid === sfid);
      if (!myRequest) {
        return false;
      }
      return true;
    });
    const assignedRequests = myRequests.filter(
      (request: PatchedTypes.IServiceRequest) => request.status === "ASSIGNED"
    );
    const inProgressRequests = myRequests.filter(
      (request: PatchedTypes.IServiceRequest) => request.status === "IN_PROGRESS"
    );
    const completedRequests = myRequests.filter(
      (request: PatchedTypes.IServiceRequest) => request.status === "COMPLETED"
    );
    const { classes } = this.props;
    const { acceptRequest, completeRequest, isLoading, rejectRequest, showFullscreenGalleryDialog } = this.state;
    return (
      <ResponsiveNavbar title="My requests">
        {showFullscreenGalleryDialog && (
          <FullscreenGalleryDialog
            {...showFullscreenGalleryDialog}
            open={showFullscreenGalleryDialog ? true : false}
            handleClose={this.handleFullscreenGalleryDialogClose}
          />
        )}
        {completeRequest && (
          <RequestOperationConfirmationModal
            actionButtonLabel="COMPLETE"
            isLoading={isLoading}
            request={completeRequest}
            title="Complete request"
            handleAccept={this.handleCompleteRequestConfirmationAccept}
            handleDecline={this.handleCompleteRequestConfirmationDecline}
            handleThumbnailClick={this.handleThumbnailClick}
          >
            <DialogContentText>Are you sure you want to complete the following request?</DialogContentText>
          </RequestOperationConfirmationModal>
        )}

        {acceptRequest && (
          <RequestOperationConfirmationModal
            actionButtonLabel="ACCEPT"
            isLoading={isLoading}
            request={acceptRequest}
            title="Accept request"
            handleAccept={this.handleAcceptRequestConfirmationAccept}
            handleDecline={this.handleAcceptRequestConfirmationDecline}
            handleThumbnailClick={this.handleThumbnailClick}
          >
            <DialogContentText>Are you sure you want to accept the following request?</DialogContentText>
          </RequestOperationConfirmationModal>
        )}

        {rejectRequest && (
          <RequestOperationConfirmationModal
            actionButtonLabel="REJECT"
            isLoading={isLoading}
            request={rejectRequest}
            title="Reject request"
            handleAccept={this.handleRejectRequestConfirmationAccept}
            handleDecline={this.handleRejectRequestConfirmationDecline}
            handleThumbnailClick={this.handleThumbnailClick}
          >
            <DialogContentText>Are you sure you want to reject the following request?</DialogContentText>
          </RequestOperationConfirmationModal>
        )}

        <Body>
          <List className={classes.root} dense subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <StyledListSubheader color="inherit">Assigned to me</StyledListSubheader>
                {!assignedRequests.length && (
                  <RequestListItemPlaceholder isLoading={isRequestsLoading} noOfLoadingItems={2} />
                )}
                {assignedRequests.map((request: PatchedTypes.IServiceRequest, index: number) => (
                  <ServiceRequestListItem
                    handleThumbnailClick={this.handleThumbnailClick}
                    isUpdated={myRequestNotifications.has(request.serreqid!)}
                    key={`${request.serreqid}-${index}`}
                    request={request}
                    self
                    render={({}) => (
                      <Fragment>
                        <StyledFab
                          aria-label="Reject request"
                          color="secondary"
                          size="small"
                          onClick={() => this.handleRejectRequest(request)}
                        >
                          <RejectIcon />
                        </StyledFab>
                        <StyledFab
                          aria-label="Accept request"
                          color="primary"
                          size="small"
                          onClick={() => this.handleAcceptRequest(request)}
                        >
                          <AcceptIcon />
                        </StyledFab>
                      </Fragment>
                    )}
                  />
                ))}
              </ul>
            </li>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <StyledListSubheader color="inherit">In-progress requests</StyledListSubheader>
                {!inProgressRequests.length && (
                  <RequestListItemPlaceholder isLoading={isRequestsLoading} noOfLoadingItems={2} />
                )}
                {inProgressRequests.map((request: PatchedTypes.IServiceRequest, index: number) => (
                  <ServiceRequestListItem
                    handleThumbnailClick={this.handleThumbnailClick}
                    isUpdated={myRequestNotifications.has(request.serreqid!)}
                    key={`${request.serreqid}-${index}`}
                    request={request}
                    self
                    render={({}) => (
                      <Fragment>
                        <StyledFab
                          aria-label="Complete request"
                          color="primary"
                          size="small"
                          onClick={() => this.handleCompleteRequest(request)}
                        >
                          <AcceptIcon />
                        </StyledFab>
                      </Fragment>
                    )}
                  />
                ))}
              </ul>
            </li>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <StyledListSubheader color="inherit">Completed requests</StyledListSubheader>
                {!completedRequests.length && (
                  <RequestListItemPlaceholder isLoading={isRequestsLoading} noOfLoadingItems={2} />
                )}
                {completedRequests.map((request: PatchedTypes.IServiceRequest, index: number) => (
                  <ServiceRequestListItem
                    handleThumbnailClick={this.handleThumbnailClick}
                    isUpdated={myRequestNotifications.has(request.serreqid!)}
                    key={`${request.serreqid}-${index}`}
                    request={request}
                    self
                  />
                ))}
              </ul>
            </li>
          </List>
        </Body>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MyRequestsScreen);
