import { StaffClient } from "messaging";
import { action, observable } from "mobx";
import { getConnectionOptions } from "../utils/index";

export class ClientStore {
  @observable public mqttClientError?: Error;
  @observable public isConnectedToMQTTBroker: boolean = false;

  private client?: StaffClient;

  constructor() {
    this.onConnected = this.onConnected.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);
    this.getClient = this.getClient.bind(this);

    this.client = new StaffClient({
      ...getConnectionOptions(),
      onConnected: this.onConnected,
      onConnectionLost: this.onConnectionLost
    });

    this.client.connect();

    this.client.on("error", this.setMqttClientError);
  }

  // NOTE: We can use reconnected boolean to do something specific for reconnection
  // but for now we are not using it.
  public onConnected(reconnected: boolean, uri: string) {
    this.isConnectedToMQTTBroker = true;
  }

  // NOTE: We can use the errorCode and errorMessage to do something specific for different cases
  // but for now we are not using it.
  public onConnectionLost(error: { errorCode: number; errorMessage: string }) {
    this.isConnectedToMQTTBroker = false;
  }

  public getClient(): StaffClient | undefined {
    if (this.isConnectedToMQTTBroker) {
      return this.client;
    }
    return;
  }

  @action private setMqttClientError = (error: Error) => {
    this.mqttClientError = error;
  };
}
