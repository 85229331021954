import React, { Component } from "react";

import { Dialog } from "@material-ui/core";

export interface IAlertDialogProps {
  render: (props: { handleClose: () => void }) => JSX.Element;
  handleClose?: () => void
}

export interface IAlertDialogState {
  open: boolean;
}

class AlertDialog extends Component<IAlertDialogProps, IAlertDialogState> {
  public state: IAlertDialogState = {
    open: true
  };

  public handleClose = () => {
    this.setState({ open: false });
  };

  public render() {
    const { render } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" {...this.props.handleClose && { onClose: this.props.handleClose }}>
          {render({ handleClose: this.handleClose })}
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
