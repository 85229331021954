import { IStaffInfo, PatchedTypes } from "messages";
import React, { Component, Context, Fragment } from "react";

import {
  Chip,
  createStyles,
  DialogContentText,
  Fab,
  List,
  ListSubheader,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import { FabProps } from "@material-ui/core/Fab";
import { ListSubheaderProps } from "@material-ui/core/ListSubheader";
import AcceptIcon from "@material-ui/icons/Check";

import { FullscreenGalleryDialog } from "../../components/FullscreenGalleryDialog";
import { RequestListItemPlaceholder } from "../../components/Placeholders";
import { RequestOperationConfirmationModal } from "../../components/RequestOperationConfirmationModal";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { ServiceRequestListItem } from "../../components/ServiceRequestListItem";
import { ApiService } from "../../services";
import styled from "../../styles/styled-components";
import { IState, StateContext } from "../StateContext";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
`;

const StyledListSubheader = styled(ListSubheader as React.SFC<ListSubheaderProps>)`
  background-image: linear-gradient(279deg, #ffd273, #ffde3b);
  color: #000000;
`;

const StyledFab = styled(Fab as React.SFC<FabProps>)`
  margin-right: 8px !important;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    }
  });

export interface ITasksScreenProps extends WithStyles<typeof styles> {}

export interface ITasksScreenState {
  completeRequest?: PatchedTypes.IServiceRequest;
  isLoading: boolean;
  showFullscreenGalleryDialog?: {
    imgUrls: string | string[];
    clickedStep: number;
  };
}

class TasksScreen extends Component<ITasksScreenProps, ITasksScreenState> {
  public static contextType: Context<IState> = StateContext;

  public state: ITasksScreenState = {
    isLoading: false
  };

  // NOTE: For now reusing the dashboardNotifications for setting the badge in task screen
  public componentDidMount = () => {
    const { markAllDashboardNotificationsAsRead, setIsDashboardOpenOnUI }: IState = this.context;
    setIsDashboardOpenOnUI(true);
    setTimeout(markAllDashboardNotificationsAsRead, 10000);
  };

  // NOTE: For now reusing the dashboardNotifications for setting the badge in task screen
  public componentWillUnmount = () => {
    const { setIsDashboardOpenOnUI }: IState = this.context;
    setIsDashboardOpenOnUI(false);
  };

  public handleCompleteRequest = (request: PatchedTypes.IServiceRequest) => {
    this.setState({ completeRequest: request });
  };

  public handleCompleteRequestConfirmationAccept = async (closeModal: () => void) => {
    const { completeRequest } = this.state;
    if (completeRequest) {
      try {
        this.setState({ isLoading: true });
        const { serreqid } = completeRequest;
        await ApiService.setServiceRequestStatus(serreqid as string, "COMPLETED");
        closeModal();
        this.setState({ completeRequest: undefined, isLoading: false });
      } catch (error) {
        closeModal();
        this.setState({ completeRequest: undefined, isLoading: false });
        // TODO: Add a toast saying Something went wrong! Please, try again later.
      }
    }
  };

  public handleCompleteRequestConfirmationDecline = (closeModal: () => void) => {
    closeModal();
    this.setState({ completeRequest: undefined });
  };

  public handleThumbnailClick = (images: string[], index: number) => {
    this.setState({ showFullscreenGalleryDialog: { imgUrls: images, clickedStep: index } });
  };

  public handleFullscreenGalleryDialogClose = () => {
    this.setState({ showFullscreenGalleryDialog: undefined });
  };

  public render() {
    const { dashboardNotifications, isRequestsLoading, serviceRequests }: IState = this.context;
    const newRequests = Array.from(serviceRequests.values()).filter(
      (request: PatchedTypes.IServiceRequest) => request.status !== "COMPLETED"
    );
    const { classes } = this.props;
    const { isLoading, showFullscreenGalleryDialog, completeRequest } = this.state;
    return (
      <ResponsiveNavbar title="Tasks">
        {showFullscreenGalleryDialog && (
          <FullscreenGalleryDialog
            {...showFullscreenGalleryDialog}
            open={showFullscreenGalleryDialog ? true : false}
            handleClose={this.handleFullscreenGalleryDialogClose}
          />
        )}
        {completeRequest && (
          <RequestOperationConfirmationModal
            actionButtonLabel="COMPLETE"
            isLoading={isLoading}
            request={completeRequest}
            title="Complete request"
            handleAccept={this.handleCompleteRequestConfirmationAccept}
            handleDecline={this.handleCompleteRequestConfirmationDecline}
            handleThumbnailClick={this.handleThumbnailClick}
          >
            <DialogContentText>Are you sure you want to complete the following request?</DialogContentText>
          </RequestOperationConfirmationModal>
        )}
        <Body>
          <List className={classes.root} dense subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                {!newRequests.length && (
                  <RequestListItemPlaceholder isLoading={isRequestsLoading} noOfLoadingItems={2} />
                )}
                {newRequests.map((request: PatchedTypes.IServiceRequest, index: number) => (
                  <ServiceRequestListItem
                    handleThumbnailClick={this.handleThumbnailClick}
                    key={`${request.serreqid}-${index}`}
                    request={request}
                    isUpdated={dashboardNotifications.has(request.serreqid!)}
                    render={() => (
                      <Fragment>
                        <StyledFab
                          aria-label="Complete request"
                          color="primary"
                          size="small"
                          onClick={() => this.handleCompleteRequest(request)}
                        >
                          <AcceptIcon />
                        </StyledFab>
                      </Fragment>
                    )}
                  />
                ))}
              </ul>
            </li>
          </List>
        </Body>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TasksScreen);
