import { GuestChatSession } from "messaging";
export class GuestChatSessionPersistenceService {
  public static hostName: string = process.env.REACT_APP_API_URL as string;
  public static async historySessions(
    tid: string,
    pid: string,
    sfid: string,
    page: number = 0,
    noOfRecords: number = 20
  ): Promise<GuestChatSession[]> {
    const response = await fetch(
      `${this.hostName}/sessions-guest-chat/history/${tid}/${pid}/${sfid}?page=${page}&noOfRecords=${noOfRecords}`
    );
    return await response.json();
  }
}
