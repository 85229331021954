import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";

import { CssBaseline, Drawer, Hidden, IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";

import { ToastStore } from "../../stores";
import { Navbar } from "../Navbar";
import { NotificationTone } from "../NotificationTone";
import { SideDrawer } from "../SideDrawer";

export const drawerWidth = 240;

const styles = ({ breakpoints: { up }, mixins: { toolbar } }: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexFlow: "column",
      flexGrow: 1
    },
    drawer: {
      [up("sm")]: {
        flexShrink: 0,
        width: drawerWidth
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    menuButton: {
      [up("sm")]: {
        display: "none"
      },
      marginRight: 20
    },
    root: {
      display: "flex"
    },
    toolbar
  });

export interface IResponsiveNavbarState {
  mobileOpen: boolean;
}

export interface IResponsiveNavbarProps extends WithStyles<typeof styles> {
  render?: () => JSX.Element | undefined;
  title: string;
}

@inject("toastStore")
@observer
class ResponsiveNavbar extends Component<IResponsiveNavbarProps & { toastStore?: ToastStore }, IResponsiveNavbarState> {
  public state = {
    mobileOpen: false
  };

  public handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  public render() {
    const { children, classes, title, render } = this.props;
    const { isOpen, handleClose, toast } = this.props.toastStore!;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Navbar title={title || "Responsive drawer"} onMenuClick={this.handleDrawerToggle} render={render} />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <SideDrawer />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              <SideDrawer />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Snackbar
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom"
            }}
            open={isOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={
              toast && (
                <Fragment>
                  <span id="message-id">
                    {toast.title}
                    <br />
                    {toast.body}
                  </span>
                  <NotificationTone />
                </Fragment>
              )
            }
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ]}
          />
          {children}
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ResponsiveNavbar);
