import { Provider } from "mobx-react";
import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, RouteProps, Switch } from "react-router-dom";
import { PageLoad } from "../components/PageLoad";
import {
  BookingStore,
  CheckinStore,
  ClientStore,
  MenuStore,
  MissedConversationStore,
  OrderStore,
  ReasonPickerStore,
  StationStore,
  ToastStore
} from "../stores";
import { ThemeWrapper } from "../styles/theme";
import { notificationTone } from "../utils";
import { AuthConsumer, AuthProvider, IAuth } from "./AuthContext";
import { BookingListScreen } from "./BookingListScreen";
import { BotMessagingPage } from "./BotMessagingPage";
import { ChatListScreen } from "./ChatListScreen";
import { CompletedTasksScreen } from "./CompletedTasksScreen";
import { DashboardScreen } from "./DashboardScreen";
import { HistoryListScreen } from "./HistoryListScreen";
import { HistoryOrdersScreen } from "./HistoryOrdersScreen";
import { LoginScreen } from "./LoginScreen";
import { MenuAvailabilityScreen } from "./MenuAvailabilityScreen";
import { MessagingScreen } from "./MessagingScreen";
import { MissedConversationScreen } from "./MissedConversationScreen";
import { MyEscalationScreen } from "./MyEscalationScreen";
import { MyRequestsScreen } from "./MyRequestsScreen";
import { OtherConversationScreen } from "./OtherConversationScreen";
import { RequestModal } from "./RequestModal";
import { RestaurantOrdersScreen } from "./RestaurantOrdersScreen";
import { StateProvider } from "./StateContext";
import { TasksScreen } from "./TasksScreen";

export type PrivateRouteProps = RouteProps & any;

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: RenderComponent, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(renderProps: RouteComponentProps) => (
        <AuthConsumer>
          {(auth: IAuth) => (auth.isAuthenticated ? <RenderComponent {...renderProps} /> : <Redirect to="/" />)}
        </AuthConsumer>
      )}
    />
  );
};

const checkinStore = new CheckinStore();

// NOTE: initializing all mobx stores here
const stores = {
  bookingStore: new BookingStore(checkinStore),
  checkinStore,
  clientStore: new ClientStore(),
  menuStore: new MenuStore(),
  missedConversationStore: new MissedConversationStore(),
  orderStore: new OrderStore(notificationTone),
  reasonPickerStore: new ReasonPickerStore(),
  stationStore: new StationStore(),
  toastStore: new ToastStore()
};

class App extends Component {
  constructor(props: any) {
    super(props);
  }
  public render() {
    return (
      <AuthProvider>
        <Provider {...stores}>
          <AuthConsumer>
            {({ staffRoutes, staffInfo }: IAuth) => (
              <StateProvider staffInfo={staffInfo}>
                <RequestModal />
                <Router>
                  <Switch>
                    <Route exact path="/" component={LoginScreen} />
                    {staffRoutes && staffRoutes.get("chats") && (
                      <PrivateRoute name="chats" path="/chats" component={ChatListScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("chat-histories") && (
                      <PrivateRoute name="chat-histories" path="/histories" component={HistoryListScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("missed-conversations") && (
                      <PrivateRoute
                        name="missed-conversations"
                        path="/missed-conversations"
                        component={MissedConversationScreen}
                      />
                    )}
                    {staffRoutes && staffRoutes.get("other-conversations") && (
                      <PrivateRoute
                        name="other-conversations"
                        path="/other-conversations"
                        component={OtherConversationScreen}
                      />
                    )}
                    {/* {staffRoutes && staffRoutes.get("hotel-bookings") && (
                        <PrivateRoute name="hotel-bookings" path="/bookings" component={PropertyBookingsScreen} />
                      )} */}
                    {staffRoutes && staffRoutes.get("hotel-bookings") && (
                      <PrivateRoute name="hotel-bookings" path="/bookings" component={BookingListScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("service-request-dashboard") && (
                      <PrivateRoute name="service-request-dashboard" path="/dashboard" component={DashboardScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("my-service-request") && (
                      <PrivateRoute name="my-service-request" path="/my-requests" component={MyRequestsScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("my-escalation") && (
                      <PrivateRoute name="my-escalation" path="/my-escalation" component={MyEscalationScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("tasks") && (
                      <PrivateRoute name="tasks" path="/tasks" component={TasksScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("completed-tasks") && (
                      <PrivateRoute name="completed-tasks" path="/completed-tasks" component={CompletedTasksScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("restaurant-order") && (
                      <PrivateRoute
                        name="restaurant-order"
                        path="/restaurant-orders"
                        component={RestaurantOrdersScreen}
                      />
                    )}
                    {staffRoutes && staffRoutes.get("history-order") && (
                      <PrivateRoute name="history-order" path="/history-orders" component={HistoryOrdersScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("menu-availability") && (
                      <PrivateRoute
                        name="menu-availability"
                        path="/menu-availability"
                        component={MenuAvailabilityScreen}
                      />
                    )}
                    {staffRoutes && staffRoutes.get("bot-chat") && (
                      <PrivateRoute name="bot-chat" path="/botchat" component={BotMessagingPage} />
                    )}
                    {staffRoutes && staffRoutes.get("chat-sessions") && (
                      <PrivateRoute name="chat-sessions" path="/chat/:sesid" component={MessagingScreen} />
                    )}
                    {staffRoutes && staffRoutes.get("history-sessions") && (
                      <PrivateRoute name="history-sessions" path="/history/:sesid" component={MessagingScreen} />
                    )}
                    <Route render={() => <PageLoad />} />
                  </Switch>
                </Router>
              </StateProvider>
            )}
          </AuthConsumer>
        </Provider>
      </AuthProvider>
    );
  }
}

export default ThemeWrapper(App);
