import { IBucketItem, PatchedTypes } from "messages";
import React, { ChangeEvent, Fragment, FunctionComponent } from "react";

import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  List,
  SnackbarContent
} from "@material-ui/core";

import { ThemedStyledProps } from "styled-components";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";
import { AlertDialog } from "../AlertDialog";
import { OrderListItem } from "../OrderListItem";
import BucketItemData from "../OrderListItem/bucket-item-data";
import ReasonPicker from "../OrderListItem/reason-picker";

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed} !important;
`;

export interface IOrderOperationConfirmationModalProps {
  actionButtonLabel: string;
  buckets?: PatchedTypes.IBucket[];
  cancelAnotherItem?: boolean;
  children: JSX.Element;
  disableAction?: boolean;
  isLoading: boolean;
  item?: IBucketItem;
  order?: PatchedTypes.IOrder;
  showMessageWhenDisabled?: string;
  showReasonPicker?: boolean;
  title: string;
  handleCancelAnotherItemChange?(event: ChangeEvent<HTMLInputElement>): void;
  handleAccept(handleClose: () => void): void;
  handleDecline(handleClose: () => void): void;
}

const OrderOperationConfirmationModal: FunctionComponent<IOrderOperationConfirmationModalProps> = (
  props: IOrderOperationConfirmationModalProps
) => {
  const {
    actionButtonLabel,
    buckets,
    cancelAnotherItem,
    children,
    disableAction,
    handleAccept,
    handleCancelAnotherItemChange,
    handleDecline,
    isLoading,
    item,
    order,
    showMessageWhenDisabled,
    showReasonPicker,
    title
  } = props;
  return (
    <AlertDialog
      render={({ handleClose }) => (
        <Fragment>
          <DialogTitle>{isLoading ? "Please wait" : title}</DialogTitle>
          <DialogContent>
            {!isLoading && children}
            {!isLoading && (
              <Fragment>
                <List dense>
                  {order && buckets && <OrderListItem buckets={buckets} defaultFullInfo order={order} />}
                  {item && (
                    <Fragment>
                      <BucketItemData defaultFullInfo item={item} />
                      <Divider />
                    </Fragment>
                  )}
                </List>
                {showReasonPicker && <ReasonPicker />}
                {cancelAnotherItem !== undefined && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cancelAnotherItem}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleCancelAnotherItemChange!(e)}
                        value="cancelAnotherItem"
                      />
                    }
                    label="Cancel another item"
                  />
                )}
              </Fragment>
            )}
            {isLoading && (
              <ProgressWrapper>
                <CircularProgress color="inherit" />
              </ProgressWrapper>
            )}
            {!isLoading && disableAction && showMessageWhenDisabled && (
              <SnackbarContent message={showMessageWhenDisabled} />
            )}
          </DialogContent>
          {!isLoading && (
            <DialogActions>
              <Button variant="contained" color="secondary" onClick={() => handleDecline(handleClose)}>
                CLOSE
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={disableAction}
                onClick={() => handleAccept(handleClose)}
              >
                {actionButtonLabel}
              </Button>
            </DialogActions>
          )}
        </Fragment>
      )}
    />
  );
};

export default OrderOperationConfirmationModal;
