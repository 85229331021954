import { IBucketItem, PatchedTypes } from "messages";
import { inject, observer } from "mobx-react";
import React, { Component, Context } from "react";

import {
  createStyles,
  Fab,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { ListSubheaderProps } from "@material-ui/core/ListSubheader";
import { OrderListItem } from "../../components/OrderListItem";
import { RequestListItemPlaceholder } from "../../components/Placeholders";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { OrderStore } from "../../stores";
import styled from "../../styles/styled-components";
import { IState, StateConsumer, StateContext } from "../StateContext";

import { FabProps } from "@material-ui/core/Fab";
import RestaurantIcon from "@material-ui/icons/Restaurant";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
`;

const StyledListSubheader = styled(ListSubheader as React.SFC<ListSubheaderProps>)`
  background-image: linear-gradient(279deg, #ffd273, #ffde3b);
  color: #000000;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    },
    styledFab: {
      position: "fixed",
      bottom: "30px",
      right: "30px"
    }
  });

export interface IHistoryOrdersScreenProps extends WithStyles<typeof styles> {
  orderStore: OrderStore;
}

export interface ISelectedItem {
  bucket: PatchedTypes.IBucket;
  bucketIndex: number;
  index: number;
  item: IBucketItem;
  order: PatchedTypes.IOrder;
}

export interface IHistoryOrdersScreenState {
  openSelectionDialog: boolean;
  restaurants?: any;
}

@inject("orderStore")
@observer
class HistoryOrdersScreen extends Component<IHistoryOrdersScreenProps, IHistoryOrdersScreenState> {
  public static contextType: Context<IState> = StateContext;
  public state: IHistoryOrdersScreenState = { openSelectionDialog: false };

  public componentDidMount() {
    const { hotelInfo }: IState = this.context;
    if (hotelInfo && hotelInfo.restaurants && hotelInfo.restaurants.length > 0) {
      this.setState({ restaurants: hotelInfo.restaurants });
    }
  }

  public handleRestaurantSelection = (restaurant: any) => {
    console.log("RID", restaurant.rid);
    this.setState({ openSelectionDialog: false });
    const { classes, orderStore } = this.props;
    const { changeRid } = orderStore;
    changeRid(restaurant.rid);
  };

  public handleSelectRestaurantDialog = () => {
    this.setState({ openSelectionDialog: true });
  };

  public render() {
    const { classes, orderStore } = this.props;
    const { closedOrders, rid, isFetching, setRid } = orderStore;
    return (
      <ResponsiveNavbar title={"History Orders"}>
        <StateConsumer>
          {({ hotelInfo }: IState) => {
            if (rid && rid !== "") {
              setRid(rid);
            } else {
              if (hotelInfo && hotelInfo.restaurants && hotelInfo.restaurants.length) {
                const {
                  restaurants: [firstRestaurant]
                } = hotelInfo;
                const { rid } = firstRestaurant;
                setRid(rid);
              }
            }
            return (
              <Body>
                {this.state.openSelectionDialog && (
                  <Dialog aria-labelledby="simple-dialog-title" open={this.state.openSelectionDialog}>
                    <DialogTitle id="simple-dialog-title">Select Restaurant</DialogTitle>
                    <List>
                      {this.state.restaurants &&
                        this.state.restaurants.map((restaurant: any) => (
                          <ListItem
                            button
                            onClick={() => this.handleRestaurantSelection(restaurant)}
                            key={restaurant.rid}
                          >
                            <ListItemText primary={restaurant.restaurantName} />
                          </ListItem>
                        ))}
                    </List>
                  </Dialog>
                )}
                {orderStore && (
                  <List className={classes.root} dense subheader={<li />}>
                    <li className={classes.listSection}>
                      <ul className={classes.ul}>
                        <StyledListSubheader color="inherit">Closed orders</StyledListSubheader>
                        {closedOrders.length === 0 && (
                          <RequestListItemPlaceholder isLoading={isFetching} noOfLoadingItems={2} />
                        )}
                        {closedOrders.map((order: PatchedTypes.IOrder) => {
                          const { buckets } = order;
                          return (
                            <OrderListItem
                              key={`order#${order.oid}`}
                              order={order}
                              buckets={buckets as PatchedTypes.IBucket[]}
                            />
                          );
                        })}
                      </ul>
                    </li>
                  </List>
                )}
                <Fab
                  color="primary"
                  aria-label="Send SMS"
                  className={classes.styledFab}
                  onClick={this.handleSelectRestaurantDialog}
                >
                  <RestaurantIcon />
                </Fab>
              </Body>
            );
          }}
        </StateConsumer>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HistoryOrdersScreen);
