import {
  AppBar,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  // Divider,
  FilledInput,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Slide,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Theme,
  TextField,
  Toolbar,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { SlideProps } from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { inject, observer } from "mobx-react";
// import moment from "moment";
import React, { Fragment, FunctionComponent } from "react";
import { FullscreenGalleryDialog } from "../FullscreenGalleryDialog";
import { CheckinStore } from "../../stores";
import { nationalityList, bedTypes, membershipTypes, paymentModes, roomTypes } from "../../utils";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    }
  });

const Transition: FunctionComponent<SlideProps> = (props: SlideProps) => {
  return <Slide direction="up" {...props} />;
};

export interface ICheckinModalProps extends WithStyles<typeof styles> {
  checkinStore?: CheckinStore;
}

const CheckinModal: FunctionComponent<ICheckinModalProps> = inject("bookingStore", "checkinStore")(
  observer((props: ICheckinModalProps) => {
    const { checkinStore, classes } = props;
    const {
      activeCheckin,
      disableSubmitForActiveCheckin,
      errorsInCheckin,
      handleFilesChange,
      handleThumbnailClick,
      hideCheckinModal,
      hideFullscreenGalleryDialog,
      isCheckinModalInEditMode,
      isCheckinModalVisible,
      reportError,
      setAddressInCheckin,
      setArrivalDateInCheckin,
      setBedTypeInCheckin,
      setBookingNoInCheckin,
      setDesignationInCheckin,
      setEmailInCheckin,
      setExtraBedInCheckin,
      setGSTNoInCheckin,
      setMembershipNoInCheckin,
      setMembershipTypeInCheckin,
      setMobileInCheckin,
      setFirstNameInCheckin,
      setNationalityInCheckin,
      setNoOfAdultsInCheckin,
      setNoOfChildrenInCheckin,
      setOrganisationInCheckin,
      setPaymentModeInCheckin,
      setRoomTypeInCheckin,
      setSpecialInstructionInCheckin,
      setVisitPurposeInCheckin,
      showFullscreenGalleryDialog,
      submitActiveCheckin,
      toggleIsSmokingRoomInCheckin
    } = checkinStore!;
    return (
      <Fragment>
        {showFullscreenGalleryDialog && (
          <FullscreenGalleryDialog
            {...showFullscreenGalleryDialog}
            open={showFullscreenGalleryDialog ? true : false}
            handleClose={hideFullscreenGalleryDialog}
          />
        )}
        <Dialog open={isCheckinModalVisible} fullScreen TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" aria-label="Close" onClick={hideCheckinModal}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {!isCheckinModalInEditMode && "Add a checkin"}
                {isCheckinModalInEditMode && "Edit a checkin"}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Stepper activeStep={1} alternativeLabel>
              <Step>
                <StepLabel>Booking</StepLabel>
              </Step>
              <Step>
                <StepLabel>Checkin</StepLabel>
              </Step>
            </Stepper>
            {/* <Button color="secondary" variant="contained" aria-label="Skip Checkin" onClick={hideCheckinModal}>
              SKIP CHECKIN FOR THIS BOOKING
            </Button>
            <br />
            <br />
            <Divider />
            <br />
            <br /> */}
            <form noValidate autoComplete="off" onSubmit={submitActiveCheckin}>
              {/* <FormGroup>
                <FormLabel>
                  <Typography color="primary">Guest personal details</Typography>
                </FormLabel>
                <FormControl required error={errorsInCheckin.firstName ? true : false} margin="dense" fullWidth>
                  <TextField
                    required
                    autoFocus
                    id="first-name"
                    label="First name"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.firstName}
                    onChange={setNameInCheckin}
                    onBlur={() => reportError("firstName")}
                  />
                  {errorsInCheckin.firstName === "required" && (
                    <FormHelperText error>Name is a required field.</FormHelperText>
                  )}
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <TextField
                    id="designation"
                    label="Designation"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.designation || undefined}
                    onChange={setDesignationInCheckin}
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <TextField
                    id="organisation"
                    label="Organisation"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.organisation || undefined}
                    onChange={setOrganisationInCheckin}
                  />
                </FormControl>

                <FormControl required error={errorsInCheckin.address ? true : false} margin="dense" fullWidth>
                  <TextField
                    required
                    id="address"
                    label="Address"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.address}
                    onChange={setAddressInCheckin}
                    onBlur={() => reportError("address")}
                  />
                  {errorsInCheckin.address === "required" && (
                    <FormHelperText error>Address is a required field.</FormHelperText>
                  )}
                </FormControl>

                <FormControl error={errorsInCheckin.email ? true : false} margin="dense" fullWidth>
                  <TextField
                    id="email"
                    label="Email ID"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.email || undefined}
                    onChange={setEmailInCheckin}
                    onBlur={() => reportError("email")}
                  />
                  {errorsInCheckin.email === "invalid" && <FormHelperText error>Email ID is invalid.</FormHelperText>}
                </FormControl>

                <FormControl error={errorsInCheckin.mobile ? true : false} margin="dense" fullWidth>
                  <TextField
                    id="mobile"
                    label="Mobile number"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.mobile || undefined}
                    onChange={setMobileInCheckin}
                    onBlur={() => reportError("mobile")}
                  />
                  {errorsInCheckin.mobile === "invalid" && (
                    <FormHelperText error>Mobile number is invalid.</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  required
                  error={errorsInCheckin.nationality ? true : false}
                  variant="filled"
                  fullWidth
                  margin="dense"
                >
                  <FormLabel htmlFor="filled-nationality">Nationality</FormLabel>
                  <Select
                    required
                    autoWidth
                    value={activeCheckin.nationality || ""}
                    onChange={setNationalityInCheckin}
                    onBlur={() => reportError("nationality")}
                    input={<FilledInput name="nationality" id="filled-nationality" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {nationalityList.map((country: any, index: number) => (
                      <MenuItem key={`${country.en_short_name}-${index}`} value={country.nationality}>
                        {country.en_short_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorsInCheckin.nationality === "invalid" && (
                    <FormHelperText error>Nationality is a required field.</FormHelperText>
                  )}
                </FormControl>

                {activeCheckin.nationality === "Indian" && (
                  <Button variant="raised" color="primary" component="label">
                    Upload File(s)
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      multiple={false}
                      onChange={handleFilesChange}
                    />
                  </Button>
                )}
              </FormGroup>
              <br />
              <br /> */}

              <FormGroup>
                <FormLabel>
                  <Typography color="primary">Booking details</Typography>
                </FormLabel>
                <FormControl required error={errorsInCheckin.bookingNo ? true : false} margin="dense" fullWidth>
                  <TextField
                    required
                    disabled={true}
                    id="booking-no"
                    label="Booking number"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.bookingNo}
                    onChange={setBookingNoInCheckin}
                    onBlur={() => reportError("bookingNo")}
                  />
                  {errorsInCheckin.bookingNo === "required" && (
                    <FormHelperText error>Booking number is a required field.</FormHelperText>
                  )}
                </FormControl>

                {/* <FormControl fullWidth margin="dense">
                  <TextField
                    id="visit-purpose"
                    label="Visit purpose"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.visitPurpose || undefined}
                    onChange={setVisitPurposeInCheckin}
                  />
                </FormControl> */}

                {/* <FormControl required error={errorsInCheckin.arrivalDate ? true : false} margin="dense" fullWidth>
                  <TextField
                    required
                    id="arrival-date"
                    label="Arrival date"
                    type="date"
                    defaultValue={moment(activeCheckin.arrivalDate as number).format("YYYY-MM-DD")}
                    onChange={setArrivalDateInCheckin}
                  />
                  {errorsInCheckin.arrivalDate === "invalid" && (
                    <FormHelperText error>Arrival date is invalid.</FormHelperText>
                  )}
                </FormControl> */}

                <FormControl fullWidth margin="dense">
                  <TextField
                    id="no-of-adults"
                    label="Number of Adults"
                    inputProps={{ min: 1, max: 30 }}
                    type="number"
                    variant="filled"
                    defaultValue={activeCheckin.noOfAdults || 0}
                    onChange={setNoOfAdultsInCheckin}
                  />
                </FormControl>

                <FormControl fullWidth margin="dense">
                  <TextField
                    id="no-of-children"
                    label="Number of children"
                    inputProps={{ min: 0, max: 20 }}
                    type="number"
                    variant="filled"
                    defaultValue={activeCheckin.noOfChildren || 0}
                    onChange={setNoOfChildrenInCheckin}
                  />
                </FormControl>
              </FormGroup>
              <br />
              <br />

              <FormGroup>
                <FormLabel>
                  <Typography color="primary">Transactional details</Typography>
                </FormLabel>
                <FormControl variant="filled" fullWidth margin="dense">
                  <FormLabel htmlFor="payment-mode">Payment mode</FormLabel>
                  <Select
                    autoWidth
                    value={activeCheckin.paymentMode || ""}
                    onChange={setPaymentModeInCheckin}
                    input={<FilledInput id="payment-mode" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {paymentModes.map((mode: string, index: number) => (
                      <MenuItem key={`${mode}-${index}`} value={mode}>
                        {mode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="dense">
                  <TextField
                    id="gst-no"
                    label="GST Number"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.gstNo || undefined}
                    onChange={setGSTNoInCheckin}
                  />
                </FormControl>

                <FormControl variant="filled" fullWidth margin="dense">
                  <FormLabel htmlFor="membership-type">Membership type</FormLabel>
                  <Select
                    autoWidth
                    value={activeCheckin.membershipType || ""}
                    onChange={setMembershipTypeInCheckin}
                    input={<FilledInput id="membership-type" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {membershipTypes.map((type: string, index: number) => (
                      <MenuItem key={`${type}-${index}`} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="dense">
                  <TextField
                    id="membership-no"
                    label="Membership number"
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.membershipNo || undefined}
                    onChange={setMembershipNoInCheckin}
                  />
                </FormControl>
              </FormGroup>
              <br />
              <br />

              <FormGroup>
                <FormLabel>
                  <Typography color="primary">Room details</Typography>
                </FormLabel>
                <FormControl variant="filled" fullWidth margin="dense">
                  <FormLabel htmlFor="bed-type">Bed Type</FormLabel>
                  <Select
                    autoWidth
                    value={activeCheckin.bedType || ""}
                    onChange={setBedTypeInCheckin}
                    input={<FilledInput id="bed-type" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {bedTypes.map((type: string, index: number) => (
                      <MenuItem key={`${type}-${index}`} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="dense">
                  <TextField
                    id="extra-bed"
                    label="Extra bed"
                    inputProps={{ min: 0, max: 5 }}
                    type="number"
                    variant="filled"
                    defaultValue={activeCheckin.extraBed || 0}
                    onChange={setExtraBedInCheckin}
                  />
                </FormControl>

                <FormControl variant="filled" fullWidth margin="dense">
                  <FormLabel htmlFor="room-type">Room type</FormLabel>
                  <Select
                    autoWidth
                    value={activeCheckin.roomType || ""}
                    onChange={setRoomTypeInCheckin}
                    input={<FilledInput id="room-type" />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {roomTypes.map((type: string, index: number) => (
                      <MenuItem key={`${type}-${index}`} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <br />
                <FormGroup>
                  <FormLabel>Want a smoking room?</FormLabel>
                  <FormControl margin="dense">
                    <Switch
                      defaultChecked={activeCheckin.isSmokingRoom || false}
                      onChange={toggleIsSmokingRoomInCheckin}
                      value="checked"
                    />
                  </FormControl>
                </FormGroup>

                {/* <FormControl fullWidth margin="dense">
                  <TextField
                    id="other-requests"
                    label="Other requests"
                    multiline
                    rows={2}
                    rowsMax={3}
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.otherRequests || undefined}
                    onChange={setOtherRequestsInCheckin}
                  />
                </FormControl> */}
              </FormGroup>
              <br />
              {/* <br /> */}

              {/* <FormGroup>
                <FormLabel>
                  <Typography color="primary">Additional details</Typography>
                </FormLabel>
                <FormControl fullWidth margin="dense">
                  <TextField
                    id="special-instructions"
                    label="Special instructions"
                    multiline
                    rows={2}
                    rowsMax={3}
                    type="text"
                    variant="filled"
                    defaultValue={activeCheckin.specialInstruction || undefined}
                    onChange={setSpecialInstructionInCheckin}
                  />
                </FormControl>
              </FormGroup>
              <br />
              <br /> */}

              <Button
                disabled={disableSubmitForActiveCheckin}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                NEXT
              </Button>
              {/* <br />
              <br />
              <Button
                color="secondary"
                fullWidth
                variant="contained"
                aria-label="Skip Checkin"
                onClick={hideCheckinModal}
              >
                SKIP CHECKIN FOR THIS BOOKING
              </Button> */}
            </form>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  })
);

export default withStyles(styles, { withTheme: true })(CheckinModal);
