import { Typography } from "@material-ui/core";
import { IAirportPickupData } from "messages";
import React, { Component, MouseEvent, Fragment, FunctionComponent } from "react";
import styled from "../../styles/styled-components";
import { ThumbnailList } from "../ThumbnailList";

const StyledAnchor = styled.a`
  margin-left: 5px;
`;

export interface IAirportPickupDataProps {
  data: IAirportPickupData;
  defaultFullInfo?: boolean;
  handleThumbnailClick(images: string[], index: number): void;
}

export interface IAirportPickupDataState {
  isShowMore: boolean;
}

class AirportPickupData extends Component<IAirportPickupDataProps, IAirportPickupDataState> {
  public state: IAirportPickupDataState = { isShowMore: false };

  public handleShowMore = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ isShowMore: true });
  };

  public handleShowLess = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ isShowMore: false });
  };

  public render() {
    const {
      data: {
        cabInfo: { carType, noOfPeople, specialInstruction },
        firstName,
        flightNo,
        lastName,
        mobile,
        photo
      },
      defaultFullInfo,
      handleThumbnailClick
    } = this.props;
    const { isShowMore } = this.state;
    return (
      <Fragment>
        <Typography>
          <Typography component="p">
            {`Airport pickup - ${firstName} ${lastName}`}
            {!defaultFullInfo && isShowMore && (
              <StyledAnchor href="" onClick={this.handleShowLess}>
                show less
              </StyledAnchor>
            )}
            {!defaultFullInfo && isShowMore !== undefined && !isShowMore && (
              <StyledAnchor href="" onClick={this.handleShowMore}>
                show more
              </StyledAnchor>
            )}
          </Typography>
        </Typography>
        {(isShowMore || defaultFullInfo) && (
          <Fragment>
            <Typography component="p">{`Mobile # : ${mobile}`}</Typography>
            <Typography component="p">{`Flight # : ${flightNo}`}</Typography>
            <Typography component="p">{`# of people : ${noOfPeople}`}</Typography>
            {carType && <Typography component="p">{`Car type # : ${carType}`}</Typography>}
          </Fragment>
        )}
        {specialInstruction && `Instruction: ${specialInstruction}`}
        {photo && <ThumbnailList images={[photo]} handleThumbnailClick={handleThumbnailClick} />}
      </Fragment>
    );
  }
}

export default AirportPickupData;
