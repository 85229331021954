import {
  CircularProgress, createStyles, DialogContent, FormControl, IconButton,
  InputAdornment, InputLabel, List, OutlinedInput, Select, TextField,
  withStyles, WithStyles
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { IStation, PatchedTypes } from "messages";
import React, { ChangeEvent, Component, Fragment } from "react";

import { ThemedStyledProps } from "styled-components";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";
import { AlertDialog } from "../AlertDialog";
import { OrderListItem } from "../OrderListItem";

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed} !important;
`;

interface ISendSmsModalProps extends WithStyles<typeof styles> {
  stations: Map<string, IStation>;
  orders: PatchedTypes.IOrder[];
  handleSendSms: (oid: string, sid: string, phoneNumbers: string) => Promise<void>;
  handleClose: () => void;
}

interface ISendSmsModalState {
  selectedStation: string;
  phoneNumbers: Map<string, string>;
  isSending: boolean;
  sendingOid: string;
}

const styles = () =>
  createStyles({
    formControl: {
      margin: "1rem",
      minWidth: 120,
    },
    margin: {
      margin: "1rem",
    },
    textField: {
      display: "block"
    },
  });

class SendSmsModal extends Component<ISendSmsModalProps, ISendSmsModalState> {
  constructor(props: ISendSmsModalProps) {
    super(props)
    this.state = {
      isSending: false,
      phoneNumbers: new Map(),
      selectedStation: this.props.stations.values().next().value.sid,
      sendingOid: ""
    };
  }

  public render = () => {
    const { stations, classes, orders } = this.props;
    const selectedOrders = orders.filter(order => order.sid === this.state.selectedStation);
    return (
      <AlertDialog
        {...!this.state.isSending && { handleClose: this.props.handleClose }}
        render={() => (
          <Fragment>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="station-select">
                Table
              </InputLabel>
              <Select
                native
                disabled={this.state.isSending}
                value={this.state.selectedStation}
                onChange={this.handleStationSelect}
                input={
                  <OutlinedInput name="station" labelWidth={40} id="station-select" />
                }
              >
                {
                  Array.from(stations.values()).map((station, i) =>
                    <option id={`${station.sid}-${i}`} value={station.sid}>{station.name}</option>)
                }
              </Select>
            </FormControl>
            <DialogContent>
              {selectedOrders.length === 0 &&
                <Fragment>
                  No active order
                  <TextField
                    className={`${classes.margin} ${classes.textField}`}
                    variant="outlined"
                    type="text"
                    label="Mobile numbers"
                    disabled={this.state.isSending}
                    value={this.state.phoneNumbers.get("") || ""}
                    onChange={e => this.handlePhoneNumberEntry("", e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="send sms"
                            onClick={() => this.handleSendSmsClick("")}
                            disabled={this.state.isSending}
                          >
                            {this.state.isSending ? <CircularProgress size={20} /> : <SendIcon color="primary" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Fragment>}
              {selectedOrders.map(order =>
                <List dense>
                  <OrderListItem buckets={order.buckets} defaultFullInfo order={order} />
                  <TextField
                    id={`${order.oid}-mobile-number`}
                    className={`${classes.margin} ${classes.textField}`}
                    variant="outlined"
                    type="text"
                    label="Mobile numbers"
                    disabled={this.state.isSending}
                    value={this.state.phoneNumbers.get(order.oid) || ""}
                    onChange={e => this.handlePhoneNumberEntry(order.oid, e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="send sms"
                            onClick={() => this.handleSendSmsClick(order.oid)}
                            disabled={this.state.isSending}
                          >
                            {this.state.isSending && this.state.sendingOid === order.oid ? <CircularProgress size={20} /> : <SendIcon {...!this.state.isSending && { color: "primary" }} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </List>
              )}
            </DialogContent>
          </Fragment>
        )}
      />
    );
  };

  private handleStationSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedStation: event.target.value })
  }

  private handlePhoneNumberEntry = (oid: string, event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    this.setState({ phoneNumbers: new Map(this.state.phoneNumbers.set(oid, event.target.value)) })
  }

  private handleSendSmsClick = async (oid: string) => {
    const phoneNumbers = (this.state.phoneNumbers.get(oid) || "").replace(/,/g, " ").split(" ")
    const phoneNumbersRegExp = /^[0-9\+\- ]{8,20}$/
    if (phoneNumbers.some(phoneNumber => !phoneNumbersRegExp.test(phoneNumber))) {
      return;
    };
    this.setState({ isSending: true, sendingOid: oid })
    await this.props.handleSendSms(oid, this.state.selectedStation, phoneNumbers.join(" "));
    this.setState({ isSending: false, sendingOid: "" })
  }

}

export default withStyles(styles, { withTheme: true })(SendSmsModal);
