import { PatchedTypes } from "messages";
import { action, computed, observable } from "mobx";
import moment from "moment";
import { ChangeEvent, FormEvent } from "react";
import { database, ApiService } from "../services";
import { CheckinStore } from "./checkin";

interface IPrerequisite {
  pid: string;
  sfid: string;
  tid: string;
}

export class BookingStore {
  private reset: PatchedTypes.IHotelBooking = {
    checkInDate: new Date().getTime(),
    checkOutDate: new Date().getTime(),
    isForeignNational: false
  } as PatchedTypes.IHotelBooking;
  @observable public prerequisite?: IPrerequisite;
  @observable public isBookingModalInEditMode: boolean = false;
  @observable public isBookingModalVisible: boolean = false;
  @observable public isBookingsFetching: boolean = false;
  @observable public isLoading: boolean = false;
  @observable public activeBooking: PatchedTypes.IHotelBooking = this.reset;
  @observable public bookings: PatchedTypes.IHotelBooking[] = [];
  @observable public errorsInBooking: { [k: string]: string | undefined } = {};
  private editIndex?: number;
  private errors: { [k: string]: string | undefined } = {};

  constructor(private checkinStore: CheckinStore) {}

  @action private fetchBookings = async ({ tid, pid }: IPrerequisite) => {
    this.isBookingsFetching = true;
    try {
      const response = await ApiService.getHotelBookings(tid, pid);
      this.bookings = await response.json();
      this.isBookingsFetching = false;
    } catch (error) {
      console.log(error);
    }
  };

  @action public setPrerequisite = (prerequisite: IPrerequisite) => {
    if (this.prerequisite) {
      return;
    }
    this.prerequisite = prerequisite;
    this.fetchBookings(prerequisite);
  };

  @action public showBookingModal = (edit?: { booking: PatchedTypes.IHotelBooking; index: number }) => {
    this.editIndex = edit && edit.index ? edit.index : undefined;
    this.isBookingModalInEditMode = edit && edit.booking ? true : false;
    const { sfid, ...restPrerequisite } = this.prerequisite as IPrerequisite;
    this.activeBooking = Object.assign(
      this.activeBooking,
      restPrerequisite,
      edit && edit.booking ? edit.booking : {},
      this.isBookingModalInEditMode ? { modifiedBy: sfid } : { createdBy: sfid, modifiedBy: sfid }
    );
    this.isBookingModalVisible = true;
  };

  @action public hideBookingModal = () => {
    this.activeBooking = this.reset;
    this.errors = {};
    this.errorsInBooking = {};
    this.editIndex = undefined;
    this.isBookingModalInEditMode = false;
    this.isBookingModalVisible = false;
  };

  @action public reportError = (key: string) => {
    this.checkForErrors();
    this.errorsInBooking[key] = this.errors[key];
  };

  @action public setBookingNoInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const bookingNo = change.target.value;
    this.activeBooking.bookingNo = bookingNo.trim();
  };

  @action public setCheckInDateInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const checkInDate = new Date(change.target.value);
    this.activeBooking.checkInDate = checkInDate.getTime();
  };

  @action public setCheckOutDateInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const checkOutDate = new Date(change.target.value);
    this.activeBooking.checkOutDate = checkOutDate.getTime();
  };

  @action public setFirstNameInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const firstName = change.target.value;
    this.activeBooking.firstName = firstName.trim();
  };

  @action public setLastNameInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const lastName = change.target.value;
    this.activeBooking.lastName = lastName.trim();
  };

  @action public setEmailInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const email = change.target.value;
    this.activeBooking.email = email.trim();
  };

  @action public setMobileInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const mobile = change.target.value;
    this.activeBooking.mobile = mobile.trim();
  };

  @action public setAdditionalInfoInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const additionalInfo = change.target.value;
    this.activeBooking.additionalInfo = additionalInfo.trim();
  };

  @action public setRoomNoInBooking = (change: ChangeEvent<HTMLInputElement>) => {
    const roomNo = change.target.value;
    this.activeBooking.roomNo = roomNo.trim();
  };

  @action public submitActiveBooking = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.isLoading = true;

    this.checkinStore.setPrerequisite(this.prerequisite!);

    try {
      if (this.isBookingModalInEditMode) {
        await ApiService.updateHotelBooking(this.activeBooking);
        this.bookings[this.editIndex as number] = this.activeBooking;

        this.isLoading = false;
      } else {
        const response = await ApiService.createHotelBooking(this.activeBooking);
        const { hbkid } = await response.json();
        this.activeBooking.hbkid = hbkid;
        this.bookings.push(this.activeBooking);

        this.isLoading = false;

        const { additionalInfo, bookingNo, email, firstName, isForeignNational, lastName, mobile } = this.activeBooking;
        this.checkinStore.activeCheckin = Object.assign(
          this.checkinStore.activeCheckin,
          {
            bookingNo,
            name: `${firstName} ${lastName ? lastName : ""}`.trim()
          },
          email ? { email } : {},
          mobile ? { mobile } : {},
          isForeignNational ? { nationality: isForeignNational ? "Foreigner" : "Indian" } : {},
          additionalInfo ? { specialInstruction: additionalInfo } : {}
        );

        this.checkinStore.showCheckinModal();
      }

      this.hideBookingModal();
    } catch (error) {
      this.isLoading = false;
      // TODO: Show some toast message that the request failed
      console.log(error);
    }
  };

  @action public toggleIsCancelledInBooking = (event: FormEvent<HTMLInputElement>) => {
    this.activeBooking.isCancelled = !this.activeBooking.isCancelled;
  };

  @action public toggleIsForeignNationalInBooking = (event: FormEvent<HTMLInputElement>) => {
    this.activeBooking.isForeignNational = !this.activeBooking.isForeignNational;
  };

  public isValidDate(date: number) {
    return moment(date).isValid();
  }

  public isValidEmail(email: string) {
    const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    return emailRegex.test(email);
  }

  public isValidMobile(mobile: string) {
    const mobileRegex = new RegExp(/^[0-9\+\- ]{8,20}$/);
    return mobileRegex.test(mobile);
  }

  @computed public get disableSubmitForActiveBooking() {
    const { bookingNo, checkInDate, checkOutDate, firstName, email, mobile } = this.activeBooking;
    if (!bookingNo || (bookingNo && bookingNo.trim() === "")) {
      return true;
    }
    if (!checkInDate || (checkInDate && !this.isValidDate(checkInDate))) {
      return true;
    }
    if (!checkOutDate || (checkOutDate && !this.isValidDate(checkOutDate))) {
      return true;
    }
    if (!firstName || (firstName && firstName.trim() === "")) {
      return true;
    }
    if (email && !this.isValidEmail(email)) {
      return true;
    }
    if (mobile && !this.isValidMobile(mobile)) {
      return true;
    }
    return false;
  }

  private checkForErrors() {
    const { bookingNo, checkInDate, checkOutDate, firstName, email, mobile } = this.activeBooking;

    this.errors.bookingNo = bookingNo && bookingNo.trim() !== "" ? undefined : "required";
    this.errors.checkInDate = checkInDate && this.isValidDate(checkInDate) ? undefined : "invalid";
    this.errors.checkOutDate = checkOutDate && this.isValidDate(checkInDate) ? undefined : "invalid";
    this.errors.firstName = firstName && firstName.trim() !== "" ? undefined : "required";

    if (email && email.trim() !== "") {
      this.errors.email = this.isValidEmail(email) ? undefined : "invalid";
    } else {
      this.errors.email = undefined;
    }

    if (mobile && mobile.trim() !== "") {
      this.errors.mobile = this.isValidMobile(mobile) ? undefined : "invalid";
    } else {
      this.errors.mobile = undefined;
    }
  }
}
