import React, { FunctionComponent } from "react";

import { Typography } from "@material-ui/core";

export interface ISLATimeProps {
  date: number;
}

const SLATime: FunctionComponent<ISLATimeProps> = (props: ISLATimeProps) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const { date } = props;
  const day = new Date(date).getDate();
  const month = monthNames[new Date(date).getMonth()];
  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();
  return <Typography component="p">{`${day} ${month} ${hours}:${minutes < 10 ? `0${minutes}` : minutes}`}</Typography>;
};

export default SLATime;
