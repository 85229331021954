import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import { GuestChatAnswer, GuestChatDecline, GuestChatRequest } from "messaging";
import { inject } from "mobx-react";
import React, { Component, Context, Fragment } from "react";
import { AlertDialog } from "../../components/AlertDialog";
import { NotificationTone } from "../../components/NotificationTone";
import { ClientStore } from "../../stores";
import { IState, StateContext } from "../StateContext";

@inject("clientStore")
class RequestModal extends Component<{ clientStore?: ClientStore }, {}> {
  public static contextType: Context<IState> = StateContext;

  public handleAccept = async (request: GuestChatRequest) => {
    const { dismissActiveRequest, staff }: IState = this.context;
    const { isConnectedToMQTTBroker, getClient } = this.props.clientStore!;
    const answer = new GuestChatAnswer({
      request,
      staff: `${staff}`
    });

    const staffClient = getClient();
    if (isConnectedToMQTTBroker && staffClient) {
      staffClient.sendGuestChatAnswer(answer);
    }

    dismissActiveRequest(request.chreqid);
  };

  public handleDecline = async (request: GuestChatRequest) => {
    request.booking = undefined;
    const { isConnectedToMQTTBroker, getClient } = this.props.clientStore!;
    const { dismissActiveRequest, staff }: IState = this.context;
    const decline = new GuestChatDecline({
      request,
      staff: `${staff}`
    });

    const staffClient = getClient();
    if (isConnectedToMQTTBroker && staffClient) {
      staffClient.sendGuestChatDecline(decline);
    }

    dismissActiveRequest(request.chreqid);
  };

  public render() {
    const { activeRequests }: IState = this.context;
    return (
      <Fragment>
        {activeRequests.length > 0 && <NotificationTone loop />}
        {activeRequests.map((request: GuestChatRequest, index: number) => {
          const { booking, bookingNo } = request;
          return (
            <AlertDialog
              key={`${request.chreqid}${index}`}
              render={({ handleClose }) => (
                <Fragment>
                  <DialogTitle>Incoming chat</DialogTitle>
                  <DialogContent>
                    {booking && (
                      <DialogContentText>
                        <Typography component="span">
                          {booking.firstName && (
                            <Fragment>
                              <b>Name: </b> {booking.firstName ? booking.firstName : ""}
                              {booking.lastName ? ` ${booking.lastName}` : ""}
                              <br />
                            </Fragment>
                          )}
                          {booking.email && (
                            <Fragment>
                              <b>Email: </b> {booking.email}
                              <br />
                            </Fragment>
                          )}
                          {booking.roomNo && (
                            <Fragment>
                              <b>Room# : </b> {booking.roomNo ? booking.roomNo : " -"}
                              <br />
                            </Fragment>
                          )}
                          {bookingNo && (
                            <Fragment>
                              <b>Booking# : </b> {bookingNo ? bookingNo : "-"}
                            </Fragment>
                          )}
                        </Typography>
                      </DialogContentText>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        this.handleDecline(request);
                        handleClose();
                      }}
                    >
                      DECLINE
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.handleAccept(request);
                        handleClose();
                      }}
                    >
                      ACCEPT
                    </Button>
                  </DialogActions>
                </Fragment>
              )}
            />
          );
        })}
      </Fragment>
    );
  }
}

export default RequestModal;
