import React, { ChangeEvent, Component, FormEvent, Fragment } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { ThemedStyledProps } from "styled-components";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";

import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";
import { IState, StateContext } from "../StateContext";

import { AuthenticatedStaff } from "../../services";
import { getAuthorisedRoutes } from "../../utils";
import { AuthConsumer, IAuth } from "../AuthContext";

const Logo = styled.img`
  display: block;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const Form = styled.form`
  padding: 20px;
  width: 100%;
`;

const Body = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.background};
`;

const ProgressWrapper = styled.div`
  display: block;
  margin-right: auto;
  margin-left: auto;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed};
`;

const StyledButton = styled(Button as React.FunctionComponent<ButtonProps>)`
  margin-top: 8px !important;
`;

export interface ILoginScreenState {
  loading: boolean;
  password: string;
  username: string;
  status: string;
}

export type ILoginScreenWithRouterProps = {} & RouteComponentProps;

class LoginScreen extends Component<{}, ILoginScreenState> {
  public static contextType = StateContext;

  public state: ILoginScreenState = {
    loading: false,
    password: "",
    status: "",
    username: ""
  };

  constructor(props: ILoginScreenWithRouterProps) {
    super(props);
  }

  public handleUsernameChange = (change: ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: change.target.value });
  };

  public handlePasswordChange = (change: ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: change.target.value });
  };

  public handleSubmit = async (form: FormEvent<HTMLFormElement>, auth: IAuth) => {
    this.setState({ loading: true });
    form.preventDefault();
    const { username, password } = this.state;
    try {
      const { tid, pid, sfid, message }: AuthenticatedStaff = await auth.authenticate(username.toLowerCase(), password);
      if (tid && pid && sfid) {
        const {
          initStateContext,
          setHotelInfo,
          setTenantId,
          setStaff,
          setStaffId,
          setProperty,
          setPropertyId
        }: IState = this.context;
        initStateContext(tid, pid, sfid);
        setProperty(`t/${tid}/p/${pid}`);
        setStaff(`t/${tid}/p/${pid}/sf/${sfid}`);
        setPropertyId(pid);
        setStaffId(sfid);
        setTenantId(tid);
        setHotelInfo(tid, pid);
      } else if (message) {
        this.setState({ loading: false, status: "invalid-credentials" });
      }
    } catch (error) {
      console.error("AUTH ERROR", error);
    }
  };

  public render() {
    const { loading, password, status, username } = this.state;
    return (
      <AuthConsumer>
        {(auth: IAuth) => {
          const authorisedRoutes = auth.staffRoutes && getAuthorisedRoutes(auth.staffRoutes);
          return (
            <Fragment>
              {auth.isAuthenticated && authorisedRoutes && authorisedRoutes.length > 0 && (
                <Redirect to={authorisedRoutes[0].URL} />
              )}
              <Body>
                <Logo src="/logo-circle-180x180.png" />
                {loading && (
                  <ProgressWrapper>
                    <CircularProgress color="inherit" />
                  </ProgressWrapper>
                )}
                <Dialog
                  open={status === "invalid-credentials"}
                  onClose={this.handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Login failed</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">Invalid username or password</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleCloseDialog} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                {!loading && (
                  <Form
                    noValidate
                    autoComplete="off"
                    onSubmit={(e: FormEvent<HTMLFormElement>) => this.handleSubmit(e, auth)}
                  >
                    <TextField
                      label="Username"
                      value={username}
                      variant="filled"
                      fullWidth
                      onChange={this.handleUsernameChange}
                      margin="dense"
                    />
                    <TextField
                      label="Password"
                      value={password}
                      variant="filled"
                      type="password"
                      fullWidth
                      onChange={this.handlePasswordChange}
                      margin="dense"
                    />
                    <StyledButton
                      variant="contained"
                      fullWidth
                      color="primary"
                      type="submit"
                      disabled={!username || !password}
                    >
                      Login
                    </StyledButton>
                  </Form>
                )}
              </Body>
            </Fragment>
          );
        }}
      </AuthConsumer>
    );
  }

  private handleCloseDialog = () => {
    this.setState({ status: "" });
  };
}

export default LoginScreen;
