import { Divider, Typography } from "@material-ui/core";
import { IBucketItem, PatchedTypes } from "messages";
import React, { Fragment, FunctionComponent } from "react";
import TimeAgo from "react-timeago";
import BucketItemData from "./bucket-item-data";

export interface IBucketDataProps {
  buckets: PatchedTypes.IBucket[];
  defaultFullInfo?: boolean;
}

const BucketData: FunctionComponent<IBucketDataProps> = ({ defaultFullInfo, buckets }: IBucketDataProps) => (
  <Fragment>
    {buckets &&
      buckets.map((bucket: PatchedTypes.IBucket, bucketIndex: number) => {
        const { bid, createdAt, items } = bucket;
        return (
          <Fragment key={`${bid}#${bucketIndex}`}>
            {defaultFullInfo && (
              <Typography component="div" color="primary">
                <TimeAgo date={createdAt} />
              </Typography>
            )}
            {items &&
              items.map((item: IBucketItem, itemIndex: number) => (
                <Fragment key={`${item.iid}#${itemIndex}`}>
                  <BucketItemData item={item} defaultFullInfo={defaultFullInfo} />
                  {defaultFullInfo && <Divider />}
                </Fragment>
              ))}
          </Fragment>
        );
      })}
  </Fragment>
);

export default BucketData;
