import React, { FunctionComponent } from "react";

import {
  AppBar,
  createStyles,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { SlideProps } from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { Gallery } from "../../components/Gallery";

const styles = () =>
  createStyles({
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    }
  });

const Transition: FunctionComponent<SlideProps> = (props: SlideProps) => {
  return <Slide direction="up" {...props} />;
};

export interface IFullscreenGalleryDialogProps extends WithStyles<typeof styles> {
  imgUrls: string | string[];
  clickedStep: number;
  open: boolean;
  handleClose: () => void;
}

const FullscreenGalleryDialog: FunctionComponent<IFullscreenGalleryDialogProps> = (
  props: IFullscreenGalleryDialogProps
) => {
  const { classes, clickedStep, handleClose, open, imgUrls } = props;
  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Fullscreen Gallery
          </Typography>
        </Toolbar>
      </AppBar>
      <Gallery fullscreen={true} initialActiveStep={clickedStep} imgUrls={imgUrls} stopAutoPlay={true} />
    </Dialog>
  );
};

export default withStyles(styles)(FullscreenGalleryDialog);
