import React, { FunctionComponent } from "react";

import { Chats } from "../../components/Chats";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { IState, StateConsumer } from "../StateContext";

const HistoryListScreen: FunctionComponent<{}> = (props: {}) => (
  <ResponsiveNavbar title={"History chats"}>
    <StateConsumer>{({ histories }: IState) => <Chats sessions={histories} />}</StateConsumer>
  </ResponsiveNavbar>
);

export default HistoryListScreen;
