import { Chip, createStyles, Fab, List, ListSubheader, Theme, WithStyles, withStyles } from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";
import { FabProps } from "@material-ui/core/Fab";
import { ListSubheaderProps } from "@material-ui/core/ListSubheader";
import { IStaffInfo, PatchedTypes } from "messages";
import React, { Component, Context } from "react";
import { FullscreenGalleryDialog } from "../../components/FullscreenGalleryDialog";
import { RequestListItemPlaceholder } from "../../components/Placeholders";
import { ResponsiveNavbar } from "../../components/ResponsiveNavbar";
import { ServiceRequestListItem } from "../../components/ServiceRequestListItem";
import { ApiService } from "../../services";
import styled from "../../styles/styled-components";
import { getDepartmentsFromStaffInfo, IState, StateContext } from "../StateContext";
import withStaffInfo from "./with-staff-info";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
`;

const StyledChip = styled(Chip as React.SFC<ChipProps>)`
  margin: 0 5px;
`;

const StyledListSubheader = styled(ListSubheader as React.SFC<ListSubheaderProps>)`
  background-image: linear-gradient(279deg, #ffd273, #ffde3b);
  color: #000000;
`;

const StyledFab = styled(Fab as React.SFC<FabProps>)`
  margin-right: 8px !important;
`;

const styles = (theme: Theme) =>
  createStyles({
    listSection: {
      backgroundColor: "inherit"
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "auto",
      position: "relative",
      width: "100%"
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0
    }
  });

export interface ICompletedTasksScreenProps extends WithStyles<typeof styles> {
  staffInfo?: IStaffInfo;
}

export interface ICompletedTasksScreenState {
  completedRequests: PatchedTypes.IServiceRequest[];
  isLoading: boolean;
  showFullscreenGalleryDialog?: {
    imgUrls: string | string[];
    clickedStep: number;
  };
}

class CompletedTasksScreen extends Component<ICompletedTasksScreenProps, ICompletedTasksScreenState> {
  public static contextType: Context<IState> = StateContext;

  public state: ICompletedTasksScreenState = {
    completedRequests: [],
    isLoading: false
  };

  public componentDidMount = async () => {
    this.fetchCompletedRequests();
  };

  public getSnapshotBeforeUpdate(prevProps: ICompletedTasksScreenProps) {
    if (!prevProps.staffInfo && this.props.staffInfo) {
      return { shouldFetchServiceRequests: true };
    }
    return null;
  }

  public async componentDidUpdate(
    prevProps: ICompletedTasksScreenProps,
    prevState: ICompletedTasksScreenState,
    snapshot?: {
      shouldFetchServiceRequests?: boolean;
    }
  ) {
    if (snapshot && snapshot.shouldFetchServiceRequests) {
      this.fetchCompletedRequests();
    }
  }

  public fetchCompletedRequests = async () => {
    if (!this.props.staffInfo) {
      return;
    }

    this.setState({ isLoading: true });
    const departments = getDepartmentsFromStaffInfo(this.props.staffInfo);
    const { tid, pid } = this.props.staffInfo;
    const completedRequests = await ApiService.getCompletedServiceRequests(tid, pid, departments);
    this.setState({ isLoading: false, completedRequests });
  };

  public handleThumbnailClick = (images: string[], index: number) => {
    this.setState({ showFullscreenGalleryDialog: { imgUrls: images, clickedStep: index } });
  };

  public handleFullscreenGalleryDialogClose = () => {
    this.setState({ showFullscreenGalleryDialog: undefined });
  };

  public render() {
    const { classes } = this.props;
    const { isLoading, showFullscreenGalleryDialog, completedRequests } = this.state;
    return (
      <ResponsiveNavbar title="Completed Tasks">
        {showFullscreenGalleryDialog && (
          <FullscreenGalleryDialog
            {...showFullscreenGalleryDialog}
            open={showFullscreenGalleryDialog ? true : false}
            handleClose={this.handleFullscreenGalleryDialogClose}
          />
        )}
        <Body>
          <List className={classes.root} dense subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                {!completedRequests.length && <RequestListItemPlaceholder isLoading={isLoading} noOfLoadingItems={2} />}
                {completedRequests.map((request: PatchedTypes.IServiceRequest, index: number) => (
                  <ServiceRequestListItem
                    handleThumbnailClick={this.handleThumbnailClick}
                    key={`${request.serreqid}-${index}`}
                    request={request}
                  />
                ))}
              </ul>
            </li>
          </List>
        </Body>
      </ResponsiveNavbar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withStaffInfo(CompletedTasksScreen));
