import { EventEmitter } from "events";
import { GuestChatSession, IPeerMessage, PeerMessage } from "messaging";
import { GuestChatPersistenceService } from "../../services";
import { IChat, IFetchChat } from "./interfaces";

export interface IHistoryChatOptions {
  chat?: IChat<PeerMessage>;
  session?: GuestChatSession;
}

export class HistoryChat extends EventEmitter implements IChat<PeerMessage>, IFetchChat {
  public static type: string = "HistoryChat";
  public readonly type: string = HistoryChat.type;
  public readonly sesid: string;
  public readonly guest: string;
  public messages: PeerMessage[] = [];

  public noOfLastLoadRecords: number = 0;
  public noOfRecords: number = 15;

  constructor(option: IHistoryChatOptions) {
    super();
    const { chat, session } = option;
    if (session) {
      this.guest = session.guest || "currently not available";
      this.sesid = session.sesid;
      this.fetchFirstPage();
    } else if (chat) {
      this.guest = chat.guest as string;
      this.sesid = chat.sesid as string;
      this.messages = chat.messages;
    } else {
      throw new Error("Invalid options to HistoryChat constructor");
    }
  }

  public fetchNextPage = async (page: number) => {
    const messages = await GuestChatPersistenceService.fetchMessages(this.sesid, page, this.noOfRecords);
    this.messages = [...messages.map((message: IPeerMessage) => new PeerMessage(message)), ...this.messages];
    this.noOfLastLoadRecords = messages.length;
  };

  private fetchFirstPage() {
    this.fetchNextPage(0).then(() => this.emit("first-page-fetch"));
  }
}
