import { ICheckin } from "messages";
import { action, computed, observable } from "mobx";
import moment from "moment";
import { ChangeEvent, FormEvent, ReactNode } from "react";
import { database, ApiService, storage } from "../services";

interface IPrerequisite {
  pid: string;
  sfid: string;
  tid: string;
}

// TODO: Try to incorporate the below details into the checkin form
// repeated PassportInfo passportInfos = 14;
// string idProof = 15; // pan card is not accepted
// repeated string otherRequests = 22;
// }

// message PassportInfo {
//   required string passportNo = 0;
//   float issuedDate = 1;
//   string issuedPlace = 2;
//   float expiryDate = 3;
//   required string passportImage = 4;
// }

export class CheckinStore {
  private reset: ICheckin = {
    // arrivalDate: new Date().getTime(),
    extraBed: 0,
    isSmokingRoom: false,
    noOfAdults: 0,
    noOfChildren: 0,
    // TODO: Take care of status properly when implementing update feature for checkin in staff app
    status: "NEW"
  } as ICheckin;
  @observable public prerequisite?: IPrerequisite;
  @observable public isCheckinModalInEditMode: boolean = false;
  @observable public isCheckinModalVisible: boolean = false;
  @observable public isCheckinsFetching: boolean = false;
  @observable public isLoading: boolean = false;
  @observable public isUploading: boolean = false;
  @observable public activeCheckin: ICheckin = this.reset;
  @observable public checkins: Map<string, ICheckin> = new Map();
  @observable public showFullscreenGalleryDialog?: { imgUrls: string[]; clickedStep: number };
  @observable public errorsInCheckin: { [k: string]: string | undefined } = {};
  private errors: { [k: string]: string | undefined } = {};

  @action private fetchCheckins = async ({ tid, pid }: IPrerequisite) => {
    this.isCheckinsFetching = true;
    try {
      // TODO: write an API to fetch all the checkins for a property
      // const response = await ApiService.getHotelBookings(tid, pid);
      // this.checkins = await response.json();
      this.isCheckinsFetching = false;
    } catch (error) {
      console.log(error);
    }
  };

  public getCheckin = async (chkinid: string) => {
    const checkin = this.checkins.get(chkinid);
    if (checkin) {
      return checkin;
    }

    const checkinFromApi = await ApiService.getCheckin(chkinid);
    this.checkins.set(checkinFromApi.chkinid!, checkinFromApi);
    return checkinFromApi;
  };

  @action public handleThumbnailClick = (images: string[], index: number) => {
    this.showFullscreenGalleryDialog = { imgUrls: images, clickedStep: index };
  };

  @action public hideFullscreenGalleryDialog = () => {
    this.showFullscreenGalleryDialog = undefined;
  };

  @action public handleFilesChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const uploadPromises: Array<Promise<string>> = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < files.length; index++) {
        uploadPromises.push(this.uploadAsAPromise(files[index], index));
      }
      try {
        this.isUploading = true;
        const images = await Promise.all(uploadPromises);
        this.isUploading = false;
      } catch (error) {
        // TODO: handle error here
        this.isUploading = false;
      }
    }
  };

  private uploadAsAPromise = (imageFile: File, index: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      const { pid, tid } = this.prerequisite!;
      const storageRef = storage.ref(`t/${tid}/p/${pid}/request/check-ins/${new Date().getTime()}-${index}`);
      const task = storageRef.put(imageFile);
      task.on(
        "state_changed",
        (snapshot: any) => {
          // NOTE: Can be used to calculate the actual progress percentage
        },
        (error: Error) => {
          reject(error);
        },
        async () => {
          resolve(await task.snapshot.ref.getDownloadURL());
        }
      );
    });
  };

  @action public setPrerequisite = (prerequisite: IPrerequisite) => {
    if (this.prerequisite) {
      return;
    }
    this.prerequisite = prerequisite;
    this.fetchCheckins(prerequisite);
  };

  @action public showCheckinModal = () => {
    const { sfid, ...restPrerequisite } = this.prerequisite as IPrerequisite;
    this.activeCheckin = Object.assign(
      this.activeCheckin,
      restPrerequisite,
      this.isCheckinModalInEditMode ? { modifiedBy: sfid } : { createdBy: sfid, modifiedBy: sfid }
    );
    this.isCheckinModalVisible = true;
  };

  @action public hideCheckinModal = () => {
    this.activeCheckin = this.reset;
    this.errors = {};
    this.errorsInCheckin = {};
    this.isCheckinModalInEditMode = false;
    this.isCheckinModalVisible = false;
  };

  @action public reportError = (key: string) => {
    this.checkForErrors();
    this.errorsInCheckin[key] = this.errors[key];
  };

  @action public setBookingNoInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const bookingNo = change.target.value;
    this.activeCheckin.bookingNo = bookingNo.trim();
  };

  @action public setAddressInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const address = change.target.value;
    this.activeCheckin.address = address.trim();
  };

  @action public setNationalityInCheckin = (change: ChangeEvent<HTMLSelectElement>, child: ReactNode) => {
    const nationality = change.target.value;
    this.activeCheckin.nationality = nationality;
  };

  @action public setFirstNameInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const firstName = change.target.value;
    this.activeCheckin.firstName = firstName.trim();
  };

  @action public setLastNameInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const lastName = change.target.value;
    this.activeCheckin.lastName = lastName.trim();
  };

  @action public setDesignationInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const designation = change.target.value;
    this.activeCheckin.designation = designation.trim();
  };

  @action public setOrganisationInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const organisation = change.target.value;
    this.activeCheckin.designation = organisation.trim();
  };

  @action public setGSTNoInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const gstNo = change.target.value;
    this.activeCheckin.gstNo = gstNo.trim();
  };

  @action public setEmailInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const email = change.target.value;
    this.activeCheckin.email = email.trim();
  };

  @action public setMobileInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const mobile = change.target.value;
    this.activeCheckin.mobile = mobile.trim();
  };

  @action public setSpecialInstructionInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const specialInstruction = change.target.value;
    this.activeCheckin.specialInstruction = specialInstruction.trim();
  };

  @action public setVisitPurposeInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const visitPurpose = change.target.value;
    this.activeCheckin.visitPurpose = visitPurpose.trim();
  };

  @action public setPaymentModeInCheckin = (change: ChangeEvent<HTMLSelectElement>, child: ReactNode) => {
    const paymentMode = change.target.value;
    this.activeCheckin.paymentMode = paymentMode.trim();
  };

  @action public setMembershipTypeInCheckin = (change: ChangeEvent<HTMLSelectElement>, child: ReactNode) => {
    const membershipType = change.target.value;
    this.activeCheckin.membershipType = membershipType.trim();
  };

  @action public setMembershipNoInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const membershipNo = change.target.value;
    this.activeCheckin.membershipNo = membershipNo.trim();
  };

  @action public setArrivalDateInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const arrivalDate = new Date(change.target.value);
    this.activeCheckin.arrivalDate = arrivalDate.getTime();
  };

  @action public setBedTypeInCheckin = (change: ChangeEvent<HTMLSelectElement>, child: ReactNode) => {
    const bedType = change.target.value;
    this.activeCheckin.bedType = bedType.trim();
  };

  @action public setExtraBedInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const extraBed = parseInt(change.target.value, 10);
    this.activeCheckin.extraBed = extraBed;
  };

  @action public setRoomTypeInCheckin = (change: ChangeEvent<HTMLSelectElement>, child: ReactNode) => {
    const roomType = change.target.value;
    this.activeCheckin.roomType = roomType.trim();
  };

  @action public setNoOfAdultsInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const noOfAdults = parseInt(change.target.value, 10);
    this.activeCheckin.noOfAdults = noOfAdults;
  };

  @action public setNoOfChildrenInCheckin = (change: ChangeEvent<HTMLInputElement>) => {
    const noOfChildren = parseInt(change.target.value, 10);
    this.activeCheckin.noOfChildren = noOfChildren;
  };

  @action public submitActiveCheckin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.isLoading = true;

    try {
      const response = await ApiService.createCheckin(this.activeCheckin);
      this.isLoading = false;
      this.hideCheckinModal();
    } catch (error) {
      this.isLoading = false;
      // TODO: Show some toast message that the request failed
      console.log(error);
    }
  };

  @action public toggleIsSmokingRoomInCheckin = (event: FormEvent<HTMLInputElement>) => {
    this.activeCheckin.isSmokingRoom = !this.activeCheckin.isSmokingRoom;
  };

  // public isValidDate(date: number) {
  //   return moment(date).isValid();
  // }

  // public isValidEmail(email: string) {
  //   const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  //   return emailRegex.test(email);
  // }

  // public isValidMobile(mobile: string) {
  //   const mobileRegex = new RegExp(/^[0-9\+\- ]{8,20}$/);
  //   return mobileRegex.test(mobile);
  // }

  @computed public get disableSubmitForActiveCheckin() {
    const { address, bookingNo, email, firstName, lastName, mobile, nationality } = this.activeCheckin;
    if (!bookingNo || (bookingNo && bookingNo.trim() === "")) {
      return true;
    }
    // if (!firstName || (firstName && firstName.trim() === "")) {
    //   return true;
    // }
    // if (!lastName || (lastName && lastName.trim() === "")) {
    //   return true;
    // }
    // if (!nationality || (nationality && nationality.trim() === "")) {
    //   return true;
    // }
    // if (!address || (address && address.trim() === "")) {
    //   return true;
    // }
    // if (email && !this.isValidEmail(email)) {
    //   return true;
    // }
    // if (mobile && !this.isValidMobile(mobile)) {
    //   return true;
    // }
    return false;
  }

  private checkForErrors() {
    const { address, bookingNo, email, firstName, lastName, mobile, nationality } = this.activeCheckin;

    this.errors.bookingNo = bookingNo && bookingNo.trim() !== "" ? undefined : "required";
    // this.errors.firstName = firstName && firstName.trim() !== "" ? undefined : "required";
    // this.errors.lastName = lastName && lastName.trim() !== "" ? undefined : "required";
    // this.errors.nationality = nationality && nationality.trim() !== "" ? undefined : "required";
    // this.errors.address = address && address.trim() !== "" ? undefined : "required";

    // if (email && email.trim() !== "") {
    //   this.errors.email = this.isValidEmail(email) ? undefined : "invalid";
    // } else {
    //   this.errors.email = undefined;
    // }

    // if (mobile && mobile.trim() !== "") {
    //   this.errors.mobile = this.isValidMobile(mobile) ? undefined : "invalid";
    // } else {
    //   this.errors.mobile = undefined;
    // }
  }
}
