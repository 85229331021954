import { GuestChatSession } from "messaging";

export const getRemovedSessions = (
  sessions: Map<string, GuestChatSession>,
  newSessions: GuestChatSession[]
): GuestChatSession[] => {
  if (sessions.size > newSessions.length) {
    return Array.from(sessions.values()).filter(
      (session: GuestChatSession) =>
        !newSessions.find((newSession: GuestChatSession) => session.sesid === newSession.sesid)
    );
  }
  return [];
};
