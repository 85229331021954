import React, { FunctionComponent, Fragment } from "react";

export interface IShortTimeProps {
  date: Date | number;
}

const ShortTime: FunctionComponent<IShortTimeProps> = (props: IShortTimeProps) => {
  const date = new Date(props.date).toTimeString();
  const [hrs, mins] = date.split(":");
  const hours = parseInt(hrs, 10);
  const minutes = parseInt(mins, 10);
  const shortTime = [
    hours > 12 ? hours - 12 : hrs,
    minutes === 0 ? "" : `:${minutes}`,
    hours > 12 ? " PM" : " AM"
  ].join("");
  return <Fragment>{shortTime}</Fragment>;
};

export default ShortTime;
