import { StaffClient } from "messaging";
import { getConnectionOptions } from "../utils";

export class StaffClientService {
  public static getInstance = async (errorHandler: (error: Error) => void): Promise<StaffClient> => {
    if (!StaffClientService.instance) {
      const staffClient = new StaffClient(getConnectionOptions());
      try {
        await staffClient.connect();
        StaffClientService.instance = staffClient;
      } catch (error) {
        errorHandler(error);
      }
      staffClient.on("error", errorHandler);
    }
    return StaffClientService.instance;
  };
  private static instance: StaffClient;
  private constructor() {}
}
