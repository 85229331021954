import React from "react";

export interface IToastProps {
  loop?: boolean;
}

const NotificationTone = (props: IToastProps) => (
  <audio autoPlay loop={props.loop}>
    <source src="/media/quite-impressed.ogg" type="audio/ogg" />
    <embed src="/media/quite-impressed.mp3" hidden={true} />
    <track kind="captions" />
  </audio>
);

export default NotificationTone;
