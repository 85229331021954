import { IAssignedStaffInfo, IHotel, IStaffInfo, PatchedTypes, ICheckin } from "messages";

export type AuthenticatedStaff = IStaffInfo & { message: string; customToken: string; staffAccessList: string[] };

export class ApiService {
  public static hostName: string = process.env.REACT_APP_API_URL as string;
  public static getHotelBookings(tid: string, pid: string) {
    return fetch(`${this.hostName}/hotel-bookings/${tid}/${pid}`);
  }

  public static async getMissedConversations(tid: string, pid: string): Promise<any[]> {
    const response = await fetch(`${this.hostName}/missed-conversations/${tid}/${pid}`);
    return (await response.json()) as any[];
  }

  public static async getHotelBookingByBookingNo(tid: string, pid: string, bookingNo: string) {
    const response = await fetch(`${this.hostName}/hotel-bookings/${tid}/${pid}/${bookingNo}`);
    const booking = (await response.json()) as PatchedTypes.IHotelBooking;
    return booking;
  }

  public static async getCheckin(chkinid: string): Promise<ICheckin> {
    const response = await fetch(`${this.hostName}/checkins/${chkinid}`);
    return await response.json();
  }

  public static createHotelBooking(hotelBooking: PatchedTypes.IHotelBooking) {
    return fetch(`${this.hostName}/hotel-bookings/`, {
      body: JSON.stringify(hotelBooking),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    });
  }

  public static createCheckin(checkin: ICheckin) {
    return fetch(`${this.hostName}/checkins/`, {
      body: JSON.stringify(checkin),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    });
  }

  public static async assignStaffToServiceRequest(serreqid: string, staffs: IAssignedStaffInfo[]) {
    const response = await fetch(`${this.hostName}/service-requests/${serreqid}/assign-staffs`, {
      body: JSON.stringify({ staffs }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT"
    });
    if (response && response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public static async removeStaffFromServiceRequest(serviceRequestId: string) {
    const response = await fetch(`${this.hostName}/service-requests/${serviceRequestId}/remove-staffs`, {
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT"
    });
    if (response && response.status === 200) {
      return true;
    } else {
      return false;
    }
  }

  public static updateHotelBooking(hotelBooking: PatchedTypes.IHotelBooking) {
    const { hbkid } = hotelBooking;
    return fetch(`${this.hostName}/hotel-bookings/${hbkid}`, {
      body: JSON.stringify(hotelBooking),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT"
    });
  }

  public static async getServiceRequests(
    tid: string,
    pid: string,
    departments?: string[] | null
  ): Promise<PatchedTypes.IServiceRequest[]> {
    const departmentsQuery =
      departments && departments.length > 0 ? `?departments=${departments.join("&departments=")}` : "";
    const response = await fetch(`${this.hostName}/service-requests/${tid}/${pid}${departmentsQuery}`);
    return (await response.json()) as PatchedTypes.IServiceRequest[];
  }

  public static async getCompletedServiceRequests(
    tid: string,
    pid: string,
    departments?: string[] | null
  ): Promise<PatchedTypes.IServiceRequest[]> {
    const departmentsQuery =
      departments && departments.length > 0 ? `?departments=${departments.join("&departments=")}` : "";
    const response = await fetch(`${this.hostName}/service-requests/completed/${tid}/${pid}${departmentsQuery}`);
    return (await response.json()) as PatchedTypes.IServiceRequest[];
  }

  public static async getServiceRequestEscalation(
    tid: string,
    pid: string,
    sfid: string
  ): Promise<{
    activeEscalations: PatchedTypes.IServiceRequestEscalation[];
    completedEscalations: PatchedTypes.IServiceRequestEscalation[];
  }> {
    const response = await fetch(`${this.hostName}/service-request-escalations/${tid}/${pid}/${sfid}`);
    return await response.json();
  }

  public static async getStaffInfo(sfid: string): Promise<IStaffInfo> {
    const response = await fetch(`${this.hostName}/staff-info/${sfid}`);
    if (response.status === 200) {
      return (await response.json()) as IStaffInfo;
    }
    throw new Error("Staff not found");
  }

  public static async getAllStaffInfo(tid: string, pid: string) {
    const response: Response = await fetch(`${this.hostName}/staff-info/${tid}/${pid}`);
    return (await response.json()) as IStaffInfo[];
  }

  public static async loginStaff(username: string, password: string): Promise<AuthenticatedStaff> {
    const response: Response = await fetch(`${this.hostName}/staffs/login`, {
      body: JSON.stringify({ username, password }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    });
    return await response.json();
  }

  public static setServiceRequestStatus(id: string, status: string) {
    return fetch(`${this.hostName}/service-requests/${id}`, {
      body: JSON.stringify({ status }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT"
    });
  }

  public static setServiceRequestDepartment(id: string, department: string) {
    return fetch(`${this.hostName}/service-requests/${id}`, {
      body: JSON.stringify({ department }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT"
    });
  }

  public static updateStaffToken(sfid: string, token: string) {
    return fetch(`${this.hostName}/staff-info/${sfid}/update-staff-token`, {
      body: JSON.stringify({ sfid, pushToken: [token] }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT"
    });
  }

  public static async turnOnNotification(sfid: string, token?: string) {
    const response: Response = await fetch(`${this.hostName}/staff-info/${sfid}/turn-on-notification`, {
      body: JSON.stringify({ sfid, pushToken: [token] }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT"
    });
    return await response.json();
  }

  public static async turnOffNotification(sfid: string) {
    const response: Response = await fetch(`${this.hostName}/staff-info/${sfid}/turn-off-notification`, {
      body: JSON.stringify({ sfid }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT"
    });
    return await response.json();
  }

  public static async getHotelInformation(tid: string, pid: string) {
    const response = await fetch(`${this.hostName}/hotels/${tid}/${pid}`);
    return (await response.json()) as IHotel;
  }

  public static async getStaffAccessInfo(tid: string, pid: string, dept: string, role: string) {
    const response = await fetch(`${this.hostName}/hotel-access-configuration/${tid}/${pid}/${dept}/${role}`);
    return await response.json();
  }

  public static async createOrder(rid: string, sid: string, uid: string): Promise<{ oid: string }> {
    const response = await fetch(`${process.env.REACT_APP_MENU_APP_URL}/app/orders`, {
      body: JSON.stringify({ rid, sid, uid }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        // tslint:disable-next-line: object-literal-key-quotes
        Authorization: `Basic ${process.env.REACT_APP_MENU_APP_HTTP_AUTH}`,
        "Content-Type": "application/json"
      },
      method: "POST"
    });
    return await response.json();
  }

  public static async sendSMS(oid: string, phoneNumbers: string): Promise<{ status: string }> {
    const response = await fetch(`${process.env.REACT_APP_MENU_APP_URL}/app/orders/${oid}/send-sms`, {
      body: JSON.stringify({ phoneNumbers }),
      headers: {
        // tslint:disable-next-line:object-literal-key-quotes
        Accept: "application/json",
        // tslint:disable-next-line: object-literal-key-quotes
        Authorization: `Basic ${process.env.REACT_APP_MENU_APP_HTTP_AUTH}`,
        "Content-Type": "application/json"
      },
      method: "POST"
    });
    return await response.json();
  }
}
